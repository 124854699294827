<template>
  <v-container id="BollettaInterattivaMieiDatiMioContratto5Dt" class="pa-7">
    <v-row  :class="isMobile ?  'monocol MieiDatiMioContrattoMobile': 'col MieiDatiMioContratto5Dt'" justify="center" align="center">
      <v-col :cols="isMobile ? 12 : 6">
        <BollettaInterattivaMieiDati5Dt :documentData="documentData" :getImg="getImg" :translateLabel="translateLabel" />
      </v-col>
      <v-col  :cols="isMobile ? 12 : 6">
        <BollettaInterattivaMioContratto5Dt :documentData="documentData" :getImg="getImg" :translateLabel="translateLabel" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import BollettaInterattivaMieiDati5Dt from './BollettaInterattivaMieiDati5Dt.vue';
import BollettaInterattivaMioContratto5Dt from './BollettaInterattivaMioContratto5Dt.vue';

export default {
 components: {
  BollettaInterattivaMieiDati5Dt,
  BollettaInterattivaMioContratto5Dt,
  },
  props: {
        documentData: {
            type: Object,
            default: null
        },
        translateLabel: {
          type: Function,
        },
        getImg: {
          type: Function,
        },
    },
  methods:{
  },
   computed: { 
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        }
    },
}
</script>

<style>

</style>