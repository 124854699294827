import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    getAcceptanceSection() {
        const url = this.webApiBaseUrl + "/api/registration/acceptancesection";
        return this.apiCallerHelper.callGet(url);
    }

    getHeaderSection() {
        const url = this.webApiBaseUrl + "/api/registration/headersection";
        return this.apiCallerHelper.callGet(url);
    }

    getVerifySection() {
        const url = this.webApiBaseUrl + "/api/registration/verifysection";
        return this.apiCallerHelper.callGet(url);
    }

    getUsernameSection() {
        const url = this.webApiBaseUrl + "/api/registration/usernamesection";
        return this.apiCallerHelper.callGet(url);
    }

    verify(data) {
        const url = this.webApiBaseUrl + "/api/registration/verify";
        return this.apiCallerHelper.callPost(url, data);
    }

    getProfileSection(customerId) {
        const url = this.webApiBaseUrl + "/api/registration/profilesection/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    getDropdownValues(format, param) {
        const url = this.webApiBaseUrl + "/api/registration/dropdown/" + format + "/param/" + param;
        return this.apiCallerHelper.callGet(url);
    }

    saveProfile(data) {
        const url = this.webApiBaseUrl + "/api/registration/saveprofile";
        return this.apiCallerHelper.callPost(url, data);
    }

    saveAcceptances(data) {
        const url = this.webApiBaseUrl + "/api/registration/saveacceptances";
        return this.apiCallerHelper.callPost(url, data);
    }
}