<template>
        <div class="MainBollettaInterattiva3Dt">
            <div class="MainFirstSectionModuleBollettaInterattiva3Dt">
            <v-container class="wrapWidth ContainerFirstSectionModuleBollettaInterattiva3Dt">
                <template>
                    <v-row class="FirsSectionModuleBollettaInterattiva3Dt">
                        <v-col cols="4">
                            <component v-for="(em, i) in enabledModulesLeft" :key="'component-left-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :checkLabel="checkLabel"></component>
                        </v-col>
                        <v-col cols="8" class="py-0">
                            <component v-for="(em, i) in enabledModulesRight" :key="'component-right-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :checkLabel="checkLabel"></component>
                        </v-col>
                    </v-row>  
                </template>
            </v-container>
            </div>
            <v-spacer class="spacerBollettaInterattiva3Dt"></v-spacer>
            <div class="MainSecondSectionModuleBollettaInterattiva3Dt">
                <v-container class="wrapWidth ContainerSecondSectionModuleBollettaInterattiva3Dt">
                    <template>
                        <v-row class="SecondSectionModuleBollettaInterattiva3Dt">
                            <component v-for="(em, i) in enabledModules" :key="'component-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :checkLabel="checkLabel" :modules="modules"></component>    
                        </v-row>       
                    </template>
                </v-container>
            </div>
    </div>
</template>

<script>
import MultiComponentSwitch3Dt from './BollettaInterattiva3Dt/MultiComponentSwitch3Dt.vue';
import BollettaInterattivaDownload3Dt from './BollettaInterattiva3Dt/BollettaInterattivaDownload3Dt.vue';
import Bolletta_Interattiva_Riepilogo_Doc3Dt from './BollettaInterattiva3Dt/Bolletta_Interattiva_Riepilogo_Doc3Dt.vue';
import BollettaInterattivaDatiPagamentoTestata3Dt from './BollettaInterattiva3Dt/BollettaInterattivaDatiPagamentoTestata3Dt.vue';
import BollettaInterattivaDatiPagamentoBody3Dt from './BollettaInterattiva3Dt/BollettaInterattivaDatiPagamentoBody3Dt.vue';
import BollettaInterattivaFornitura3Dt from './BollettaInterattiva3Dt/BollettaInterattivaFornitura3Dt.vue';
import BollettaInterattivaDatiFornitura3Dt from './BollettaInterattiva3Dt/BollettaInterattivaDatiFornitura3Dt.vue';
import BollettaInterattivaProfiloDuferco3Dt from './BollettaInterattiva3Dt/BollettaInterattivaProfiloDuferco3Dt.vue';
import BollettaInterattivaCustomerCare3Dt from './BollettaInterattiva3Dt/BollettaInterattivaCustomerCare3Dt.vue';
import BollettaInterattivaAutoletturaDuferco3Dt from './BollettaInterattiva3Dt/BollettaInterattivaAutoletturaDuferco3Dt.vue';

/*import BollettaInterattivaBody from './BollettaInterattivaBody.vue';
import BollettaInterattivaRiepilogoDoc from './BollettaInterattivaRiepilogoDoc.vue';
import BollettaInterattivaChart from './BollettaInterattivaChart.vue';
import BollettaInterattivaChart2 from './BollettaInterattivaChart2.vue';
import BollettaInterattivaAvviso from './BollettaInterattivaAvviso.vue';
import BollettaInterattivaContatti3 from './BollettaInterattivaContatti3.vue';
import BollettaInterattivaDownload from './BollettaInterattivaDownload.vue';
import BollettaInterattivaBanner from './BollettaInterattivaBanner.vue'
//import BollettaInterattivaFornitura from './BollettaInterattivaFornitura.vue';
import BollettaInterattivaDatiPagamento from './BollettaInterattivaDatiPagamento.vue';
import CustomerCare from './CustomerCare.vue';
import BollettaInterattivaDatiFornitura from './BollettaInterattivaDatiFornitura.vue';
import BollettaInterattivaProfiloDuferco from './BollettaInterattivaProfiloDuferco.vue';
import BollettaInterattivaAutoletturaDuferco from './BollettaInterattivaAutoletturaDuferco.vue';
import BollettaInterattivaDatiPagamento2 from './BollettaInterattivaDatiPagamento2.vue';
import Fornitura from './Fornitura.vue';*/
import ErrorPage from "../components/ErrorPage.vue";
import { services } from '../scripts/services/serviceBuilder'


export default ({
    components: {
        //LEFT
        Bolletta_Interattiva_Riepilogo_Doc3Dt,
        BollettaInterattivaDownload3Dt,

        //RIGHT
        BollettaInterattivaDatiPagamentoTestata3Dt,

        //CENTER
        MultiComponentSwitch3Dt,
        BollettaInterattivaDatiPagamentoBody3Dt,
        BollettaInterattivaFornitura3Dt,
        BollettaInterattivaDatiFornitura3Dt,
        BollettaInterattivaProfiloDuferco3Dt,
        BollettaInterattivaCustomerCare3Dt,
        BollettaInterattivaAutoletturaDuferco3Dt,

        /*BollettaInterattivaBody,
        BollettaInterattivaRiepilogoDoc,
        BollettaInterattivaChart,
        BollettaInterattivaChart2,
        BollettaInterattivaAvviso,
        BollettaInterattivaContatti3,
        BollettaInterattivaDownload,
        BollettaInterattivaBanner,
        //BollettaInterattivaFornitura,
        Fornitura,
        BollettaInterattivaDatiPagamento,
        ,
        CustomerCare,
        BollettaInterattivaProfiloDuferco,
        BollettaInterattivaAutoletturaDuferco,
        BollettaInterattivaDatiPagamento2,*/
        ErrorPage
    },
    props:{
      cryptoId: {
        type: String,
        default: null
      },
      documentId: {
        type: String,
        default: null
      },
      error: {
        type: Number,
        default: null
      },
      checkLabel: {
        type: Function,
      },
    },
    data() {
        return {
            enabledModulesLeft:[],
            enabledModulesRight:[],
            enabledModules:[],
            modules: {
                "riepilogo_doc3": "Bolletta_Interattiva_Riepilogo_Doc3Dt",
                "scarica_pdf": "BollettaInterattivaDownload3Dt",
                "dati_pagamento_testata": "BollettaInterattivaDatiPagamentoTestata3Dt",
                "dati_pagamento_sintesi": "BollettaInterattivaDatiPagamentoBody3Dt",
                "consumi": "BollettaInterattivaFornitura3Dt",
                "dati_fornitura_duferco": "BollettaInterattivaDatiFornitura3Dt",
                "profilo_duferco": "BollettaInterattivaProfiloDuferco3Dt",
                "customer_care_duferco": "BollettaInterattivaCustomerCare3Dt",
                "autolettura_duferco": "BollettaInterattivaAutoletturaDuferco3Dt",

                "multi_component_switch_3dt": "MultiComponentSwitch3Dt",
            
                "error_page": "ErrorPage",

                /*"riepilogo_doc": "BollettaInterattivaRiepilogoDoc",
                "sintesi_importi2": "BollettaInterattivaChart2",
                //"rate": "BollettaInterattivaBody",
                "info_e_contatti": "BollettaInterattivaContatti3",
                "banner_pubblicitario": "BollettaInterattivaBanner",
                "mancati_pagamenti": "BollettaInterattivaAvviso",
                //"fornitura": "BollettaInterattivaFornitura",
                "fornitura_duferco": "Fornitura",
                "dati_pagamento": "BollettaInterattivaDatiPagamento",
                "customer_care_duferco": "CustomerCare",
                "dati_fornitura_duferco": "BollettaInterattivaDatiFornitura",
                "profilo_duferco": "BollettaInterattivaProfiloDuferco",
                "autolettura_duferco": "BollettaInterattivaAutoletturaDuferco",
                "dati_pagamento2": "BollettaInterattivaDatiPagamento2",*/
            },
            overlayVisible: true,
            documentData: null
        }
    },
    methods: {
        payBtnClicked: function(item) {
            console.log(item);
        },
        getModule(name){
            return this.modules[name];
        },
    },
    initmodules: function (){
            this.enabledModulesLeft = ['riepilogo_doc3','scarica_pdf'],
            this.enabledModulesRight = ['dati_pagamento_testata'],
            this.enabledModules = ['multi_component_switch_3dt']
    },
    created: function () {
		var that = this;
        var requests = [];
        if(that.error == null && that.cryptoId != null) {
            if(that.cryptoId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId));
            else if(that.documentId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfoById(that.documentId));
            else {
                that.enabledModules = ['error_page'];
                return;
            }

            Promise.all(requests)
            .then(responses => {
                var obj = responses[0].data;
                obj.crypto = that.cryptoId;
                console.log("obj",obj);
                that.documentData = obj;     
                this.enabledModulesLeft = ['riepilogo_doc3','scarica_pdf'],
                this.enabledModulesRight = ['dati_pagamento_testata'],
                this.enabledModules = ['multi_component_switch_3dt']    
            })
            .catch(error => {
                that.error = error.data;
                that.enabledModules = ['error_page'];
            });
        }
        else {
            that.enabledModules = ['error_page'];
        }
    }
})
</script>