export default ({
  state: {
    components: [],
    direction: null,
  },
  mutations: {
    SET_COMPONENTS(state, payload) {
      state.components = payload;
    },
    SET_DIRECTION(state, payload) {
      state.direction = payload;
    },
  },
  getters: {
    components(state) {
      return state.components;
    },
    direction(state) {
      return state.direction;
    },
  },
  actions: {},
  modules: {},
});
