<template>
	<v-app>
		<v-navigation-drawer floating v-model="drawer" class="secondaryColorBg topHeader" app>
			<v-app-bar  class="primaryColorBg topHeader pl-6" flat>
				<router-link class="pa-0 d-flex align-center"  to="/">
					<v-img  class="mr-2" 
							style="display:inline-block" 
							contain 
							max-width="48" 
							max-height="48" 
						:src="getHeaderLogo()"/>
					<v-col class="fifthColorTx">
						<span>{{ $t("message.header_text") }}</span>
						<br>
						<span>{{ $t("message.header_text_subtitle") }}</span>
					</v-col>
				</router-link>
			</v-app-bar>
			<Menu 	display="vertical" 
					class="pt-8"
					show="vertical" 
					:value="true"
					:items="menuHeaders"/>		
		</v-navigation-drawer>
		<v-app-bar  app flat dark class="secondaryColorBg pl-6 pr-6 topHeader">
			<v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
			<v-spacer></v-spacer>
			<v-col cols="5" lg="2" sm="3"  md="3">
				<Languages  :locales="locales"></Languages>
			</v-col>
			<v-col cols="5" lg="2" sm="5"  md="3">
				<Anagrafica v-if="nameList" :name-list="nameList" 
				@cod-client-changed="codClientUpdate"></Anagrafica>
			</v-col>
		</v-app-bar>
		<v-main class="mb-10">
			<v-container>
				<transition name="component-fade" mode="out-in">
					<router-view/>
				</transition>
			</v-container>
		</v-main>
		<v-footer id="footer" :class="{ 'primaryColorBg': true, 'paddingLeft': paddingDrawer}">
			<v-container>
			<v-row>
				<v-col>
					<span v-if="$t('message.footer_text').length > 0" class="footerText" v-html="$t('message.footer_text')"></span>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<span v-if="$t('message.footer_text_second').length > 0"  class="footerTextSecond" v-html="$t('message.footer_text_second')"></span>
				</v-col>
			</v-row>
			<v-divider class="divider"></v-divider>
			<v-row class="footerTextRow">
				<v-col :cols="changeCol()" v-if="$t('message.contacts_text').length > 0">
					<span class="footerContactsText" v-html="$t('message.contacts_text')"></span>
				</v-col>
				<v-col :cols="changeCol()" v-if="$t('message.footer_free_section').length > 0">
					<span class="footerFreeSection" v-html="$t('message.footer_free_section')"></span>
				</v-col>
				<v-col :cols="changeCol()" v-if="$t('message.policy_privacy_text').length > 0">
					<span class="footerPolicyPrivacy" v-html="$t('message.policy_privacy_text')"></span>
				</v-col>
				<v-col :cols="changeCol()" class="text-right" v-if="channels && channels.length > 0">
					<span class="font-weight-bold">{{$t('message.footer_social_text')}}</span> 
					<social-channels :channels="channels"></social-channels>
				</v-col>
			</v-row>
			</v-container>
		</v-footer>
		<Snackbar ref="mySnackbar"></Snackbar> 
	</v-app>
</template>
<script>
import Menu from '../components/Menu.vue'
import Anagrafica from '../components/Anagrafica.vue'
import Snackbar from '../components/Snackbar.vue'
import SocialChannels from '../components/SocialChannels.vue'
import { services } from '../scripts/services/serviceBuilder'
import Languages from '../components/Languages.vue'

export default {
	name: 'template2',
	components: {
		Menu,
		SocialChannels,
		Anagrafica,
		Snackbar,
		Languages
	},
	props: {
	},
	data: () => ({
		channels: null,
		drawer: true,
		items: [],		
		menuHeaders: [],
		locales: [],
		nameList: null,
	}),
	computed: {
		isAuthenticated: function () {
			var result = this.getKeycloak(this.$parent);
			if (!result || !Object.prototype.hasOwnProperty.call(result, 'idTokenParsed')) {
				return false;
			}	
			return true;
		},
		paddingDrawer: function () {
			return this.drawer;
		},
	},
	methods: {
		codClientUpdate: function(value) {
			this.$cod_client.value = value;
		},
		changeCol: function () {
			if(screen.width < 600)
				return 12;
		},
		getHeaderLogo: function () {
			return './' + location.hostname + '/images/logo.png';
		},
		getKeycloak: function (node) {
			if (node == null)
				return null;
			if (node.$options.name != "App")
				return this.getKeycloak(node.$parent);
			return node.keycloak;
		},
		getNormalizedChannels: function (data) {
			if (!data || 
				data.length < 1 || 
				!data['social_link_presence'] || 
				data['social_link_presence'] == '0')
				return [];

			var that = this;
			var normalizedChannels = [];
			for (const [key, value] of Object.entries(data)) {
				if(key != 'social_link_presence' && value != null && value.length > 0) {
					normalizedChannels.push({
						icon: that.getSocialChannelIcon(key),
						tooltip: null,
						url: value
					});
				}
			}

			return normalizedChannels;
		},
		getSocialChannelIcon: function (settingName) {
			switch (settingName) {
				case 'social_link_facebook':
					return 'mdi-facebook';
				case 'social_link_instagram':
					return 'mdi-instagram';
				case 'social_link_linkedin':
					return 'mdi-linkedin';
				case 'social_link_twitter':
					return 'mdi-twitter';
				default:
					return 'mdi-help-circle';
			}
		},
	},
	created: function () {
		var that = this;	
		this.$root.$on("codClientChanged", this.codClientUpdate);
		that.menuHeaders = [];

		var requests = [];
		requests.push(services.apiCallerSocial.data());
		requests.push(services.apiCallerLocale.list());

		if(that.isAuthenticated) {
			requests.push(services.apiCallerMenu.get());
			requests.push(services.apiCallerCustomer.names());
			
		}

		Promise.all(requests)
			.then(responses => {
				that.channels = that.getNormalizedChannels(responses[0].data);

				if(that.isAuthenticated) {
					responses[2].data.forEach(element => {		
						that.menuHeaders.push(element);		
					})
					that.nameList = responses[3].data;
				}
				if(!!responses[1].data && Array.isArray(responses[1].data) && responses[1].data.length > 1){
					that.locales = responses[1].data;
					this.$i18n.locale = responses[1].data[0];
				}
			})		
	},
};
</script>

<style scoped>
	.component-fade-enter-active, .component-fade-leave-active {
		/* effetto transizione di pagina */
		transition: opacity .3s ease;
	}
	.component-fade-enter, .component-fade-leave-to {
		/* effetto transizione di pagina */
		opacity: 0;
	}
	.paddingLeft {
		padding-left: 280px !important;
	}
</style>
