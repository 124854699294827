<template>
  <v-app>
    <v-skeleton-loader type="article" tile :loading="loading">
      <!-- contenitore header principale -->
      <v-container id="headerContainer" fluid class="pa-0" style="height: 160px">
        <v-toolbar height="120" class="middleHeader" flat>
          <v-container class="wrapWidth container_style">
            <v-row align="center">
              <v-col xl="3" lg="3" md="3" sm="4"></v-col>
              <v-col xl="6" lg="6" md="6" sm="4"
                class="py-0 mt-5">
                <v-img
                  contain
                  id="FooterLogo"
                  max-width="200px"
                  :src="getHeaderLogo()"
                ></v-img>
              </v-col>
              <v-col xl="3" lg="3" md="3" sm="4"
                class="py-0 mb-2" align="right">
                <Languages :locales="locales" :dark="true" :customStyle="{width: '120px'}"></Languages>
              </v-col>              
            </v-row>
          </v-container>
        </v-toolbar>
      </v-container>
      <!--contenitore del main-->
      <v-main class="main">
        <v-container class="wrapWidth">
          <BollettaInterattiva :cryptoId="cryptoId" :documentId="documentId" :error="error"/>
        </v-container>
      </v-main>
      <!--contenitore del footer-->
      <v-footer class="footer">
        <v-container class="wrapWidth" justify="center">
          <v-row align="center" justify="center">
            <v-img
              contain
              id="FooterLogo"
              max-width="80px"
              justify="center"
              :src="getFooterLogo()"
            ></v-img>
          </v-row>

          <v-card
            class="text-center"
            max-width="100%"
            tile
            outlined
            color="transparent"
          >
            <v-list-item three-line class="justify-center">
              <v-list-item-content>
                <v-list-item-subtitle>
                  Alia Servizi Ambientali Spa
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Via Baccio da Montelupo 52 50142 Firenze
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  Reg. Imprese Firenze CF. e P.IVA 04855090488 REA n.FI 491894
                  Capitale sociale euro 85.376.852,00 i.v.
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-container>
      </v-footer>
    </v-skeleton-loader>
  </v-app>
</template>
<script>
import BollettaInterattiva from "../components/BollettaInterattiva2.vue";
import Languages from "../components/Languages.vue";
import { services } from "../scripts/services/serviceBuilder";
export default {
  name: "templateBollettaInterattiva",
  components: {
    BollettaInterattiva,
    Languages,
  },
  props: {
  
  },
  data: () => ({
    locales: [],
    loading: true,
    cryptoId: null,
    documentId: null,
    error: null
  }),
  computed: {
    isAuthenticated: function () {
      var result = this.getKeycloak(this.$parent);
      if (
        !result ||
        !Object.prototype.hasOwnProperty.call(result, "idTokenParsed")
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
      $route (to){
        this.loading = false;
        this.error = to.query.error;
        this.cryptoId = to.query.id;
        this.documentId = to.query.documentId;
      }
  },
  methods: {
    getKeycloak: function (node) {
      if (node == null) return null;
      if (node.$options.name != "App") return this.getKeycloak(node.$parent);
      return node.keycloak;
    },
    getHeaderLogo: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/header_logo.png";
    },
    getFooterLogo: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/footer_logo.png";
    }
  },
  created: function () {
    var that = this;

    var requests = [];
    requests.push(services.apiCallerLocale.list());

    Promise.all(requests).then((responses) => {
      if (
        !!responses[0].data &&
        Array.isArray(responses[0].data) &&
        responses[0].data.length > 1
      ) {
        that.locales = responses[0].data;
        that.$i18n.locale = that.$locale.value;
      }
    });
  },
};
</script>

