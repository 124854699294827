<template>
  <v-container id="bollettaInterattivaDatiFornitura" v-if="bolletta_interattiva_duferco_dati_fornitura">
      <v-spacer class="spacerExpPanelUp"></v-spacer>
      <v-expansion-panels flat>
          <v-expansion-panel>
              <v-expansion-panel-header>
                <template v-slot:actions>
                            <v-icon color="white">
                            mdi-menu-down
                            </v-icon>
                        </template>
                  <h1 style="display:flex;align-items: center;"><img v-if="true" :src="getDatiFornituraLogo()" id="datiFornituraLogo" class="pr-3" width="15%"/>{{$t('message.bolletta_interattiva_duferco_dati_fornitura_header')}}</h1>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                  <v-row>
                      <v-col xl="12" lg="12" md="12" sm="12">
                          <p v-if="showDatiFornituraTesto()" id="datiFornitura2Text" class="pt-3" align="center">{{ $t('message.bolletta_interattiva_duferco_dati_fornitura_text') }}</p>
                          <v-form>
                              <v-container>
                              <v-row v-if="fornitureFromDocumentData.length > 1">
                                  <v-col xl="12" lg="12" md="12" sm="12">
                                      <v-select
                                      label="Fornitura"
                                      :items="fornitureFromDocumentData"
                                      v-model="fornitura"
                                      required
                                      :item-text ="item => getItemSelectFornituraText(item)"
                                      return-object
                                      outlined
                                      ></v-select>
                                  </v-col>
                              </v-row>
                              <v-row v-else>
                                  <v-col xl="12" lg="12" md="12" sm="12">
                                      <div class="font-weight-medium" id="codFornitura">{{documentData.tipo_bolletta == 'GAS' ? fornitura.tipo_punto +" "+ fornitura.pdr : fornitura.tipo_punto +" "+ fornitura.pod}}</div>
                                      <div class="font-weight-medium" id="indirizzoFornitura">{{getSingleFornituraAddress()}}</div>
                                  </v-col>
                              </v-row>
                              <v-row v-if="fornitura !== null && fornitura !== undefined">
                                <v-col xl="12" lg="12" md="12" sm="12">
                                    <v-simple-table class="tableFornitura">
                                        <template v-slot:default>
                                        <tbody>
                                            <tr v-for="(item, name, i) in fornitura.dettagli_fornitura" :key="'label-'+i" class="dettagli_fornitura">
                                                    <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.' + name) ?  (name.replace("_"," ")) : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.' + name)}}</td>
                                                    <td class="text-right">{{item}}</td>
                                            </tr>
                                            <!--tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.data_attivazione_fornitura') ? "" : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.data_attivazione_fornitura')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.data_attivazione_fornitura}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.tipologia_offerta') ? "" : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.tipologia_offerta')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.tipologia_offerta}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.codice_offerta') ? "": $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.codice_offerta')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.codice_offerta}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.data_inizio_offerta') ? "": $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.data_inizio_offerta')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.data_inizio_offerta}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.potenza_impegnata') ? "" : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.potenza_impegnata')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.potenza_impegnata}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.potenza_disponibile') ? "" :$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.potenza_disponibile')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.potenza_disponibile}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.consumo_annuo') ? "" : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.consumo_annuo')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.consumo_annuo_kwh}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.consumo_annuo_contratto') ? "" : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.consumo_annuo_contratto')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.consumo_annuo_contratto}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.tensione') ? "" : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.tensione')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.tensione}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.opzione_tariffaria') ? "" : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.opzione_tariffaria')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.opzione_tariffaria}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.tipo_misuratore') ? "" :$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.tipo_misuratore')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.tipo_misuratore}}</td>
                                            </tr>
                                            <tr>
                                            <td class="text-left">{{checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.matricola_contatore') ? "" : $t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio.matricola_contatore')}}</td>
                                            <td class="text-right">{{fornitura.dettagli_fornitura.matricola_contatore}}</td>
                                            </tr-->
                                        </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-col>
                              </v-row>
                              </v-container>
                          </v-form>
                      </v-col>
                  </v-row>
              </v-expansion-panel-content>
          </v-expansion-panel>
      </v-expansion-panels>
      <v-spacer class="spacerExpPanelDown"></v-spacer>
  </v-container>
</template>

<script>
//import { services } from '../scripts/services/serviceBuilder'

export default {
  name: 'bollettaInterattivaDatiFornitura',
  data: function() {
      return {
          bolletta_interattiva_duferco_dati_fornitura : true, 
          fornitura: null,
          fornitureFromDocumentData: this.documentData.fatt_interattiva_data.fornitura,
      }
  },
  props: {
      documentData: {
          type: Object,
          default: null
      },
      checkLabel: {
          type: Function,
       },
  },
  computed: {
      
  },
  components: {
      
  },
  methods: {
      getDatiFornituraLogo: function () {
          return "./" + location.hostname + "/images/bollettainterattiva/datiFornitura2_icon.png";
      },
      getSingleFornituraAddress: function(){
        let result = "";
        this.fornitura.indirizzo.forEach(element => {
                result += element+" - ";
        });
        result = result.slice(0, -3);
        return result;
      },
      getItemSelectFornituraText: function(item){
            let result = (this.documentData.tipo_bolletta == 'GAS') ? item.tipo_punto +":"+ item.pdr +' - ' : item.tipo_punto +" "+ item.pod +' - ';
            item.indirizzo.forEach(element => {
                result += element+" - ";
            });
            result = result.slice(0, -3);
            return result;
        },      
      showDatiFornituraTesto: function(){
        let that = this;
        let text = that.$t('message.bolletta_interattiva_duferco_dati_fornitura_text');
        if(text === null || text === undefined || text?.length === 0)
          return false;
        return true;
      },
  },
  created: function () {
      let that = this;
      that.bolletta_interattiva_duferco_dati_fornitura = Boolean(Number(this.documentData.bolletta_interattiva_duferco_dati_fornitura));
      that.fornitura = that.documentData.fatt_interattiva_data.fornitura[0];
  }
}
</script>
