<template>
    <v-container id = "Bolletta_Interattiva_Riepilogo_Doc">
        <v-row>
            <v-col lg="9" md="6" sm="6">
                <p class="totalImportText" v-html="$t('message.bolletta_interattiva_doc_amount')"></p>
            </v-col>
            <v-col lg="3" md="6" sm="6">
                <p class="totalImportValue">
                    {{amountWithCurrency(documentData.document_amount)}}
                </p>
            </v-col>
        </v-row>
        <v-row v-if="parseFloat(rateApiData.totaledapagare.replace(/,/g, '.')) > 0">
            <v-col lg="9" md="6" sm="6">
                <p class="totalImportText" v-html="$t('message.bolletta_interattiva_total_to_pay')"></p>
            </v-col>
            <v-col lg="3" md="6" sm="6">
                <p class="totalImportValue">
                    {{amountWithCurrency(rateApiData.totaledapagare)}}
                </p>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col lg="12" md="12" sm="12">
                <p class="totalImportText" v-html="$t('message.bolletta_interattiva_total_paied')"></p>
            </v-col>
        </v-row>
        <v-row v-for="(item, i) in orderedRate" :key="'row-'+i">
            <v-row v-if="allItemToBePaid() && item.numero=='0'">
                <v-col lg="12" md="12" sm="6">
                <p class="totalItemToBePaidText" v-html="$t('message.bolletta_interattiva_unique_payment')"></p>
                </v-col>
                </v-row>
            <v-card class="elementContainer cardClass elevation-0" outlined>

                <v-card-text>
                    <v-row>
                        <v-col lg="7" md="6" sm="6" class="pa-0">
                            <div v-if="item.numero!='0'" class="itemIndex"><span>{{item.numero}}°</span> &nbsp;
                               <span v-html="$t('message.bolletta_interattiva_rata_acconto')"></span>
                            </div>
                            <div v-else-if="allItemToBePaid()" class="itemIndex"><span>{{$t('message.bolletta_interattiva_rata_unica')}}</span>
                            </div>
                            <div class="itemTitle ">
                                {{item.causale}}
                            </div>
                        </v-col>
                        <v-col lg="5" md="6" sm="6" class="importDateSection">
                            <div class="itemImportText" v-html="$t('message.bolletta_interattiva_importo')"></div>
                            <div class="itemImportValue">
                                {{amountWithCurrency(item.importo)}}
                            </div>
                            <div class="itemExpireDateText" v-html="$t('message.bolletta_interattiva_rata_deadline')"></div>
                            <div class="itemExpireDateValue">
                                {{item.scadenza}}
                                <a v-if="isToBePaid(item)" :href="getCalendarLink(item)" target="blank"><v-icon large>mdi-calendar</v-icon></a>
                                <a v-else :style="{pointerEvents: 'none', }" :href="getCalendarLink(item)"><v-icon large>mdi-calendar</v-icon></a>
                            </div>    
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-center pa-0">
                    <v-btn
                        depressed
                        rounded
                        class="black--text"
                        :color="getButtonColor(item)"
                        style="margin-bottom:-18px;"
                        :href="isToBePaid(item) ? item.link_pagamento : null"
                        target="blank"
                    ><span :class="{btnNotPaidText: isToBePaid(item), btnPaidText: !isToBePaid(item)}">{{getPaidText(item)}}</span>             
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
</template>
<script>

import moment from 'moment';
import { services } from '../scripts/services/serviceBuilder'

export default ({
    data() {
        return {
            rateApiData: { rate:[] }
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
    computed: { 
        orderedRate: function(){
            var newArray = [];
            var lastElement = null;
            this.rateApiData.rate.forEach(element => {
                if(element.numero != "0") {
                    newArray.push(element);
                }
                else {
                    lastElement = element
                }
            });
            if(lastElement != null)
                newArray.push(lastElement);

            return newArray;  
        }, 
        
    },
    methods: {
        allItemToBePaid: function(){
            var that = this;
            return (this.rateApiData.rate.filter(function (el) { return that.isPaid(el); }).length == 0);
        },
        isPaid: function(item) {
            return item.stato_pagamento.toUpperCase() === this.$t('message.bolletta_interattiva_paid').toUpperCase();
        },
        isToBePaid: function(item) {
            return item.stato_pagamento.toUpperCase() === this.$t('message.bolletta_interattiva_to_be_pay').toUpperCase();
        },
        isCanceled: function(item) {
            return item.stato_pagamento.toUpperCase() === this.$t('message.bolletta_interattiva_canceled').toUpperCase();
        },
        getButtonColor: function(item) {
            return this.isToBePaid(item) ? "btnNotPaid" : "btnPaid";
        },
        getPaidText: function(item) {
            if(this.isPaid(item))
                return this.$t('message.bolletta_interattiva_paid');
            if(this.isToBePaid(item))
                return this.$t('message.bolletta_interattiva_pay_now');
            if(this.isCanceled(item))
                return this.$t('message.bolletta_interattiva_canceled');
        },
        amountWithCurrency: function (_import) {
            return !isNaN(_import.replace(".","").replace(",","."))? _import + " €" : _import;
        },
        getCalendarLink: function (item) {
            let ret = "https://calendar.google.com/calendar/u/0/r/eventedit?dates=";
            if (item.scadenza) {
                let start = moment(String(item.scadenza)+"-10:00", "DD/MM/YYYY-HH:mm").format('YYYYMMDDTHHmmSS');
                let end = moment(String(item.scadenza)+"-10:30", "DD/MM/YYYY-HH:mm").format('YYYYMMDDTHHmmSS');

                ret += start + "/" + end + "&text=scadenza+rata+&details=rata+" + item.importo + "+euro"
            }
            return ret;
        }
    },
    created: function () {
		var that = this;

        services.apiCallerBollettaInterattiva.rate(that.documentData.crypto)
            .then(response => { 
                that.rateApiData = response.data.data;
            })
            .catch(error => {
                console.log(error);
            });
    }
})
</script>
