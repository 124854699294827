<template>   
  <div>
    <v-select v-if="nameList && nameList.length > 1"
      background-color="secondaryColorBg" 
      :items="nameList" 
      item-text="name" 
      item-value="code"
      v-model="cod_client"
      class="selectAnagrafica"
      flat dark
      tile
      hide-details
      prepend-icon="mdi-account-circle"
      :label="cod_client" 
      filled
      v-on:change="changeCodClient"></v-select>
    <v-text-field v-else
      background-color="secondaryColorBg" 
      readonly
      v-model="name_client"
      class="selectAnagrafica"
      flat dark
      tile
      hide-details
      
      :label="cod_client" 
      filled></v-text-field>

  </div>
</template>
<script>

  export default {
    name: 'anagrafica',
    data: function () {
      return {
        cod_client: null,
        name_client: null
      };
    },
    props: {
      nameList: null
    },
    methods: {
      changeCodClient: function(value) {
        this.$root.$emit('codClientChanged', value);
      }      
    },
    created: function (){
      if (this.nameList && this.nameList.length > 0) {
        this.cod_client = this.nameList[0].code;
        this.name_client = this.nameList[0].name;
      }
      this.$root.$emit('codClientChanged', this.cod_client);
    }
  }
</script>