<template>
        <div class="MainBollettaInterattiva5Dt">
            <div class="MainFirstSectionModuleBollettaInterattiva5Dt">
            <v-container class="wrapWidth ContainerFirstSectionModuleBollettaInterattiva5Dt">
                <template>
                    <v-row class="FirsSectionModuleBollettaInterattiva5Dt">
                        <v-col cols="4">
                            <component v-for="(em, i) in enabledModulesLeft" :key="'component-left-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :translateLabel="translateLabel" :getHeaderLogo="getHeaderLogo" :documentId="documentId" :cryptoId="cryptoId"></component>
                        </v-col>
                        <v-col cols="8" class="py-0">
                            <component v-for="(em, i) in enabledModulesRight" :key="'component-right-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :translateLabel="translateLabel"></component>
                        </v-col>
                    </v-row>  
                </template>
            </v-container>
            </div>
            
            <v-spacer class="spacerBollettaInterattiva5Dt"></v-spacer>

            <div class="MainSecondSectionModuleBollettaInterattiva5Dt">
                <v-container class="wrapWidth ContainerSecondSectionModuleBollettaInterattiva5Dt">
                    <template>
                        <v-row class="SecondSectionModuleBollettaInterattiva5Dt">
                            <component v-for="(em, i) in enabledModules" :key="'component-' + i" :is="getModule(em)" :documentData="documentData" :error="error" :translateLabel="translateLabel" :modules="modules" :getImg="getImg"></component>    
                        </v-row>       
                    </template>
                </v-container>
            </div>
    </div>
</template>

<script>
import Header5Dt from '../../BollettaInterattiva5/Desktop/Header5Dt.vue';
import MultiComponentSwitch5Dt from '../../BollettaInterattiva5/Desktop/MultiComponentSwitch5Dt.vue';
import MultiComponentSwitchContainer5Dt from '../../BollettaInterattiva5/Desktop/MultiComponentSwitchContainer5Dt.vue';

import ErrorPage from "../../../components/ErrorPage.vue";

import Bolletta_Interattiva_Riepilogo_Doc5Dt from './Bolletta_Interattiva_Riepilogo_Doc5Dt.vue';
import BollettaInterattivaWebTile5Dt from './BollettaInterattivaWebTile5Dt.vue';
import BollettaInterattivaInfoPagamento5Dt from './BollettaInterattivaInfoPagamento5Dt.vue';
import BollettaInterattivaWarning5Dt from './BollettaInterattivaWarning5Dt.vue';
import BollettaInterattivaDownload5Dt from './BollettaInterattivaDownload5Dt.vue';
import BollettaInterattivaFornituraCosti5Dt from './BollettaInterattivaFornituraCosti5Dt.vue';
import BollettaInterattivaAreaRiservata5Dt from './BollettaInterattivaAreaRiservata5Dt.vue';
import BollettaInterattivaFastActionTile5Dt from './BollettaInterattivaFastActionTile5Dt.vue';
import BollettaInterattivaMieiDatiMioContratto5Dt from './BollettaInterattivaMieiDatiMioContratto5Dt.vue';
import BollettaInterattivaFornituraConsumi5Dt from './BollettaInterattivaFornituraConsumi5Dt.vue';


import { services } from '../../../scripts/services/serviceBuilder'


export default ({
    components: {
        //LEFT
        Header5Dt,

        //RIGHT
        MultiComponentSwitch5Dt,

        //CENTER
        MultiComponentSwitchContainer5Dt,
        BollettaInterattivaWebTile5Dt,
        Bolletta_Interattiva_Riepilogo_Doc5Dt,
        BollettaInterattivaInfoPagamento5Dt,
        BollettaInterattivaWarning5Dt,
        BollettaInterattivaDownload5Dt,
        BollettaInterattivaFornituraCosti5Dt,
        BollettaInterattivaAreaRiservata5Dt,
        BollettaInterattivaFastActionTile5Dt,
        BollettaInterattivaMieiDatiMioContratto5Dt,
        BollettaInterattivaFornituraConsumi5Dt,

        ErrorPage
    },
    props:{
      cryptoId: {
        type: String,
        default: null
      },
      documentId: {
        type: String,
        default: null
      },
      error: {
        type: Number,
        default: null
      },
      translateLabel: {
        type: Function,
      },
      getHeaderLogo:  {
        type: Function,
      },
      getImg:  {
        type: Function,
      },
    },
    data() {
        return {
            enabledModules:[],
            enabledModulesLeft:[],
            enabledModulesRight:[],
            modules: {
                /*"riepilogo_doc3": "Bolletta_Interattiva_Riepilogo_Doc5Dt",
                "scarica_pdf": "BollettaInterattivaWebTile5Dt",
                "dati_pagamento_testata": "BollettaInterattivaDatiPagamentoTestata5Dt",
                "dati_pagamento_sintesi": "BollettaInterattivaDatiPagamentoBody5Dt",
                "consumi": "BollettaInterattivaFornitura5Dt",
                "dati_fornitura_duferco": "BollettaInterattivaDatiFornitura5Dt",
                "profilo_duferco": "BollettaInterattivaProfiloDuferco5Dt",
                "customer_care_duferco": "BollettaInterattivaCustomerCare5Dt",
                "autolettura_duferco": "BollettaInterattivaAutoletturaDuferco5Dt",*/
                
                "multi_component_switch_5Dt": "MultiComponentSwitch5Dt",
                "multi_component_switch_Container_5Dt": "MultiComponentSwitchContainer5Dt",
                "header": "Header5Dt",

                "error_page": "ErrorPage",

                //submodules
                "riepilogo_doc":"Bolletta_Interattiva_Riepilogo_Doc5Dt",
                "web_pdf": "BollettaInterattivaWebTile5Dt",
                "info_pagamento": "BollettaInterattivaInfoPagamento5Dt",
                "warning": "BollettaInterattivaWarning5Dt",
                "scarica_pdf": "BollettaInterattivaDownload5Dt",
                "consumi": "BollettaInterattivaFornituraConsumi5Dt",
                "area_riservata": "BollettaInterattivaAreaRiservata5Dt",
                "fast_action": "BollettaInterattivaFastActionTile5Dt",
                "help": "BollettaInterattivaHelpTile5Dt",
                "miei_dati_mio_contratto": "BollettaInterattivaMieiDatiMioContratto5Dt",
                "costi": "BollettaInterattivaFornituraCosti5Dt",

            },
            overlayVisible: true,
            documentData: null
        }
    },
    methods: {
        payBtnClicked: function(item) {
            console.log(item);
        },
        getModule(name){
            return this.modules[name];
        },
        initmodules: function (){
            this.enabledModulesLeft = ['header'],
            this.enabledModulesRight = ['multi_component_switch_5Dt'],
            this.enabledModules = ['multi_component_switch_Container_5Dt']
        },
    },
    created: function () {
		var that = this;
        var requests = [];
        if(that.error == null && that.cryptoId != null) {
            if(that.cryptoId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId));
            else if(that.documentId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfoById(that.documentId));
            else {
                that.enabledModules = ['error_page'];
                return;
            }

            Promise.all(requests)
            .then(responses => {
                var obj = responses[0].data;
                obj.crypto = that.cryptoId;
                console.log("obj",obj);
                that.documentData = obj;     
                this.initmodules();
            })
            .catch(error => {
                that.error = error.data;
                that.enabledModules = ['error_page'];
            });
        }
        else {
            that.enabledModules = ['error_page'];
        }
    }
})
</script>