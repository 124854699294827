<template>
    <v-container id="Bolletta_Interattiva_Riepilogo_Doc3" >
        <v-row class="mainRows">
            <div :class="documentData.bolletta_interattiva_esponi_2colonne==1 ?'col sectionParteSuperiore':'monocol sectionParteSuperiore'">
                <v-list-item three-line class="justify-center">
                    <v-list-item-avatar v-if="userImageExists">
                        <v-img class="holderImage" :src="getUserImage()" @error="userImageError"/>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <!--v-list-item-title v-if="documentData.holder_name" class="holderName text-wrap">
                            {{documentData.holder_name}}
                        </v-list-item-title>
                        <v-list-item-subtitle v-if="documentData.holder_ind_riga2" class="holderIndRiga2 text-wrap">
                            {{documentData.holder_ind_riga2}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="documentData.holder_ind_riga3" class="holderIndRiga3 text-wrap">
                            {{documentData.holder_ind_riga3}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="documentData.holder_toponimo" class="holderToponimo text-wrap">
                            {{documentData.holder_toponimo}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="documentData.holder_frazione" class="holderFrazione text-wrap">
                            {{documentData.holder_frazione}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="holderCapLocProv" class="holderCapLocProv text-wrap">
                            {{holderCapLocProv}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="documentData.holder_nazione" class="holderNazione text-wrap">
                            {{documentData.holder_nazione}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-for="(item, i) in documentData.index_fields" :key="'label'+i" 
                            class="holderFileds text-wrap">
                        <span :class=item.label>
                            {{$t("message." + item.label)}} {{item.value}}
                            </span>&nbsp;
                        </v-list-item-subtitle-->
                        <v-list-item v-if="checkInArray('invoices_codice_cliente')">
                            <div class="invoices_codice_cliente">
                                <span class="invoices_codice_cliente_label">{{$t('message.bolletta_interattiva_codice_cliente')}}:</span>&nbsp;
                                <span class="invoices_codice_cliente">{{valueInArray('invoices_codice_cliente')}}</span>
                            </div>
                        </v-list-item>

                        <v-list-item v-if="documentData.holder_name && checkInArray('invoices_holder_name')">
                            <div class="invoices_holderName">
                                <span class="invoices_holderName_label">{{$t('message.bolletta_interattiva_holder_name')}}:</span>
                                <span class="invoices_holderName">{{documentData.holder_name}}</span>
                            </div>
                        </v-list-item>

                        <!--v-list-item-subtitle v-if="documentData.holder_ind_riga2" class="holderIndRiga2 text-wrap">
                            {{documentData.holder_ind_riga2}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="documentData.holder_ind_riga3" class="holderIndRiga3 text-wrap">
                            {{documentData.holder_ind_riga3}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="documentData.holder_toponimo" class="holderToponimo text-wrap">
                            {{documentData.holder_toponimo}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="documentData.holder_frazione" class="holderFrazione text-wrap">
                            {{documentData.holder_frazione}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="holderCapLocProv" class="holderCapLocProv text-wrap">
                            {{holderCapLocProv}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-if="documentData.holder_nazione" class="holderNazione text-wrap">
                            {{documentData.holder_nazione}}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle v-for="(item, i) in documentData.index_fields" :key="'label'+i" 
                            class="holderFileds text-wrap">
                        <span :class=item.label>
                            {{$t("message." + item.label)}} {{item.value}}
                            </span>&nbsp;
                        </v-list-item-subtitle-->
                    </v-list-item-content>
                </v-list-item>
            </div>
            <v-spacer class="spacerRiepilogo_Doc3"></v-spacer>
            <div :class="documentData.bolletta_interattiva_esponi_2colonne==1 ?'col sectionParteInferiore':'monocol sectionParteInferiore'" 
            lg="12">
                <v-col v-if="imageExists" class="sectionServiceImage">
                    <div class="imageContainer">
                        <v-img contain class="serviceImage" :src="getServiceLogo()" @error="imageError" />
                    </div>
                </v-col>
                <!--v-col v-if="documentData.document_type && documentData.bolletta_interattiva_esponi_nome_doc==1" class="document_type_section">
                    <span class="document_type subtitle-2">{{$t("message." + documentData.document_type)}}</span>
                </v-col>
                <v-col v-if="documentData.document_number">
                    <div class="document_number_section">
                        <span class="document_number_label subtitle-2">{{$t('message.bolletta_interattiva_n_doc_label')}}</span>&nbsp;
                        <span class="document_number subtitle-2">{{documentData.document_number}}</span>
                    </div>
                </v-col>
                <v-col v-if="documentData.document_date">
                    <div class="document_date_section">
                        <span class="document_date_label subtitle-2">{{$t('message.bolletta_interattiva_data_label')}}</span>&nbsp;
                        <span class="document_date subtitle-2">{{documentData.document_date}}</span>
                    </div>
                </v-col>
                <v-col v-if="documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio && documentData.bolletta_interattiva_esponi_periodo_fatturazione == 1">
                    <span class="descrizione_servizio_label subtitle-2">{{$t('message.bolletta_interattiva_label_descrizione_servizio')}}</span>&nbsp;
                </v-col>
                <v-col v-if="documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio && documentData.bolletta_interattiva_esponi_periodo_fatturazione == 1">
                    <span class="descrizione_servizio subtitle-2">{{documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio}}</span>
                </v-col-->

                <v-list-item v-if="checkInArray('invoices_n_avviso') && documentData.document_number">
                            <div class="invoices_n_avviso">
                                <span class="invoices_n_avviso_label">{{$t('message.bolletta_interattiva_fatt_n_doc_label')}}</span>&nbsp;
                                <span class="invoices_n_avviso">{{documentData.document_number}}</span>
                            </div>
                </v-list-item>

                <v-list-item v-if="checkInArray('invoices_data_emissione') && documentData.document_date">
                            <div class="invoices_data_emissione">
                                <span class="invoices_data_emissione_label">{{$t('message.bolletta_interattiva_invoices_data_emissione')}}</span>&nbsp;
                                <span class="invoices_data_emissione">{{documentData.document_date}}</span>
                            </div>
                </v-list-item>
              
<!--              <v-list-item v-if="checkInArray('invoices_descrizione_servizio') && documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio">-->
<!--                            <div class="descrizione_servizio">-->
<!--                                <span class="descrizione_servizio_label">{{$t('message.bolletta_interattiva_label_descrizione_servizio3')}}:</span>&nbsp;-->
<!--                                <span class="descrizione_servizio">{{documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio}}</span>-->
<!--                            </div>-->
<!--                        </v-list-item>-->

              <v-list-item v-if="(documentData.bolletta_interattiva_esponi_periodo_fatturazione == 1) && documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio">
                            <div class="descrizione_servizio">
                                <span class="periodo_fatturazione_label">{{$t('message.bolletta_interattiva_label_periodo_fatturazione')}}:</span>&nbsp;
                                <span class="periodo_fatturazione">{{documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio}}</span>
                            </div>
                        </v-list-item>
            </div>
        </v-row>    
    </v-container>
</template>

<script>
 
export default ({
    data() {
        return {
            imageExists: true,
            userImageExists: true
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: { 
        holderCapLocProv() {
            if (this.documentData.holder_cap || this.documentData.holder_localita || this.documentData.holder_provincia) {
                return this.documentData.holder_cap + ' ' + this.documentData.holder_localita + ' (' + this.documentData.holder_provincia + ')'
            }
            return null;
        }        
    },
    methods: {
        getServiceLogo: function() {
            if (this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio) {
                return './' + location.hostname + '/images/bollettainterattiva/' + this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio;
            }
            else {
                this.imageExists = false;
            }
        },
        getUserImage: function() {
            return './' + location.hostname + '/images/bollettainterattiva/ProfileIcon.png';
        },
        imageError: function() {
            this.imageExists = false;
        },
        userImageError: function() {
            this.userImageExists = false;
        },
        checkInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).some(item => item.label.toUpperCase() === field_name.toUpperCase());
        },
        valueInArray: function (field_name)
        {
            if(!this.documentData)
                return false;
            return Array.from(this.documentData.index_fields).find(item => item.label === field_name).value;
        },
    },
})
</script>

