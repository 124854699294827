<template>
    <!-- <v-container v-if="bolletta_interattiva_dett_forn_header">
        <FornituraCard v-for="(pod, index) in forniture" :item="pod" :key="index" :documentData="documentData" :show="show[index] " @openClose="openHandler" :checkLabel="checkLabel"></FornituraCard>
    </v-container> -->

     <v-container id="FornituraContainer" >
        <div>
            <p class="text-h5 HeaderMultiComponent" v-if="bolletta_interattiva_dett_forn_header">{{$t('message.bolletta_interattiva_dett_forn_consumi')}}</p>
        </div>
            <v-row>
                <v-col xl="12" lg="12" md="12" sm="12">
                    <v-form>
                        <v-container>
                        <v-row v-if="forniture.length > 1">
                            <v-col xl="12" lg="12" md="12" sm="12">
                                <v-select
                                label="Fornitura"
                                :items="forniture"
                                v-model="fornitura"
                                required
                                :item-text ="item => getItemSelectFornituraText(item)"
                                return-object
                                outlined
                                @input="changedFornituraSelected"
                                ></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="fornitura !== null && fornitura !== undefined">
                            <BollettaInterattivaFornituraCard3Dt :item="fornitura"  :documentData="documentData" :checkLabel="checkLabel"  ></BollettaInterattivaFornituraCard3Dt>
                        </v-row>
                        </v-container>
                    </v-form>
                </v-col>
            </v-row>

  </v-container>
</template>

<script>
//import { nextTick } from 'vue/types/umd';
//import { nextTick } from 'vue';

//import { services } from '../scripts/services/serviceBuilder'
import BollettaInterattivaFornituraCard3Dt from './BollettaInterattivaFornituraCard3Dt.vue';
export default {
    name: 'Fornitura',
    components:
    {
        BollettaInterattivaFornituraCard3Dt
    },
    data: function() {
        return {
            bolletta_interattiva_dett_forn_header:false,
            forniture: {},
            fornitura: null,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: {

    },
    methods: {
      
        getItemSelectFornituraText: function(item){
            let result = (this.documentData.tipo_bolletta == 'GAS') ? item.tipo_punto +":"+ item.pdr +' - ' : item.tipo_punto +" "+ item.pod +' - ';
            item.indirizzo.forEach(element => {
                result += element+" - ";
            });
            result = result.slice(0, -3);
            return result;
        },    
        getSingleFornituraAddress: function(){
        let result = "";
        this.fornitura.indirizzo.forEach(element => {
                result += element+" - ";
        });
        result = result.slice(0, -3);
        return result;
        },
      changedFornituraSelected: async function(value) {
        await this.$nextTick();
        this.$root.$emit('changedFornituraSelected', value);
        },
            
    },
	created: function () {
        let that = this;
		that.bolletta_interattiva_dett_forn_header = Boolean(Number(that.documentData.bolletta_interattiva_dett_forn_header));

        
        that.forniture = that.documentData.fatt_interattiva_data.fornitura;
        that.fornitura = that.forniture[0];
	}
}
</script>
