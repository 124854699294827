import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    getNewMessages(customerId) {
        const url = this.webApiBaseUrl + "/api/message/new/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    getHeaders() {
        const url = this.webApiBaseUrl + "/api/message/headers";
        return this.apiCallerHelper.callGet(url);
    }

    getMessages(customerId) {
        const url = this.webApiBaseUrl + "/api/message/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    getMessageDetail(id) {
        const url = this.webApiBaseUrl + "/api/message/detail/" + id;
        return this.apiCallerHelper.callGet(url);
    }

    deleteMessage(id) {
        const url = this.webApiBaseUrl + "/api/message/" + id;
        return this.apiCallerHelper.callDelete(url);
    }

    getMessagesHome(customerId) {
        const url = this.webApiBaseUrl + "/api/message/home/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

}