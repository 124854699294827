
<template>
  <v-container id="bollettaInterattivaChart">
   <v-card class="elevation-0">
      <slide-up-down :active="showThisModule" class="text-center">
        <h1 class="titleHeader">{{ $t('message.bolletta_interattiva_sintesi_importi_header') }}</h1>
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-list>
                <v-list-item v-for="(item, i) in chartData.data.labels"
                    :key="'label-'+i" class="labelItem">
                    <v-list-item-subtitle class="pa-0">
                        <v-avatar
                          :color="'labelColor' + ((i % maxColor) + 1)"
                          size="10"
                        ></v-avatar>
                        {{ item }}
                        <span class="labelItemImport"> {{ amountWithCurrency(i) }}</span>
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
          </v-list-item-content>
        </v-list-item>
        <div v-if="documentData.bolletta_interattiva_grafico_sintesi_importi==1" style="margin:auto; relative; width: 300px">
          <canvas id="donut-canvas" ref="donut-canvas"> </canvas>
        </div>
      </slide-up-down>
        <v-card-actions class="d-flex justify-center pa-0;">
          <v-btn
                 depressed
                 rounded
                 class="btnCloseOpen black--text justify-center"
                 @click="showModule"
                 style="margin-bottom:-37px;">
            {{ showThisModule ? '- ' + $t('message.bolletta_interattiva_bottone_dettagli_close') : '+ ' + $t('message.bolletta_interattiva_bottone_dettagli_open') }}
          </v-btn>
        </v-card-actions>
   </v-card>
  </v-container>
</template>

<script>
import Chart from "chart.js";
import SlideUpDown from 'vue-slide-up-down'

export default {
  data: () => ({
    showThisModule: false,
    donutCanvas: null,
    chartVariable: null,
    chartData: {
      type: "doughnut",
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverOffset: 0,
          },
        ],
      },
      options: {
        legend: {
          display: false,
          position: "left",
        },
      },
    },
    maxColor: 10
  }),
  components: {
        SlideUpDown
  },
  props: {
    documentData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getElementColor: function (index) {
      return this.chartData.data.datasets[0].backgroundColor[index];
    },
    amountWithCurrency: function (index) {

      var formatted_value = !isNaN(this.chartData.data.datasets[0].data[index])?
        this.chartData.data.datasets[0].data[index] : 0;

      //TODO formatAmount(header.format_show, documentInfo.currency_sigle);
      formatted_value = new Intl.NumberFormat('it-IT').format(formatted_value);      
      return '€ ' + formatted_value;
    },
    populateData: function() {
      this.documentData.fatt_interattiva_data.sintesi_importi.forEach(item => {
        for (const [key, value] of Object.entries(item)) {
          this.chartData.data.labels.push(key);
          this.chartData.data.datasets[0].data.push(value.replace(".","").replace(",","."));
        }
      });
    },
    showModule: function() {
      this.showThisModule = !this.showThisModule;
      if(this.showThisModule) {
        this.createChart();
      }
    },
    createChart: function() {
      const ctx = this.$refs["donut-canvas"];// document.getElementById("donut-chart2");
      for(var i=0; i<this.documentData.fatt_interattiva_data.sintesi_importi.length; i++)
      {
          let para = document.querySelector('#bollettaInterattivaChart .labelColor' + ((i % this.maxColor) + 1));
          let current_color = getComputedStyle(para).getPropertyValue("background-color");
          this.chartData.data.datasets[0].backgroundColor.push(current_color);
      }
      this.chartVariable = new Chart(ctx, this.chartData);
      this.donutCanvas = ctx;
    }
  },
  created() {
    this.populateData();
  },
  mounted() {
    this.createChart();
  },
};
</script>
