<template>
  <v-container id="bollettaInterattivaBanner" class="pa-0">
    <a :href="getLink()" :class="{ 'disabled' : (!getLink() || getLink().length===0) }">
       <div v-if="imageExists" class="imageContainer">
        <v-img contain
              id="BannerImage" 
              :src="getImage()"
              @error="imageError"
        />      
       </div>
       </a>
  </v-container>
</template>

<script>
export default {
 data() {
   return {
    imageExists: true
   }
 },
 props: {
      documentData: {
          type: Object,
          default: null
      }
  },
 methods: {
    getImage: function () {
      var url = "./" + location.hostname + "/images/bollettainterattiva/bolletta_interattiva_banner.jpg";    
      return url;
    },
    getLink: function() {
      return this.documentData.bolletta_interattiva_banner_link;
    },
    imageError: function() {
      this.imageExists = false;
    }
 }
}
</script>
