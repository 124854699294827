<template>
  <v-container id="BollettaInterattivaDatiFornitura3Dt" v-if="bolletta_interattiva_duferco_dati_fornitura">
        <div>
            <p class="text-h5 HeaderMultiComponent">{{$t('message.bolletta_interattiva_duferco_dati_fornitura_header')}}</p>
        </div>
                  <v-row>
                      <v-col xl="12" lg="12" md="12" sm="12">
                          <p v-if="showDatiFornituraTesto()" id="datiFornitura2Text" class="pt-3" align="center">{{ $t('message.bolletta_interattiva_duferco_dati_fornitura_text') }}</p>
                          <v-form>
                              <v-container>
                              <v-row v-if="fornitureFromDocumentData.length > 1">
                                  <v-col xl="12" lg="12" md="12" sm="12">
                                      <v-select
                                      label="Fornitura"
                                      :items="fornitureFromDocumentData"
                                      v-model="fornitura"
                                      required
                                      :item-text ="item => getItemSelectFornituraText(item)"
                                      return-object
                                      outlined
                                      ></v-select>
                                  </v-col>
                              </v-row>
                              <!--v-row v-else>
                                  <v-col xl="12" lg="12" md="12" sm="12">
                                      <div class="font-weight-medium" id="codFornitura">{{documentData.tipo_bolletta == 'GAS' ? fornitura.tipo_punto +" "+ fornitura.pdr : fornitura.tipo_punto +" "+ fornitura.pod}}</div>
                                      <div class="font-weight-medium" id="indirizzoFornitura">{{getSingleFornituraAddress()}}</div>
                                  </v-col>
                              </v-row-->
                              <v-row v-if="fornitura !== null && fornitura !== undefined" justify="center"  cols="12">
                                <v-spacer></v-spacer>
                                <v-col cols="5">
                                  <v-row v-for="(values, a) in ArrayValuesLeft" :key="a" >
                                        <BollettaInterattivaDatiFornituraCard3Dt :item ="values" :documentData="documentData" :checkLabel="checkLabel"/>  
                                  </v-row>
                                </v-col> 
                                <v-spacer cols="1"></v-spacer>
                                <v-col cols="5">
                                   <v-row v-for="(values, a) in ArrayValuesRight" :key="a" >
                                        <BollettaInterattivaDatiFornituraCard3Dt :item ="values" :documentData="documentData" :checkLabel="checkLabel"/>  
                                  </v-row> 
                                </v-col>
                                <v-spacer></v-spacer>
                              </v-row>
                              </v-container>
                          </v-form>
                      </v-col>
                  </v-row>
  </v-container>
</template>

<script>
//import { services } from '../scripts/services/serviceBuilder'
import BollettaInterattivaDatiFornituraCard3Dt from "./BollettaInterattivaDatiFornituraCard3Dt.vue";
export default {
  name: 'BollettaInterattivaDatiFornitura3Dt',
  data: function() {
      return {
          bolletta_interattiva_duferco_dati_fornitura : true, 
          fornitura: null,
          fornitureFromDocumentData: this.documentData.fatt_interattiva_data.fornitura,
          
          imgFornitura: true,
          ArrayValuesLeft: null,
          ArrayValuesRight: null
      }
  },
  props: {
      documentData: {
          type: Object,
          default: null
      },
      checkLabel: {
          type: Function,
       },
  },
  computed: {
      
  },
  components: {
      BollettaInterattivaDatiFornituraCard3Dt
  },
  methods: {
    getImgFornitura: function () {
        return "./" + location.hostname + "/images/bollettainterattiva/"+this.documentData.fatt_interattiva_data.header.logo_servizio;
    },
      getDatiFornituraLogo: function () {
          return "./" + location.hostname + "/images/bollettainterattiva/datiFornitura2_icon.png";
      },
      getSingleFornituraAddress: function(){
        let result = "";
        this.fornitura.indirizzo.forEach(element => {
                result += element+" - ";
        });
        result = result.slice(0, -3);
        return result;
      },
      getSingleFornituraAddressBySpace: function(){
        let result = "";
        this.fornitura.indirizzo.forEach(element => {
                result += element+" ";
        });
        result = result.slice(0, -1);
        return result;
      },
      getSingleFornituraAddressIndex: function(index){
        if(this.fornitura.indirizzo.length >= index + 1){
            return this.fornitura.indirizzo[index];
        }else{
            return null;
        }
      },
      getItemSelectFornituraText: function(item){
            let result = (this.documentData.tipo_bolletta == 'GAS') ? item.tipo_punto +":"+ item.pdr +' - ' : item.tipo_punto +" "+ item.pod +' - ';
            item.indirizzo.forEach(element => {
                result += element+" - ";
            });
            result = result.slice(0, -3);
            return result;
        },      
      showDatiFornituraTesto: function(){
        let that = this;
        let text = that.$t('message.bolletta_interattiva_duferco_dati_fornitura_text');
        if(text === null || text === undefined || text?.length === 0)
          return false;
        return true;
      },
  },
  created: function () {
    let that = this;
    that.bolletta_interattiva_duferco_dati_fornitura = Boolean(Number(this.documentData.bolletta_interattiva_duferco_dati_fornitura));
    that.fornitura = that.documentData.fatt_interattiva_data.fornitura[0];
    if(this.documentData.tipo_bolletta != 'GAS')
    {
        this.ArrayValuesLeft = [
        {
            img: "./" + location.hostname + "/images/bollettainterattiva/desktop_fornitura_ee_1.svg",
            fields: [
                    {
                        labelTitle: this.fornitura.tipo_punto,
                        labelText: (this.fornitura.pod ) ? this.fornitura.pod : null,
                    },
                    {
                        labelTitle: null,
                        labelText: (this.documentData.holder_name || this.documentData.holder_ind_riga2 || this.documentData.holder_ind_riga3) ? 
                            (this.documentData.holder_name ? this.documentData.holder_name : '') + (this.documentData.holder_ind_riga2 ? ' ' + this.documentData.holder_ind_riga2 : '') + (this.documentData.holder_ind_riga3 ? ' ' + this.documentData.holder_ind_riga3 : '') : null,
                    },
                    {
                        labelTitle: null,
                        labelText: this.getSingleFornituraAddressIndex(0)
                    },
                    {
                        labelTitle: null,
                        labelText: this.getSingleFornituraAddressIndex(1)
                    }
                ]
        },
        {
            img: "./" + location.hostname + "/images/bollettainterattiva/desktop_fornitura_ee_2.svg",
            fields: [
                    {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.data_attivazione_fornitura') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.data_attivazione_fornitura'),
                        labelText: this.fornitura.dettagli_fornitura.data_attivazione_fornitura,
                    },
                    {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tipologia_offerta') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tipologia_offerta'),
                        labelText: this.fornitura.dettagli_fornitura.tipologia_offerta,
                    },
                    {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.codice_offerta') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.codice_offerta'),
                        labelText: this.fornitura.dettagli_fornitura.codice_offerta,
                    },
                    {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.data_inizio_offerta') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.data_inizio_offerta'),
                        labelText: this.fornitura.dettagli_fornitura.data_inizio_offerta,
                    }
                ]
        }
    
        ];
        this.ArrayValuesRight =  [
            {
                img: "./" + location.hostname + "/images/bollettainterattiva/desktop_fornitura_ee_3.svg",
                fields: [
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.potenza_impegnata') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.potenza_impegnata'),
                            labelText: this.fornitura.dettagli_fornitura.potenza_impegnata,
                        }, {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.potenza_disponibile') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.potenza_disponibile'),
                            labelText: this.fornitura.dettagli_fornitura.potenza_disponibile,
                        }, {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.consumo_annuo') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.consumo_annuo'),
                            labelText: this.fornitura.dettagli_fornitura.consumo_annuo,
                        }, {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.consumo_annuo_contratto') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.consumo_annuo_contratto'),
                            labelText: this.fornitura.dettagli_fornitura.consumo_annuo_contratto,
                        }
                    ]
            },
            {
                img: "./" + location.hostname + "/images/bollettainterattiva/desktop_fornitura_ee_4.svg",
                fields: [
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tensione') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tensione'),
                            labelText: this.fornitura.dettagli_fornitura.tensione,
                        },
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.opzione_tariffaria') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.opzione_tariffaria'),
                            labelText: this.fornitura.dettagli_fornitura.opzione_tariffaria,
                        },
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tipo_misuratore') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tipo_misuratore'),
                            labelText: this.fornitura.dettagli_fornitura.tipo_misuratore,
                        },
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.matricola_contatore') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.matricola_contatore'),
                            labelText: this.fornitura.dettagli_fornitura.matricola_contatore,
                        }
                    ]
            }
        
        ];
    }
    else
    {
         this.ArrayValuesLeft = [
        {
            img: "./" + location.hostname + "/images/bollettainterattiva/desktop_fornitura_gas_1.svg",
            fields: [
                    {
                        labelTitle: this.fornitura.tipo_punto,
                        labelText: (this.fornitura.pdr) ? this.fornitura.pdr : null,
                    },
                      {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.codice_remi') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.codice_remi'),
                        labelText: (this.fornitura.dettagli_fornitura.codice_remi) ? this.fornitura.dettagli_fornitura.codice_remi : null,
                    },
                    {
                        labelTitle: null,
                        labelText: (this.documentData.holder_name || this.documentData.holder_ind_riga2 || this.documentData.holder_ind_riga3) ? 
                            (this.documentData.holder_name ? this.documentData.holder_name : '') + (this.documentData.holder_ind_riga2 ? ' ' + this.documentData.holder_ind_riga2 : '') + (this.documentData.holder_ind_riga3 ? ' ' + this.documentData.holder_ind_riga3 : '') : null,
                    },
                    {
                        labelTitle: null,
                        labelText: this.getSingleFornituraAddressBySpace()
                    }
                ]
        },
        {
            img: "./" + location.hostname + "/images/bollettainterattiva/desktop_fornitura_gas_2.svg",
            fields: [
                    {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.data_attivazione_fornitura') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.data_attivazione_fornitura'),
                        labelText: (this.fornitura.dettagli_fornitura.data_attivazione_fornitura) ? this.fornitura.dettagli_fornitura.data_attivazione_fornitura : null,
                    },
                    {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tipologia_offerta') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tipologia_offerta'),
                        labelText: (this.fornitura.dettagli_fornitura.tipologia_offerta) ? this.fornitura.dettagli_fornitura.tipologia_offerta : null,
                    },
                    {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.codice_offerta') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.codice_offerta'),
                        labelText: (this.fornitura.dettagli_fornitura.codice_offerta) ? this.fornitura.dettagli_fornitura.codice_offerta:null,
                    },
                    {
                        labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.data_inizio_offerta') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.data_inizio_offerta'),
                        labelText: (this.fornitura.dettagli_fornitura.data_inizio_offerta) ? this.fornitura.dettagli_fornitura.data_inizio_offerta : null,
                    }    
                ]
        }
    
        ];
        this.ArrayValuesRight =  [
            {
                img: "./" + location.hostname + "/images/bollettainterattiva/desktop_fornitura_gas_3.svg",
                fields: [
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.categoria') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.categoria'),
                            labelText: this.fornitura.dettagli_fornitura.categoria,
                        }, {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.coefficiente_correttivo') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.coefficiente_correttivo'),
                            labelText: this.fornitura.dettagli_fornitura.coefficiente_correttivo,
                        }, {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.pcs') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.pcs'),
                            labelText: this.fornitura.dettagli_fornitura.pcs,
                        }
                    ]
            },
            {
                img: "./" + location.hostname + "/images/bollettainterattiva/desktop_fornitura_gas_4.svg",
                fields: [
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.classe_misuratore') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.classe_misuratore'),
                            labelText: this.fornitura.dettagli_fornitura.classe_misuratore,
                        },
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tipo_misuratore') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.tipo_misuratore'),
                            labelText: this.fornitura.dettagli_fornitura.tipo_misuratore,
                        },
                        {
                            labelTitle: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.matricola_contatore') ?  null : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_dettaglio_3Dt.matricola_contatore'),
                            labelText: this.fornitura.dettagli_fornitura.matricola_contatore,
                        }
                    ]
            }
        ];
    }
    
  }
}
</script>
