import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    home(customerId) {
        const url = this.webApiBaseUrl + "/api/customer/home/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    profile(customerId) {
        const url = this.webApiBaseUrl + "/api/customer/profile/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    names() {
        const url = this.webApiBaseUrl + "/api/customer/names";
        return this.apiCallerHelper.callGet(url);
    }
    
    saveProfile(fromModule, profile) {
        const url = this.webApiBaseUrl + "/api/customer/save/" + fromModule;
        return this.apiCallerHelper.callPost(url, profile);
    }

    changePassword() {
        const url = this.webApiBaseUrl + "/api/customer/password/reset";
        return this.apiCallerHelper.callPut(url,{ method_field: 'PUT'});
    }
    
    getUserformSection() {
        const url = this.webApiBaseUrl + "/api/customer/userformsection";
        return this.apiCallerHelper.callGet(url);
    }

}