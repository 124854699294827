<template>
  <v-container id="bollettaInterattivaContatti" class="px-0">
    <v-card class="contactscontainer" outlined>
      <v-row class="titlecontainer">
        <h1 class="title" v-html="$t('message.bolletta_interattiva_contacts_header')"></h1>
      </v-row>
      <v-row class="contactsdetailcontainer" lg="4" md="4" sm="12">
        <v-col v-if="documentData.landing_page_tel1" lg="12" md="4" sm="12" xs="12" style="text-align:center;">
          <div class="specificcontactsdetailcontainer">
            <div class="iconContainer">
              <v-img v-if="telImageExists" :src="getTelImage()" @error="telImageError" />
              <v-icon v-else large>mdi-phone</v-icon>
            </div>
            <div class="textContainer">
              <a :href="getTelLink()" :class="{ 'disabled' : (!getTelLink() || getTelLink().length===0) }">
              <p class="text">{{documentData.landing_page_tel1}}</p>
              </a>
              <p
                class="textDetails"
                v-html="$t('message.info_tel_ester')"
              ></p>
            </div>
          </div>
        </v-col>
        <v-col v-if="documentData.landing_page_tel2" lg="12" md="4" sm="12" xs="12" style="text-align:center;">
          <div class="specificcontactsdetailcontainer">
            <div class="iconContainer">
              <v-img v-if="cellImageExists" :src="getCellImage()" @error="cellImageError" />
              <v-icon v-else large>mdi-cellphone-basic</v-icon>
            </div>
            <div class="textContainer">
              <a :href="getCellLink()" :class="{ 'disabled' : (!getCellLink() || getCellLink().length===0) }">
              <p class="text">{{documentData.landing_page_tel2}}</p>
              </a>
              <p
                class="textDetails"
                v-html="$t('message.info_tel_dacell')"
              ></p>
            </div>
          </div>
        </v-col>
        <v-col v-if="documentData.landing_email" lg="12" md="4" sm="12" xs="12" style="text-align:center;">
          <div class="specificcontactsdetailcontainer">
            <div class="iconContainer">
              <v-img v-if="mailImageExists" :src="getMailImage()" @error="mailImageError" />
              <v-icon v-else large>mdi-email-outline</v-icon>
            </div>            
            <div class="textContainer">
              <a :href="getMailLink()" :class="{ 'disabled' : (!getMailLink() || getMailLink().length===0) }">
              <v-hover 
              v-slot="{ hover }">
              <p class="text" :class=" { 'on-hover': hover }">{{documentData.landing_email}}</p>
              </v-hover>
              </a>
              <p
                class="textDetails"
                v-html="$t('message.info_email')"
              ></p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      cellImageExists: true,
      mailImageExists: true,
      telImageExists: true
    };
  },
  props: {
    documentData: {
      type: Object,
      default: null,
    }    
  },
  methods: {
    getTelImage: function () {
      return './' + location.hostname + '/images/bollettainterattiva/Telephone_Icon.png';
    },
    getTelLink: function() {
      return "tel:"+this.documentData.landing_page_tel1;
    },
    getCellImage: function () {
      return './' + location.hostname + '/images/bollettainterattiva/Cellphone_Icon.png';
    },
    getCellLink: function() {
      return "tel:"+this.documentData.landing_page_tel2;
    },
    getMailImage: function () {
      return './' + location.hostname + '/images/bollettainterattiva/Email_Icon.png';
    },
    getMailLink: function() {
      return "mailto:"+this.documentData.landing_email;
    },
    cellImageError: function() {
      this.cellImageExists = false;
    },
    mailImageError: function() {
      this.mailImageExists = false;
    },
    telImageError: function() {
      this.telImageExists = false;
    }
  },
};
</script>
