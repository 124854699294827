<template>
    <v-container id="Bolletta_Interattiva_Riepilogo_Doc2">
        <v-row class="sectionParteSuperiore">
            <v-list-item three-line class="justify-center">
                <v-list-item-avatar v-if="userImageExists">
                    <v-img class="holderImage" :src="getUserImage()" @error="userImageError" />
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title v-if="documentData.holder_name" class="holderName text-wrap">
                        {{documentData.holder_name}}
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="documentData.holder_ind_riga2" class="holderIndRiga2 text-wrap">
                        {{documentData.holder_ind_riga2}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="documentData.holder_ind_riga3" class="holderIndRiga3 text-wrap">
                        {{documentData.holder_ind_riga3}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="documentData.holder_toponimo" class="holderToponimo text-wrap">
                        {{documentData.holder_toponimo}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="documentData.holder_frazione" class="holderFrazione text-wrap">
                        {{documentData.holder_frazione}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="holderCapLocProv" class="holderCapLocProv text-wrap">
                        {{holderCapLocProv}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="documentData.holder_nazione" class="holderNazione text-wrap">
                        {{documentData.holder_nazione}}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-for="(item, i) in documentData.index_fields" :key="'label'+i" 
                        class="holderFileds text-wrap">
                        {{$t("message." + item.label)}} {{item.value}}
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-row>
        <v-row v-if="imageExists" class="sectionServiceImage">
            <div class="imageContainer">
                <v-img contain class="serviceImage" :src="getServiceLogo()" @error="imageError" />
            </div>
        </v-row>
        <v-row v-if="documentData.document_type" class="document_type_section">
            <span class="document_type">{{$t("message." + documentData.document_type)}}</span>
        </v-row>
        <v-row v-if="documentData.document_number">
            <div class="document_number_section">
                <span class="document_number_label">{{$t('message.bolletta_interattiva_n_doc_label')}}</span>&nbsp;
                <span class="document_number">{{documentData.document_number}}</span>
            </div>
        </v-row>
        <v-row v-if="documentData.document_date">
            <div class="document_date_section">
                <span class="document_date_label">{{$t('message.bolletta_interattiva_data_label')}}</span>&nbsp;
                <span class="document_date">{{documentData.document_date}}</span>
            </div>
        </v-row>
        <v-row v-if="documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio">
            <span class="descrizione_servizio_label">{{$t('message.bolletta_interattiva_label_descrizione_servizio')}}</span>&nbsp;
        </v-row>
        <v-row v-if="documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio">
            <span class="descrizione_servizio">{{documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio}}</span>
        </v-row>    
    </v-container>
</template>

<script>
 
export default ({
    data() {
        return {
            imageExists: true,
            userImageExists: true
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
    computed: { 
        holderCapLocProv() {
            if (this.documentData.holder_cap || this.documentData.holder_localita || this.documentData.holder_provincia) {
                return this.documentData.holder_cap + ' ' + this.documentData.holder_localita + ' (' + this.documentData.holder_provincia + ')'
            }
            return null;
        }        
    },
    methods: {
        getServiceLogo: function() {
            if (this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio) {
                return './' + location.hostname + '/images/bollettainterattiva/' + this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio;
            }
            else {
                this.imageExists = false;
            }
        },
        getUserImage: function() {
            return './' + location.hostname + '/images/bollettainterattiva/ProfileIcon.png';
        },
        imageError: function() {
            this.imageExists = false;
        },
        userImageError: function() {
            this.userImageExists = false;
        }
    },
})
</script>
