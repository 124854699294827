<template>
    <v-card id="notFound" tile class="pa-8" width="100%" flat>
        <v-row justify="center" align="center">
            <v-col xs="12" lg="1" justify="center" align="center" >
                <v-icon size="70">mdi-alert-circle-outline</v-icon>
            </v-col>
            <v-col xs="12" lg="5" class="d-flex flex-column justify-center">
                <h1 class="font-weight-light pa-2">{{getMessage()}}</h1>
            </v-col>
        </v-row>
    </v-card>
</template>
<script>
export default {
    name: 'notFound',
    data: function () {
        return {
        };
    },
    props:{
      error: {
        type: Number,
        default: null
      },
      message: {
        type: String,
        default: null
      }
    },
    methods: {
        getMessage: function() {
            var ret = this.$t("message.notfound_message");

            if(this.message != null)
                ret = this.$t("message." + this.message);

            else if(this.error != null){
                switch(this.error){
                    case 404:
                        ret = this.$t("message.notfound_message");
                        break;
                    case 410:
                        ret = this.$t("message.not_valid_link");
                        break;
                }
            }

            return ret;
        }
    }
}
</script>