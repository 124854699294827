function LanguageCodeToCountryCode(language)
{
    switch(language){
        case 'en': return 'gb';
        case 'zh-CN': return 'cn';
        default: return language;
    }
}

function CountryCodeToLanguage(countryCode)
{
    switch(countryCode){
        case 'gb': return 'en';
        case 'cn': return 'zh-CN';
        default: return countryCode;
    }
}

export default { LanguageCodeToCountryCode, CountryCodeToLanguage }