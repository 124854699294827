<template>
    <!-- <v-container v-if="bolletta_interattiva_dett_forn_header">
        <FornituraCard v-for="(pod, index) in forniture" :item="pod" :key="index" :documentData="documentData" :show="show[index] " @openClose="openHandler" :checkLabel="checkLabel"></FornituraCard>
    </v-container> -->

     <v-container id="FornituraContainer" v-if="bolletta_interattiva_dett_forn_header">
                  <v-row>
                      <v-col xl="12" lg="12" md="12" sm="12">
                          <v-form>
                              <v-container>
                              <v-row v-if="forniture.length > 1">
                                  <v-col xl="12" lg="12" md="12" sm="12">
                                      <v-select
                                      label="Fornitura"
                                      :items="forniture"
                                      v-model="fornitura"
                                      required
                                      :item-text ="item => getItemSelectFornituraText(item)"
                                      return-object
                                      outlined
                                      @input="changedFornituraSelected"
                                      ></v-select>
                                  </v-col>
                              </v-row>
                              <v-row v-if="fornitura !== null && fornitura !== undefined">
                                    <FornituraCard :item="fornitura"  :documentData="documentData" :checkLabel="checkLabel"  ></FornituraCard>
                              </v-row>
                              </v-container>
                          </v-form>
                      </v-col>
                  </v-row>

  </v-container>
</template>

<script>
//import { nextTick } from 'vue/types/umd';
//import { nextTick } from 'vue';

//import { services } from '../scripts/services/serviceBuilder'
import FornituraCard from './FornituraCard.vue';
export default {
    name: 'Fornitura',
    components:
    {
        FornituraCard
    },
    data: function() {
        return {
            bolletta_interattiva_dett_forn_header:false,
            forniture: {},
            show:[],
            fornitura: null,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: {

    },
    methods: {
        fillShow: function()
        {
            let that = this;
            that.forniture.forEach(() => 
            {
                that.show.push(false);
            });
        },
        openHandler: function(event)
        {
            let that = this;
            if(that.show[event])
            {
                that.show = Array(that.show.length).fill(false);
            }
            else{
                that.show = Array(that.show.length).fill(false);
                that.show[event] = true;
            }            
        },
        getItemSelectFornituraText: function(item){
            let result = (this.documentData.tipo_bolletta == 'GAS') ? item.tipo_punto +":"+ item.pdr +' - ' : item.tipo_punto +" "+ item.pod +' - ';
            item.indirizzo.forEach(element => {
                result += element+" - ";
            });
            result = result.slice(0, -3);
            return result;
        },    
        getSingleFornituraAddress: function(){
        let result = "";
        this.fornitura.indirizzo.forEach(element => {
                result += element+" - ";
        });
        result = result.slice(0, -3);
        return result;
        },
      changedFornituraSelected: async function(value) {
        await this.$nextTick();
        this.$root.$emit('changedFornituraSelected', value);
        },
            
    },
	created: function () {
        let that = this;
		that.bolletta_interattiva_dett_forn_header = Boolean(Number(that.documentData.bolletta_interattiva_dett_forn_header));

        
        that.forniture = that.documentData.fatt_interattiva_data.fornitura;
        that.fornitura = that.forniture[0];
        that.fillShow();
	}
}
</script>
