import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.bollettaInterattivaApiBaseUrl = AppSettingsService.getSettings().bollettaInterattivaApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    modules() {
        const url = this.webApiBaseUrl + "/api/bollettainterattiva/modules";
        return this.apiCallerHelper.callGet(url);
    }

    documentInfo(cryptoId) {
        const url = this.webApiBaseUrl + "/api/bollettainterattiva/document/" + cryptoId;
        return this.apiCallerHelper.callGet(url);
    }

    documentInfoById(documentId) {
        const url = this.webApiBaseUrl + "/api/bollettainterattiva/document/id/" + documentId;
        return this.apiCallerHelper.callGet(url);
    }

    rate(cryptoId) {
        const url = this.bollettaInterattivaApiBaseUrl + "/bolletta_interattiva_rate/" + cryptoId;
        return this.apiCallerHelper.callGet(url);
    }

    sendEmail(cryptoId, data){
        const url = this.webApiBaseUrl + "/api/bollettainterattiva/sendemail/" + cryptoId;
        return this.apiCallerHelper.callPost(url, data);
    }
}
