<template>
    <v-container>
        <v-card>
            <v-card-title>
                Nexi Annullo di pagamento
            </v-card-title>
            <v-card-text>
                {{$t('message.bolletta_interattiva_pagaora_nexi_esito_annullo')}}
            </v-card-text>
        <v-card-actions>
            <v-btn @click="goBackToBolletta()">Torna alla bolletta</v-btn>
        </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

export default {
    data: () => ({
        
    }),
    methods:{
        goBackToBolletta:function(){
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('id');
            window.open("/bollettainterattiva" + "?id="+myParam, '_self');
        },
    },
    props:{
        nexiEsito: {
            type: Object,
            default: null
        }
    },
    computed: {
       
    }
}
</script>
    
    