import axios from 'axios';
import updateToken from "../../middleware/update-token";
import Vue from "vue";

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    updateToken(Vue.prototype.$isAuthenticated.value);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default class ApiCallerHelper {
    
    // wrappers to call types
    callGet(urlToCall) {
        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                url: urlToCall
            })
            .then(result => resolve(result))
            .catch(err => reject(err));
        });
    }

    callGetDownload(urlToCall) {

        return new Promise((resolve, reject) => {
            axios({
                method: "get",
                responseType: 'blob',
                url: urlToCall
            })
            .then(result => resolve(result))
            .catch(err => reject(err));
        });
    }

    callPost(urlToCall, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: "post",
                headers: {
                    'Content-Type': 'application/json'
                },
                url: urlToCall,
                data: data
            })
            .then(result => resolve(result))
            .catch(err => reject(err));
        });
    }

    callDelete(urlToCall) {
        return new Promise((resolve, reject) => {
            axios({
                method: "delete",
                url: urlToCall
            })
            .then(result =>resolve(result))            
            .catch(err => reject(err));
        });
    }

    callPut(urlToCall, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: "put",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                url: urlToCall,
                data: data
            })
            .then(result =>resolve(result))            
            .catch(err => reject(err));
        });
    }
}
