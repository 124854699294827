<template>
    <v-snackbar id="snackbar"
                v-model="show"
                color="grey lighten-3"
                :timeout="timeout"
                
                :multi-line="multiLine">
                <span class="primaryColorTx">{{ text }}</span>
        <template v-slot:action="item">
            <v-btn
                :color="color"
                v-bind="item.attrs"
                @click="show = false"
                class="snackbarButton">
                {{ $t("message.snackbar_button_close") }}
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
    export default {
        name: 'snackbar',
        data: function () {
            return {
                show: false,
                timeout: -1,
                text: '',
                color: 'red',
                multiLine: true
            };
        },
        methods: {
            open: function (text, color, timeout, multiLine) {
                this.text = text;
                this.color = color == null ? 'red' : color;
                this.timeout = timeout == null ? -1 : timeout;
                this.multiLine = multiLine == null ? true : multiLine;
                this.show = true;
            },
            close: function() {
                this.show = false;
            }
        }
    };
</script>