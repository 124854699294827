import Vue from "vue";
import Vuex from "vuex";
//import StoreTemplate5 from "../components/BollettaInterattiva5/Core/store/store.js";

Vue.use(Vuex);


export const store = new Vuex.Store({
  state: {
    layout: "Template1",
    ready: false
  },
  mutations: {
    SET_LAYOUT(state, payload) {
      state.layout = payload;
    },
    SET_READY(state, payload) {
      state.ready = payload;
    }
  },
  getters: {
    layout(state) {
      return state.layout;
    },
    ready(state) {
      return state.ready;
    }
  },
  actions: {},
  modules: {
   // StoreTemplate5
  },
});


