<template>
    <v-container id="Bolletta_Interattiva_Riepilogo_Doc">
        <v-row>
            <div v-if="imageExists" class="imageContainer ml-auto">
                <v-img 
                    contain 
                    id="serviceImage"
                    :src="getServiceLogo()"
                    @error="imageError"

                />
            </div>
        </v-row>
        <v-row>
            <p class="invoices_document_type ml-auto">{{$t("message." + documentData.document_type)}}</p>
        </v-row>
        <v-row>
            <p class="invoiceNumber ml-auto">
                 <span>{{$t('message.bolletta_interattiva_n_doc_label')}}</span>&nbsp;
                 <span>{{documentData.document_number}}</span>
                 <br>
                 <span v-html="$t('message.bolletta_interattiva_data_label')"></span>&nbsp;
                 <span>{{documentData.document_date}}</span> 
            </p>
        </v-row>
        <v-row>
            <p class="registeredTo ml-auto" v-html="$t('message.bolletta_interattiva_owned_by')">
            </p>
        </v-row>
        <v-row>
            <p class="invoiceHolder ml-auto">
                 <span v-if="documentData.holder_name">{{documentData.holder_name}}</span>
                 <span v-if="documentData.holder_ind_riga2"><br/>{{documentData.holder_ind_riga2}}</span>
                 <span v-if="documentData.holder_ind_riga3"><br/>{{documentData.holder_ind_riga3}}</span>
                 <span v-if="documentData.holder_toponimo"><br/>{{documentData.holder_toponimo}}</span>
                 <span v-if="documentData.holder_cap"><br/>{{documentData.holder_cap}}</span>
                 <span v-if="documentData.holder_frazione || documentData.holder_localita || documentData.holder_provincia">
                     <br/>{{documentData.holder_frazione}} {{documentData.holder_localita}} ({{documentData.holder_provincia}})
                 </span>
                 <span v-if="documentData.holder_nazione"><br/>{{documentData.holder_nazione}}</span>
                 <span v-for="(item, i) in documentData.index_fields" :key="'label'+i">
                    <br/>{{$t("message." + item.label)}} {{item.value}}
                 </span>
            </p>
        </v-row>
        <v-row>
            <v-spacer />
            <p class="invoiceInvoiceType">
                 {{documentData.fatt_interattiva_data.riepilogo_doc.descrizione_servizio}}
            </p>
        </v-row>
    
    </v-container>
</template>

<script>
 
export default ({
    data() {
        return {
            imageExists: true,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
    computed: { },
    methods: {
        getServiceLogo: function() {
            return './' + location.hostname + '/images/bollettainterattiva/' + this.documentData.fatt_interattiva_data.riepilogo_doc.logo_servizio;
        },
        imageError: function() {
            this.imageExists = false;
        }
    },
})
</script>
