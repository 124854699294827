<template>
    <v-app>
      <v-skeleton-loader type="article" tile :loading="loading">
  
        <!-- contenitore header principale -->
        <div>
          <Header :getHeaderLogo="getHeaderLogo" :getHeaderImage="getHeaderImage" :locales="locales" :documentId="documentId" :cryptoId="cryptoId"></Header>
        </div>
        <!--contenitore del main-->
  
        <v-main id="landingnexi" class="main">
          <v-container class="wrapWidth">
            <NexiAnnullo v-if="annulloQueryString==='1'" :cryptoId="cryptoId" :documentId="documentId"></NexiAnnullo>
            <NexiEsito v-else :cryptoId="cryptoId" :documentId="documentId"></NexiEsito>
          </v-container>
        </v-main>
        <!--contenitore del footer-->
        <v-footer class="footer" style="background-color: #373737;">
          <v-container class="wrapWidth" justify="center">
            <v-row align="center">
              <v-col xl="2" lg="3" md="3" sm="3">
                <v-btn icon target="_blank" :href="'https://www.facebook.com/DufercoEnergia/'">
                  <v-img :src="getSocialMediaImage('facebook')" style="width: 12px;" />
                </v-btn>
                <v-btn icon target="_blank" :href="'https://www.youtube.com/channel/UCsA0A8aNenj8GgIAnIaXmGQ'">
                  <v-img :src="getSocialMediaImage('youtube')" style="width: 12px;" />
                </v-btn>
                <v-btn icon target="_blank" :href="'https://www.linkedin.com/company/duferco-energia-spa'">
                  <v-img :src="getSocialMediaImage('linkedin')" style="width: 12px;" />
                </v-btn>
                <v-btn icon target="_blank" :href="'https://www.instagram.com/dufercoenergia'">
                  <v-img :src="getSocialMediaImage('instagram')" style="width: 12px;" />
                </v-btn>
              </v-col>
              <v-col xl="2" lg="3" md="3" sm="3">
                <a href="https://dufercoenergia.com/" title="" target="_blank" class="footer_site_link">
                <span style="color: rgb(255, 255, 255);">dufercoenergia.com</span>
                </a> 
              </v-col>
              <v-col xl="3" lg="2" md="2" sm="2">
                <v-img contain :src="getFooterLogo()" class="footer_logo"></v-img>
              </v-col>
              <v-col xl="5" lg="4" md="4" sm="4">
                <span class="footer_copyright text-right">Duferco Energia SpA - All rights reserved</span>
              </v-col>
              <!--
                <div class="footer_social_media_section">
                
                </div>
              -->
            </v-row>
          </v-container>
        </v-footer>
      </v-skeleton-loader>
    </v-app>
  </template>
  <script>
  //import Languages from "../components/Languages.vue";
  import { services } from "../scripts/services/serviceBuilder";
  import Header from "../components/Header.vue";
  import NexiEsito from "../components/NexiEsito.vue";
  import NexiAnnullo from "../components/NexiAnnullo.vue";
  export default {
    name: "TemplateLandingNexi",
    components: {
      NexiEsito,
      Header,
      NexiAnnullo
      //Languages,
    },
    props: {
    
    },
    data: () => ({
      cryptoId: null,
      documentId: null,
      error: null,
      loading: true,
      locales: [],
      annulloQueryString: null,
    }),
    computed: {
      isAuthenticated: function () {
        var result = this.getKeycloak(this.$parent);
        if (
          !result ||
          !Object.prototype.hasOwnProperty.call(result, "idTokenParsed")
        ) {
          return false;
        }
        return true;
      },
    },
    watch: {
        $route (to){
          this.loading = false;
          this.error = to.query.error;
          this.cryptoId = to.query.id;
          this.documentId = to.query.documentId;
        }
    },
    methods: {
      getKeycloak: function (node) {
        if (node == null) return null;
        if (node.$options.name != "App") return this.getKeycloak(node.$parent);
        return node.keycloak;
      },
      getHeaderImage: function () {
        if (this.$vuetify.breakpoint.mobile) {
          return "./" + location.hostname + "/images/bollettainterattiva/Header_mobile.jpg";
        }
        return "./" + location.hostname + "/images/bollettainterattiva/Header_Desktop.jpg";
      },
      getFooterLogo: function () {
        return "./" + location.hostname + "/images/bollettainterattiva/Logo_Footer.png";
      },
      getHeaderLogo: function () {
        return "./" + location.hostname + "/images/bollettainterattiva/Logo.png";
      },
      getSocialMediaImage: function(key) {
        let url = null;
        switch (key) {
          case 'facebook':
            url = "./" + location.hostname + "/images/bollettainterattiva/Social_Icons/FB.png";
            break;
          case 'youtube':
            url = "./" + location.hostname + "/images/bollettainterattiva/Social_Icons/YT.png";
            break;
          case 'linkedin':
            url = "./" + location.hostname + "/images/bollettainterattiva/Social_Icons/LN.png";
            break;
          case 'instagram':
            url = "./" + location.hostname + "/images/bollettainterattiva/Social_Icons/IG.png";
            break;
        }
        return url;
      }
    },
    created: function () {
      var that = this;
      var requests = [];
      requests.push(services.apiCallerLocale.list());
  
      Promise.all(requests).then((responses) => {
        if (
          !!responses[0].data &&
          Array.isArray(responses[0].data) &&
          responses[0].data.length > 1
        ) {
          that.locales = responses[0].data;
          that.$i18n.locale = that.$locale.value;
        }
      });
      
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('annullo');
      that.annulloQueryString = myParam;
    },
  };
  </script>
  
  <!-- <style>
  /* sfondo bolletta Duferco */
  #app {
      /* The image used */
      background-image: url("../../public/localhost/images/bollettainterattiva/dufercoBollettaInterattiva_Background.png") !important;
    
      /* Full height */
      height: 100% !important;
    
      /* Center and scale the image nicely */
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover !important;
  }
  </style> -->
  