import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    getNewInvoices(customerId) {
        const url = this.webApiBaseUrl + "/api/invoices/new/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    getInvocesInfo() {
        const url = this.webApiBaseUrl + "/api/invoices/info";
        return this.apiCallerHelper.callGet(url);
    }

}