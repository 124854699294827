<template> 

  <v-select v-if="items.length > 1" dense 
    hide-details
    v-model="$i18n.locale"
    outlined
    :class="{borderSelect : !dark, darkBorderSelect : dark}"
    :style="customStyle"
    :items="items"
    :label="$t('message.languages_label_select')"
    @change="onLanguageSelected">
        <template v-slot:selection="{item}">
        <flag :iso="getCountryImage(item)" class="mr-2"/>
            {{ item.toUpperCase() }}  
        </template>
        <template v-slot:item="{item}">
            <flag :iso="getCountryImage(item)" class="mr-2"/>
            {{ item.toUpperCase() }}
        </template>

  </v-select>

</template>
<script>

import localeHelper from "../middleware/locale.js";

  export default {
    name: 'languages',
    data() {
      return{
        items: []
      }
    },
    props:{
      dark: {
        type: Boolean,
        default: false
      },
      locales: {
        type: Array,
        default: null
      },
      customStyle: {
        type: Object,
        default: null
      }
    },
    watch: {
      locales: function (value) {
        this.localeListChanged(value);
      }
    },
    created() {
      if(this.locales != null) {
        this.localeListChanged(this.locales);
      }
    },
    methods: {
      getCountryImage: function (locale) {
        return localeHelper.LanguageCodeToCountryCode(locale);
      },
      localeListChanged: function (locales){
        this.items = locales;
      },
      onLanguageSelected: function($event) {
        this.$i18n.locale = $event;
        this.$locale.value = $event;
        this.$root.$emit("localeChanged");
      }
    }
  }
</script>
