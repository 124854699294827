<template>
  <v-container id="socialChannels" fluid>
    <v-row class="socialChannelTitle" v-if="title">
      {{title}}
    </v-row>
    <v-row class="socialChannelSubtitle" v-if="subTitle">
      {{subTitle}}
    </v-row>
    <v-row justify="end" class="socialChannelContent">
    
      <span v-for="(channel, i) in channels" :key="'channel-' + i">
        <v-tooltip 
          v-if="channel.tooltip" 
          :top="isTooltipTop()"
          :right="isTooltipRight()"
          :bottom="isTooltipBottom()"
          :left="isTooltipLeft()">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" 
              dark icon 
              class="socialChannelButton"
              target="_blank"
              :href="channel.url">
              <v-icon>{{ channel.icon }}</v-icon>
            </v-btn>
          </template>
          <span>{{ channel.tooltip }}</span>
        </v-tooltip>
        <v-btn v-else 
          dark icon 
          class="socialChannelButton"
          target="_blank"
          :href="channel.url">
          <v-icon>{{ channel.icon }}</v-icon>
        </v-btn>
      </span>
    </v-row>
  </v-container>
</template>
<script>
export default {
    name: 'socialChannels',
    data: function () {
      return {
        normalizedOptions: {
          tooltip: 'bottom'
        }
      };
    },
    props: {
      channels: null,
      title: null,
      subTitle: null,
      options: null
    },
    methods: {
      isTooltipBottom: function() {
        return this.normalizedOptions.tooltip.toLowerCase() == 'bottom';
      },
      isTooltipLeft: function() {
        return this.normalizedOptions.tooltip.toLowerCase() == 'left';
      },
      isTooltipRight: function() {
        return this.normalizedOptions.tooltip.toLowerCase() == 'right';
      },
      isTooltipTop: function() {
        return this.normalizedOptions.tooltip.toLowerCase() == 'top';
      }
    },
    created: function() {
      if (!this.options)
        return;
      
      this.normalizedOptions = Object.assign(this.normalizedOptions, this.options);
    }
  }
</script>