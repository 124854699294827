<template>
	<v-app>
		<!-- contenitore header principale -->
		<v-container id="headerContainer" fluid class="pa-0"> 
			<!--contenitore top Header  -->
			<v-app-bar dense class="primaryColorBg topHeader" flat>
				<v-container class="container_style wrapWidth">
				<v-row align="center" justify="space-between">
					<v-col>
						<h2 class="fifthColorTx topHeaderTitle">{{ $t("message.header_text") }}</h2>
					</v-col>
					<v-col cols="5" lg="3" sm="5"  md="3" v-if="showsite">
						<Anagrafica v-if="nameList" :name-list="nameList"
							@cod-client-changed="codClientUpdate"></Anagrafica>				
					</v-col>
				</v-row>
				</v-container>
			</v-app-bar>
			<v-toolbar height="120" class="secondaryColorBg dark middleHeader" flat>
				<v-container class="wrapWidth container_style">
				<v-row align="center">
					<v-app-bar-nav-icon v-if="$vuetify.breakpoint.mobile && showsite" class="navIcon" @click.stop="drawer = !drawer"/>
					
						<v-col class="pa-0" xl="9" lg="9" md="9" sm="8">
							<router-link class="pa-0 d-flex align-center"  to="/">
								<v-img  class="mr-2" 
										style="display:inline-block" 
										contain 
										id="headerImage"
										max-width="48" 
										max-height="48" 
										:src="getHeaderLogo()"/>
								<v-col class="fifthColorTx">
									<h2 class="middleHeaderTitle">{{ $t("message.header_text_second") }}</h2>
									<h6 class="header_text_subtitle">{{ $t("message.header_text_subtitle") }}</h6>
								</v-col>
							</router-link>
						</v-col>
						<v-col xl="3" lg="3" md="3" sm="4" class="py-0 mb-2" align=right>
							<Languages :locales="locales" :customStyle="{width: '120px'}"></Languages>
						</v-col>
					</v-row>
				</v-container>
			</v-toolbar>
			<!--contenitore Menu Header-->
			<v-toolbar id="myHeader" flat v-if="showsite && $vuetify.breakpoint.lgAndUp"   class="container_style secondaryColorBg menuHeader">
				<Menu 	display="horizontal" 
						show="horizontal"
						justify="center"			
						:items="menuHeaders"/>	 			
			</v-toolbar>
			<v-navigation-drawer v-if="$vuetify.breakpoint.mobile && showsite" v-model="drawer" class="secondaryColorBg navDrawer" app>
				<v-app-bar id="navDrawerHeder" class="secondaryColorBg topHeader" flat>
					<router-link class="pa-0 d-flex align-center"  to="/">
						<v-img  class="mr-2" 
								style="display:inline-block" 
								contain
								id="navDrawerHeaderImage"
								max-width="48" 
								max-height="48" 
								:src="getHeaderLogo()"/>
						<v-col class="fifthColorTx">
							<h2 class="navDrawerHeaderTitle">{{ $t("message.header_text_second") }}</h2>
							<h6 class="navDrawerHeaderTitle">{{ $t("message.header_text_subtitle") }}</h6>
						</v-col>
					</router-link>
				</v-app-bar>
				<v-divider class="divider"></v-divider>
				<Menu 	display="vertical" 
						show="vertical"
						:value="true"
						:items="menuHeaders"/>		
			</v-navigation-drawer>
		</v-container>
		<!--contenitore del main-->
		<v-main class="main">
			<v-container class="wrapWidth">
				<transition name="component-fade" mode="out-in">
					<router-view></router-view>
				</transition>
			</v-container>
		</v-main>
		<!--contenitore del footer-->
		<v-footer id="footer" class="fourthColorBg">
			<v-container class="wrapWidth">
			<v-row>
				<v-col>
					<span v-if="$t('message.footer_text').length > 0" class="footerText" v-html="$t('message.footer_text')"></span>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<span v-if="$t('message.footer_text_second').length > 0"  class="footerTextSecond" v-html="$t('message.footer_text_second')"></span>
				</v-col>
			</v-row>
			<v-divider class="divider"></v-divider>
			<v-row class="footerTextRow">
				<v-col :cols="changeCol()" v-if="$t('message.contacts_text').length > 0">
					<span class="footerContactsText" v-html="$t('message.contacts_text')"></span>
				</v-col>
				<v-col :cols="changeCol()" v-if="$t('message.footer_free_section').length > 0">
					<span class="footerFreeSection" v-html="$t('message.footer_free_section')"></span>
				</v-col>
				<v-col :cols="changeCol()" v-if="$t('message.policy_privacy_text').length > 0">
					<span class="footerPolicyPrivacy" v-html="$t('message.policy_privacy_text')"></span>
				</v-col>
				<v-col :cols="changeCol()" class="text-right" v-if="channels && channels.length > 0">
					<span class="font-weight-bold">{{$t('message.footer_social_text')}}</span> 
					<social-channels :channels="channels"></social-channels>
				</v-col>
			</v-row>
			</v-container>
		</v-footer>
	</v-app>
</template>
<script>
import Menu from '../components/Menu.vue'
import Anagrafica from '../components/Anagrafica.vue'
import Languages from '../components/Languages.vue'
import SocialChannels from '../components/SocialChannels.vue'
import { services } from '../scripts/services/serviceBuilder'
export default {
	name: 'template1',
	components: {
		Menu,
		Anagrafica,
		SocialChannels,
		Languages	
	},
	props: {
		showsite: null
	},
	data: () => ({
		channels: null,
		drawer:false,
		items: [],
		menuHeaders: [],
		locales: [],
		nameList: null,
		sticky: true,
	}),
	computed: {
		isAuthenticated: function () {
			var result = this.getKeycloak(this.$parent);
			if (!result || !Object.prototype.hasOwnProperty.call(result, 'idTokenParsed')) {
				return false;
			}	
			return true;
		}
	},
	methods: {
		codClientUpdate: function(value) {
			this.$cod_client.value = value;			
		},
		changeCol: function () {
			if(screen.width < 600)
				return 12;
		},
		changeColHeader: function () {
			if(screen.width < 600)
				return 6;
			else 
				return 3;
		},
		getHeaderLogo: function () {
			return './' + location.hostname + '/images/logo.png';
		},
		getKeycloak: function (node) {
			if (node == null)
				return null;
			if (node.$options.name != "App")
				return this.getKeycloak(node.$parent);
			return node.keycloak;
		},		
		getNormalizedChannels: function (data) {
			if (!data || 
				data.length < 1 || 
				!data['social_link_presence'] || 
				data['social_link_presence'] == '0')
				return [];

			var that = this;
			var normalizedChannels = [];
			for (const [key, value] of Object.entries(data)) {
				if(key != 'social_link_presence' && value != null && value.length > 0) {
					normalizedChannels.push({
						icon: that.getSocialChannelIcon(key),
						tooltip: null,
						url: value
					});
				}
			}

			return normalizedChannels;
		},
		getSocialChannelIcon: function (settingName) {
			switch (settingName) {
				case 'social_link_facebook':
					return 'mdi-facebook';
				case 'social_link_instagram':
					return 'mdi-instagram';
				case 'social_link_linkedin':
					return 'mdi-linkedin';
				case 'social_link_twitter':
					return 'mdi-twitter';
				default:
					return 'mdi-help-circle';
			}
		},
	},
	created: function () {
		var that = this;	
		this.$root.$on("codClientChanged", this.codClientUpdate);
		that.menuHeaders = [];

		var requests = [];
		requests.push(services.apiCallerSocial.data());
		requests.push(services.apiCallerLocale.list());

		if(that.isAuthenticated) {
			requests.push(services.apiCallerMenu.get());
			requests.push(services.apiCallerCustomer.names());
		}

		Promise.all(requests)
			.then(responses => {
				that.channels = that.getNormalizedChannels(responses[0].data);

				if(that.isAuthenticated) {
					responses[2].data.forEach(element => {		
						that.menuHeaders.push(element);
					})
					that.$i18n.locale = that.$locale.value;
					that.nameList = responses[3].data;
				}

				if(!!responses[1].data && Array.isArray(responses[1].data) && responses[1].data.length > 1){
					that.locales = responses[1].data;
				}
				
			})		
	},
	mounted: function() {
		if(this.showsite && this.$vuetify.breakpoint.lgAndUp) {
			var header = document.getElementById("myHeader");
			var stickymenuoffset = header.offsetTop
			window.addEventListener("scroll", function(){
				requestAnimationFrame(function(){
					if (window.pageYOffset > stickymenuoffset){
						header.classList.add('sticky')
					}
					else{
						header.classList.remove('sticky')
					}
				})
			})
		}		
	}
}
</script>

<style scoped>
	.sticky {
		/* menu sticky on scroll */
		position: fixed;
		top: 0;
		width: 100%;
		z-index: 2;
	}
	.component-fade-enter-active, .component-fade-leave-active {
		/* effetto transizione di pagina */
		transition: opacity .3s ease;
	}
	.component-fade-enter, .component-fade-leave-to {
		/* effetto transizione di pagina */
		opacity: 0;
	}
</style>
