<template>
    <v-container id="BollettaInterattivaFornituraCard3Dt">
        <v-row :no-gutters="true">
            <v-col >
                <v-row>
                    <v-col id="bolletta_interattiva_dett_forn_consumi" class="col-2 d-flex justify-start">
                        <p class="text-h5" id="consumoText">{{ item.consumi.consumo }}</p>
                    </v-col>
                    <v-col id="bolletta_interattiva_dett_forn_periodo">
                        <div class="subtitle-1">{{ $t('message.bolletta_interattiva_dett_forn_periodo') }}</div>
                        <div class="subtitle-1">{{ item.consumi.periodo }}</div>
                    </v-col>
                    <v-col auto >
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <div class="body-2">{{ item.consumi.stimato_reale }}</div>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col sm="12">
                <div id="bolletta_interattiva_storico_consumi_header">
                        <div v-if="documentData.bolletta_interattiva_esponi_storico_consumi==1">
                            <img v-if="imgConsumi && (documentData.bolletta_interattiva_esponi_storico_consumi_icon === 1)" :src="getImgConsumi()" id="logo_consumi" @error="() => {imgConsumi = !imgConsumi}"  height="10%" width="10%"/>
                            <!--h2>Storico Consumi</h2-->
                            <div class="subtitle-1">{{checkLabel('message.bolletta_interattiva_storico_consumi_text') ? "" : $t('message.bolletta_interattiva_storico_consumi_text')}}</div>
                            <canvas id="consumo-chart" ref="consumo-chart" :height="consumoHeight"> </canvas>
                            <v-divider></v-divider>
                        </div>
                        <div v-if="documentData.bolletta_interattiva_esponi_spesa_annua==1">
                            <img v-if="imgSpesaAnnua && (documentData.bolletta_interattiva_esponi_spesa_annua_icon === 1)" :src="getImgSpesaAnnua()" id="logo_spesa_annua" @error="() => {imgSpesaAnnua = !imgSpesaAnnua}"  height="10%" width="10%"/>

                            <h2>Spesa Annua</h2>
                            <div class="subtitle-1">{{checkLabel('message.bolletta_interattiva_spesa_annua_text') ? "" : $t('message.bolletta_interattiva_spesa_annua_text')}}</div>
                            <canvas id="annua-chart" ref="annua-chart" :height="annuaHeight"> </canvas>
                        </div>
                        
                </div>
            </v-col>
        </v-row>
        <v-row>
        </v-row>
    </v-container>
</template>

<script>
//import { services } from '../scripts/services/serviceBuilder'
import moment from "moment";
import Chart from "chart.js";
export default {
    name: 'BollettaInterattivaFornituraCard3Dt',
    data: function() {
        let that = this;
        return {
            // bolletta_interattiva_esponi_storico_consumi: false,
            // bolletta_interattiva_esponi_storico_consumi_icon: false,
            show: false,
            annuaVariable: null,
            consumoVariable: null,
            imgConsumi: true,
            imgSpesaAnnua: true,
            imgTipoPunto: true,
            imgAddress: true,
            annuaHeight: 200,
            consumoHeight: 200,
            annuaData: {
                type: "bar",
                data:{},
                options: {
                    legend: {
                        display: true,
                        position: "right",
                        labels: {
                            boxWidth: 0,
                            usePointStyle: false
                        },
                        onClick: function() {}
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            }
                        }],
                    }  
                },
            },
            consumoDataPOD: {
                type: "bar",
                data:{},
                options: {
                    legend: {
                        display: true,
                        position: "right",
                        labels: {
                            usePointStyle: true
                        },
                        onClick: function(e,legendItem) {
                            if(legendItem.text != that.$t('message.' + 'consumo_effettivo')
                             && legendItem.text != that.$t('message.' + 'consumo_stimato'))
                            Chart.defaults.global.legend.onClick.call(this, e, legendItem)
                        }
                    },
                    
                    scales: {
                        xAxes: [{
                            stacked: true // this should be set to make the bars stacked
                        }],
                        yAxes: [{
                            stacked: true,
                            ticks: {
                                beginAtZero: true,
                            } // this also..
                        }]
                    }
                },
            },
            consumoDataPDR: {
                type: "bar",
                data:{},
                options: {
                    legend: {
                        display: true,
                        position: "right",
                        labels: {
                            usePointStyle: true
                        }
                    },
                    scales: {
                        xAxes: [{
                            stacked: true // this should be set to make the bars stacked
                        }],
                        yAxes: [{
                            stacked: true, // this also..
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    }
                },
            },
        }
    },
    props: {
        item: {},
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
        //show: Boolean,
    },
    computed: {

    },
    components: {
        
    },
    methods: {
        getDatasetsForPod: function(storicoConsumiArray) {
            let that = this;
            let keysConsumi = Object.keys(storicoConsumiArray[0]).filter(k => k.startsWith("consumo_f"));
            var datasets = [];
            var consumi = ['consumo_effettivo','consumo_stimato'];

            const map = new Map(storicoConsumiArray.map(pos => [pos.mese + " " + pos.anno, pos]));
            const uniques = [...map.values()];

            if(keysConsumi.length>0)

            for (let cc = 0; cc < consumi.length; cc++) {
                datasets.push({
                label: this.$t('message.' + consumi[cc]),
                backgroundColor: '#00000000',
                borderColor: '#00000000',
                borderWidth: 0,
                data: []
                });
                for (let j = 0; j < keysConsumi.length; j++) {
                    let kindConsumo = keysConsumi[j]; //.substring(0, 6) + 'i' + keysConsumi[j].substring(7);

                   
                    //colore
                    let color=that.documentData[kindConsumo].split(',')[1]; 
                    if(cc == 1)
                        color=that.documentData[kindConsumo].split(',')[2]; 

                    if(!color)
                        continue;    
                    //calcolo val
                    let valuesDataset=[];
                    for(let i = 0; i < uniques.length; i++) {
                        let valpush = 0;
                        let month_occurrency = storicoConsumiArray.filter(sc => (sc.mese + " " + sc.anno) == (uniques[i].mese + " " + uniques[i].anno));
                        for(let a = 0; a < month_occurrency.length; a++) 
                        {
                            if(Number(month_occurrency[a]['reale']) == 0 && cc == 1){
                               valpush = (Number(month_occurrency[a][keysConsumi[j]]));
                            }
                            else
                                if(Number(month_occurrency[a]['reale']) == 1 && cc == 0){
                                    valpush = (Number(month_occurrency[a][keysConsumi[j]]))
                                }
                        }
                        valuesDataset.push(valpush);
                    }

                    datasets.push({
                    label: this.$t('message.' + this.documentData[kindConsumo].split(',')[0]),
                    //label: kindConsumo.replace("consumo_", "Fascia "),
                    backgroundColor:  (cc==0) ? color : this.createDiagonalPattern(color),
                    data: valuesDataset
                    });
                   
                }
            }


            return datasets;
        },
        getDatasetsForPdr: function(storicoConsumiArray) {

            let that = this;
            let keysConsumi = Object.keys(storicoConsumiArray[0]).filter(k => k.startsWith("consumo_f"));
            var datasets = [];
            var consumi = ['consumo_effettivo','consumo_stimato'];

            const map = new Map(storicoConsumiArray.map(pos => [pos.mese + " " + pos.anno, pos]));
            const uniques = [...map.values()];

            if(keysConsumi.length>0)

            for (let cc = 0; cc < consumi.length; cc++) {
                // datasets.push({
                // label: this.$t('message.' + consumi[cc]),
                // backgroundColor: '#00000000',
                // borderColor: '#00000000',
                // borderWidth: 0,
                // data: []
                // });

                let valuesDataset=[];
                let color='black';
                for (let j = 0; j < keysConsumi.length; j++) {
                    let kindConsumo = keysConsumi[j]; //.substring(0, 6) + 'i' + keysConsumi[j].substring(7);

                   
                    //colore
                    color=that.documentData[kindConsumo].split(',')[1]; 
                    if(cc == 1)
                        color=that.documentData[kindConsumo].split(',')[2]; 

                    if(!color)
                        continue;    
                    //calcolo val
                    //let valuesDataset=[];
                    for(let i = 0; i < uniques.length; i++) {
                        let valpush = 0;
                        let month_occurrency = storicoConsumiArray.filter(sc => (sc.mese + " " + sc.anno) == (uniques[i].mese + " " + uniques[i].anno));
                        for(let a = 0; a < month_occurrency.length; a++) 
                        {
                            if(Number(month_occurrency[a]['reale']) == 0 && cc == 1){
                               valpush = (Number(month_occurrency[a][keysConsumi[j]]));
                            }
                            else
                                if(Number(month_occurrency[a]['reale']) == 1 && cc == 0){
                                    valpush = (Number(month_occurrency[a][keysConsumi[j]]))
                                }
                        }
                        valuesDataset.push(valpush);
                    }

                    datasets.push({
                    label: this.$t('message.' + consumi[cc]),
                    backgroundColor: color,
                    data: valuesDataset
                    });
                   
                }
            }
            return datasets;
        },
        // calcChartHeight(dataset,legend_space=false){
        //     return (dataset && dataset.length>0)?((8*(dataset.length)) + ((legend_space)?18:0)):200;
        // },
        removeData: function(chart) {
            chart.data.labels = [];
            
            /*chart.data.datasets = [
                            {
                                label: 'Spesa effettiva',
                                data: [],
                                backgroundColor: this.documentData.spesa_annua_color,
                                hoverOffset: 0,
                            },
                        ];
                        */
            /*this.annuaData.data = {
                        labels:[],
                        datasets: [
                            {
                                label: 'Spesa effettiva',
                                data: [],
                                backgroundColor: this.documentData.spesa_annua_color,
                                hoverOffset: 0,
                            },
                        ],
                    }; */          
            
            //chart.update();
        },
        resetChartsData: function(){
            this.annuaData.data = {
                        labels:[],
                        /*datasets: [
                            {
                                label: 'Spesa effettiva',
                                data: [],
                                backgroundColor: this.documentData.spesa_annua_color,
                                hoverOffset: 0,
                            },
                        ],*/
                        datasets: [
                            {
                                label: 'Spesa effettiva',
                                data: [],
                                backgroundColor: this.documentData.spesa_annua_color,
                                hoverOffset: 0,
                            },
                        ],
                    };
            this.consumoDataPOD.data = {
                        labels:[],
                        datasets: [
                            {
                                label: 'labl',
                                data: [],
                                backgroundColor: [],
                                hoverOffset: 0,
                            },
                        ],
                    };  
            this.consumoDataPDR.data = {
                        labels:[],
                        datasets: [
                            {
                                label: this.$t('message.consumo_effettivo'),
                                data: [],
                                backgroundColor: [],
                                hoverOffset: 0,
                            },
                            {
                                label: this.$t('message.consumo_stimato'),
                                data: [],
                                backgroundColor: [],
                                hoverOffset: 0,
                            },
                        ],
                    };      
        },
        populateDataAnnua: function() {
            
            let that = this;
            
            that.annuaData.data.labels = that.item.spesa_annua.map(x => {
                  return x.mese + " " + x.anno
            });
            that.item.spesa_annua.forEach(item => {
                //that.annuaData.data.labels.push(item.mese);
                that.annuaData.data.datasets[0].data.push(item.importo);
            });
        },
        populateDataConsumi: function() {
            
            let that = this;          

            if(that.item.tipo_punto === "POD")
            {
                const map = new Map(that.item.storico_consumi.map(pos => [pos.mese + " " + pos.anno, pos]));
                const uniques = [...map.values()];
                if(uniques.length>0){
                    that.consumoDataPOD.data.labels = uniques.map(x => {
                    return x.mese + " " + x.anno
                    });
                    that.consumoDataPOD.data.datasets = that.getDatasetsForPod(that.item.storico_consumi);
                }
            }
            else if(that.item.tipo_punto === "PDR")
            {
                const map = new Map(that.item.storico_consumi.map(pos => [pos.mese + " " + pos.anno, pos]));
                const uniques = [...map.values()];
                if(uniques.length>0){
                    that.consumoDataPDR.data.labels = uniques.map(x => {
                      return x.mese + " " + x.anno
                    });
                    that.consumoDataPDR.data.datasets = that.getDatasetsForPdr(that.item.storico_consumi);
                }
            }
        },
        getAddressFornitura: function(){
            let result ='';
            this.item.indirizzo.forEach(element => {
                result += element+" ";
            });
            result = result.slice(0, -1);
            return result;
        },   
        getImgConsumi: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/storico_consumi.svg";
        },
        getImgSpesaAnnua: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/storico_consumi.svg";
        },
        getimgTipoPunto: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/" + this.item.tipo_punto + ".svg";
        },
        getimgAddress: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/pod_address.svg";
        },
        createDiagonalPattern: function(color = 'black') {
        // create a 10x10 px canvas for the pattern's base shape
        let shape = document.createElement('canvas')
        shape.width = 10
        shape.height = 10
       // get the context for drawing
        let c = shape.getContext('2d')
       //per colorare background
        c.fillStyle = color;
        c.fillRect(0,0,10,10); 
       
        c.lineWidth = 1;
        // draw 1st line of the shape 
        //per colorare line
        c.strokeStyle = 'white';
        c.beginPath()
        c.moveTo(0, 8)
        c.lineTo(8, 0)
        c.stroke()
        // draw 2nd line of the shape 
        c.beginPath()
        c.moveTo(8, 10)
        c.lineTo(10, 8)
        c.stroke()

        // create the pattern from the shape
        return c.createPattern(shape, 'repeat')
        },
        openClose() {
            let that = this;
            //that.$emit("openClose", that.$vnode.key);
            that.show = !that.show;
        }, 
        initMethod: function () {
            let firstrun= (this.annuaVariable ==null && this.consumoVariable==null);

            if(this.item.spesa_annua && this.item.spesa_annua.length>1 && this.item.spesa_annua[1].data && this.item.spesa_annua[0].data && moment(this.item.spesa_annua[1].data).isBefore(this.item.spesa_annua[0].data))
                this.item.spesa_annua = this.item.spesa_annua.reverse();
            //reverse array storico
            if(this.item.storico_consumi && this.item.storico_consumi.length>1 && this.item.storico_consumi[1].data && this.item.storico_consumi[0].data && moment(this.item.storico_consumi[1].data).isBefore(this.item.storico_consumi[0].data))
                this.item.storico_consumi = this.item.storico_consumi.reverse();
            // if(!firstrun) {
            //     this.removeData(this.annuaVariable);
            //     this.removeData(this.consumoVariable);
            // }
            this.resetChartsData();

            this.populateDataAnnua();
            this.populateDataConsumi();
            // this.annuaHeight = this.calcChartHeight(this.annuaData.data.datasets[0].data); 
            // this.consumoHeight =(this.item.tipo_punto === "PDR")? this.calcChartHeight(this.consumoDataPDR.data.datasets[1].data) : this.calcChartHeight(this.consumoDataPOD.data.datasets[1].data);         
            
            this.annuaHeight = (this.annuaData.data.datasets[0].data) ? 100 : 1; 
            this.consumoHeight = (this.annuaData.data.datasets[0].data) ? 100 : 1;          

            if(!firstrun){
                this.annuaVariable.update();
                this.consumoVariable.update();
            }
        }
    },
    beforeMount(){
        this.initMethod();
        /*this.populateDataAnnua();
        this.populateDataConsumi();
        this.annuaHeight = this.calcChartHeight(this.annuaData.data.datasets[0].data); 
        this.consumoHeight =(this.item.tipo_punto === "PDR")? this.calcChartHeight(this.consumoDataPDR.data.datasets[1].data,true) : this.calcChartHeight(this.consumoDataPOD.data.datasets[1].data,true);*/         
    },
    mounted: function()
    {
        let that = this;
        //that.populateDataAnnua();
        //that.populateDataConsumi();
        that.$root.$on("changedFornituraSelected", this.initMethod);
        const ctxA = that.$refs["annua-chart"];
        that.annuaVariable = new Chart(ctxA, that.annuaData);
        const ctxB = that.$refs["consumo-chart"];
        that.consumoVariable = that.item.tipo_punto === "POD" ? new Chart(ctxB, that.consumoDataPOD) : new Chart(ctxB, that.consumoDataPDR);
    }

	/*created: function () {
        let that = this;
        that.bolletta_interattiva_esponi_storico_consumi = Boolean(Number(that.documentData.bolletta_interattiva_esponi_storico_consumi));
        that.bolletta_interattiva_esponi_storico_consumi_icon = Boolean(Number(that.documentData.bolletta_interattiva_esponi_storico_consumi_icon));
	}*/
}
</script>
