<template>
  <v-container id="MultiComponentSwitch5Dt">
      <template>
          <v-container v-if="switcherElements && switcherElements.length>0">
            <v-row class="d-flex" justify="center" align="center">
              <!-- <v-col v-for="(em, i) in switcherElements" :key="'component-' + i" @click="selectedComponent=em['comp']"> -->
              <v-col v-for="(em, i) in switcherElements" :key="'component-' + i" @click="selectedComponent=em['comp'],ChangeSelection(em)">
                  <v-list-item>
                    <v-list-item-content align="center">
                      <div class="cardmoduleContainer" >
                         <img :class="(selectedComponent == em['comp']) ? 'cardmoduleImgSelected' : 'cardmoduleImg'"
                            v-if="(selectedComponent == em['comp']) ? em['imgsel'] : em['img']" 
                            :src="getimgComponent((selectedComponent == em['comp']) ? em['imgsel'] : ((em['hover'])?em['imghover']:em['img']))" 
                            :id="'logo_component '+ i" @error="() => {(selectedComponent == em['comp']) ? em['imgsel']  = null : em['img'] = null}"
                            @mouseover="em['hover'] = true" @mouseleave="em['hover'] = false"
                            />
                      </div>
                    </v-list-item-content>
                  </v-list-item>
              </v-col>
            </v-row>
            <!--v-row v-if="selectedComponent">
              <component :is="getModule(selectedComponent)" :documentData="documentData" :error="error" :translateLabel="translateLabel"></component>
            </v-row-->


          </v-container>
      </template>
    </v-container>
</template>

<script>

import {store} from '../../../store/index.js'

export default {
  components: {
    },
  props: {
    documentData: {
      type: Object,
      default: null,
    },
    modules: {
      type: Object,
      default: null,
    },
        error: {
      type: Number,
      default: null
    },
    translateLabel: {
        type: Function,
    },
  },
  computed: { 
      isMobile: function () {
          return this.$vuetify.breakpoint.mobile;
      }
  },
  data() {
      return {
      switcherElements: null,
      selectedComponent : null
      }
  },
  mounted() {
    this.AddComponent();
    if(!this.selectedComponent && this.switcherElements.length>0 ) 
    {
      this.selectedComponent = this.switcherElements[0]['comp'];
      this.ChangeSelection( this.switcherElements[0]);
    }
     
  },
  methods: {
     AddComponent(){
      if(!this.isMobile)
        this.switcherElements = [ 
                        {
                          comp: ['riepilogo_doc','web_pdf','info_pagamento','costi','consumi','warning','scarica_pdf'],
                          img: "/images/bollettainterattiva/Template5/Core/menu_ico_home.svg", //this.documentData.tipo_bolletta==='GAS' ? "/images/bollettainterattiva/desktop_menu_sintesi_gas_unsel.svg" : "/images/bollettainterattiva/desktop_menu_sintesi_ee_unsel.svg",
                          imgsel: "/images/bollettainterattiva/Template5/Core/menu_ico_home_active.svg", //this.documentData.tipo_bolletta==='GAS' ? "/images/bollettainterattiva/desktop_menu_sintesi_gas_sel.svg" : "/images/bollettainterattiva/desktop_menu_sintesi_ee_sel.svg",
                          imghover: "/images/bollettainterattiva/Template5/Core/menu_ico_home_hover.svg", //this.documentData.tipo_bolletta==='GAS' ? "/images/bollettainterattiva/desktop_menu_sintesi_gas_sel.svg" : "/images/bollettainterattiva/desktop_menu_sintesi_ee_sel.svg",
                          hover:false
                        },
                        {
                          comp: ['riepilogo_doc','area_riservata','fast_action'],
                          img: "/images/bollettainterattiva/Template5/Core/menu_ico_azioni.svg",
                          imgsel: "/images/bollettainterattiva/Template5/Core/menu_ico_azioni_active.svg",
                          imghover: "/images/bollettainterattiva/Template5/Core/menu_ico_azioni_hover.svg",
                          hover:false
                        },
                        {
                          comp: "place",
                          img: "/images/bollettainterattiva/Template5/Core/menu_ico_point.svg",
                          imgsel: "/images/bollettainterattiva/Template5/Core/menu_ico_point_active.svg",
                          imghover: "/images/bollettainterattiva/Template5/Core/menu_ico_point_hover.svg",
                          url: "https://www.unoenergy.it/dove-siamo/",
                          hover:false
                        },
                        {
                          comp: ['riepilogo_doc','help','area_riservata'],
                          img: "/images/bollettainterattiva/Template5/Core/menu_ico_aiuto.svg",
                          imgsel: "/images/bollettainterattiva/Template5/Core/menu_ico_aiuto_active.svg",
                          imghover: "/images/bollettainterattiva/Template5/Core/menu_ico_aiuto_hover.svg",
                          hover:false
                        },
                        {
                          comp: ["miei_dati_mio_contratto","area_riservata"],
                          img: "/images/bollettainterattiva/Template5/Core/menu_ico_profilo.svg",
                          imgsel: "/images/bollettainterattiva/Template5/Core/menu_ico_profilo_active.svg",
                          imghover: "/images/bollettainterattiva/Template5/Core/menu_ico_profilo_hover.svg",
                          hover:false
                        }
                      ];
     else this.switcherElements = [ 
                        {
                          comp: ['riepilogo_doc','web_pdf','info_pagamento','costi','consumi','warning','scarica_pdf'],
                          img: "/images/bollettainterattiva/Template5/Core/menu_ico_home.svg", //this.documentData.tipo_bolletta==='GAS' ? "/images/bollettainterattiva/desktop_menu_sintesi_gas_unsel.svg" : "/images/bollettainterattiva/desktop_menu_sintesi_ee_unsel.svg",
                          imgsel: "/images/bollettainterattiva/Template5/Core/menu_ico_home_active.svg", //this.documentData.tipo_bolletta==='GAS' ? "/images/bollettainterattiva/desktop_menu_sintesi_gas_sel.svg" : "/images/bollettainterattiva/desktop_menu_sintesi_ee_sel.svg",
                          imghover: "/images/bollettainterattiva/Template5/Core/menu_ico_home_hover.svg", //this.documentData.tipo_bolletta==='GAS' ? "/images/bollettainterattiva/desktop_menu_sintesi_gas_sel.svg" : "/images/bollettainterattiva/desktop_menu_sintesi_ee_sel.svg",
                          hover:false,
                          direction:null
                        },
                        {
                          comp: ['header_mobile_fast_actions','area_riservata','fast_action'],
                          img: "/images/bollettainterattiva/Template5/Core/menu_ico_azioni.svg",
                          imgsel: "/images/bollettainterattiva/Template5/Core/menu_ico_azioni_active.svg",
                          imghover: "/images/bollettainterattiva/Template5/Core/menu_ico_azioni_hover.svg",
                          hover:false,
                          direction:"right"
                        },
                        {
                          comp: "place",
                          img: "/images/bollettainterattiva/Template5/Core/menu_ico_point.svg",
                          imgsel: "/images/bollettainterattiva/Template5/Core/menu_ico_point_active.svg",
                          imghover: "/images/bollettainterattiva/Template5/Core/menu_ico_point_hover.svg",
                          url: "https://www.unoenergy.it/dove-siamo/",
                          hover:false,
                          direction:null
                        }
                      ]
     },
   
    ChangeSelection(sel){
      store.commit('SET_DIRECTION', sel['direction']);
      
      if(sel['url']!=null)
      {
        this.selectedComponent = this.switcherElements[0]['comp'];
        window.open(sel['url'], '_blank');
      }
      else{
        store.commit('SET_COMPONENTS', sel['comp']);
        this.$store.getters.components;
        
        if(this.isMobile)
          this.selectedComponent = this.switcherElements[0]['comp'];

      }
    },
    getModule(name){
      return this.modules[name];
    },
    getimgComponent(imageName) {
      return "./" + location.hostname + imageName;
    },
  },
};
</script>
<style scoped>
.cardmoduleTextSelected {
  color: white;
}
.cardmoduleText {
  color: black;
}
.moduleSelected {
  background: orange!important;
}
.module {
  background: white!important;
}
</style>