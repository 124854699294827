<template>
  <v-container id="bollettaInterattivaDatiPagamento" class="px-0">
<!--
      <v-row class="titlecontainer">
        <h1 class="title" v-html="$t('message.bolletta_interattiva_dati_pagamento_title')"></h1>
      </v-row>
      <div class="detailcontainer">
        <div>
          <span class="normal" v-html="$t('message.bolletta_interattiva_dati_pagamento_num')"></span>
          <span class="normal"> </span>
          <span class="bold">8391032</span>
        </div>
        <div>
          <span class="normal" v-html="$t('message.bolletta_interattiva_dati_pagamento_time')"></span>
          <span class="normal"> </span>
          <span class="bold">12/07/22022</span>
        </div>
      </div>
      <div class="periodcontainer">
        <div>
          <span class="normal" v-html="$t('message.bolletta_interattiva_dati_pagamento_period')"></span>
        </div>
        <div>
          <span class="bold">Giugno-Luglio 2022</span>
        </div>
      </div>
-->
      <v-card class="dataCard" outlined>
        <div v-if="documentData.dati_pagamento_fields.doc_amount.value != documentData.dati_pagamento_fields.doc_payment_debt.value">
        <div class="datacontainer">
          <span class="normal" v-html="$t('message.' +  documentData.dati_pagamento_fields.doc_amount.label)"></span>
        </div>
        <div class="datacontainer">
          <span class="data">€ {{documentData.dati_pagamento_fields.doc_amount.value}}</span>
        </div>
        <hr/>
        </div>
        <div class="datacontainer">
          <span class="normal" v-html="$t('message.' +  documentData.dati_pagamento_fields.doc_payment_debt.label)"></span>
        </div>
        <div class="datacontainer">
          <span class="databold">€ {{documentData.dati_pagamento_fields.doc_payment_debt.value}}</span>
        </div>
        <div v-if="documentData.dati_pagamento_fields.doc_payment_debt.value != '0' &&
        documentData.dati_pagamento_fields.doc_payment_debt.value != '0,00' && 
        documentData.dati_pagamento_fields.doc_payment_debt.value != '0.00'">
        <div class="datacontainer">
          <span class="normal" v-html="$t('message.' +  documentData.dati_pagamento_fields.doc_payment_expiry.label)"></span>
        </div>
        <div class="datacontainer">
          <span class="data">{{documentData.dati_pagamento_fields.doc_payment_expiry.value}}</span>
        </div>
        </div>

        <div v-if="documentData.bolletta_interattiva_esponi_stato_pagamento==1 &&
        (documentData.document_payment_status==0 || documentData.document_payment_status==3 || documentData.document_payment_status==4)" class="datacontainer">
          <div v-if="documentData.document_payment_status==0">
            <div class="notifiedUpperMessageCanceledContainer">
              <v-icon class="notifiedImageCanceledContainer">mdi-emoticon-sad-outline</v-icon>
              <div>
                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_annullato')"></span>
              </div>
            </div>
          </div>
          <div v-if="documentData.document_payment_status==3">
            <div class="notifiedUpperMessageValidateContainer">
              <v-icon class="notifiedImageValidateContainer">mdi-timer-sand</v-icon>
              <div>
                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_davalidare')"></span>
              </div>
            </div>
          </div>
          <div v-if="documentData.document_payment_status==4">
            <div class="notifiedUpperMessagePaidContainer">
              <v-icon class="notifiedImagePaidContainer">mdi-emoticon-happy-outline</v-icon>
              <div>
                <span class="notification" v-html="$t('message.bolletta_interattiva_dati_pagamento_pagamento_ok')"></span>
              </div>
            </div>
          </div>
        </div>

      <v-card v-if="documentData.bolletta_interattiva_esponi_paga_ora==1 &&
        documentData.document_payment!='rid' && documentData.document_payment_status!=0 &&
         documentData.document_payment_status!=3 && documentData.document_payment_status!=4" class="payCard">
          <div class="datacontainer">
            <span class="datacontainer ">
          <img class="euroImage" :src="getEuroImage()" contain/>
            </span>
            <span class="paylarge" v-html="$t('message.bolletta_interattiva_pagaora_header')"></span>
          </div>
          
          <div v-if="documentData.bolletta_interattiva_pagaora_1==1">
            <div class="datacontainer">
              <span class="paymedium" v-html="$t('message.bolletta_interattiva_pagaora_1')"></span>
            </div>
            <div class="datacontainer">
              <v-btn v-bind="size"
                :href=documentData.bolletta_interattiva_link_pagaora_1 target="_blank"
                class="payButton"
                depressed
                  outlined
                  rounded
                  >
                  <img class="payCcImage" :src="getPay1Image()" contain/>
                  <span class="payButton_content" v-html="$t('message.bolletta_interattiva_button_pagaora_1')"></span>
              </v-btn>
            </div>    
          </div>

          <div v-if="documentData.bolletta_interattiva_pagaora_2==1">
            <div class="datacontainer">
              <span class="paymedium" v-html="$t('message.bolletta_interattiva_pagaora_2')"></span>
            </div>
            <div class="datacontainer">
              <v-btn v-bind="size"
              :href=documentData.bolletta_interattiva_link_pagaora_2 target="_blank"
                class="payButton"
                depressed
                  outlined
                  rounded
                  >
                  <img class="payCcImage" :src="getPay2Image()" contain/>
                  <span class="payButton_content" v-html="$t('message.bolletta_interattiva_button_pagaora_2')"></span>
              </v-btn>
            </div>    
          </div>

          <div v-if="documentData.bolletta_interattiva_pagaora_3==1">
            <div class="datacontainer">
              <span class="paymedium" v-html="$t('message.bolletta_interattiva_pagaora_3')"></span>
            </div>
            <div class="datacontainer">
              <v-btn v-bind="size"
              :href=documentData.bolletta_interattiva_link_pagaora_3 target="_blank"
                class="payButton"
                depressed
                  outlined
                  rounded
                  >
                  <img class="payCcImage" :src="getPay3Image()" contain/>
                  <span class="payButton_content" v-html="$t('message.bolletta_interattiva_button_pagaora_3')"></span>
              </v-btn>
            </div>    
          </div>

          <div v-if="documentData.bolletta_interattiva_pagaora_4==1">
            <div class="datacontainer">
              <span class="paymedium" v-html="$t('message.bolletta_interattiva_pagaora_4')"></span>
            </div>
            <div class="datacontainer">
              <v-btn v-bind="size"
              :href=documentData.bolletta_interattiva_link_pagaora_4 target="_blank"
                class="payButton"
                depressed
                  outlined
                  rounded
                  >
                  <img class="payCcImage" :src="getPay4Image()" contain/>
                  <span class="payButton_content" v-html="$t('message.bolletta_interattiva_button_pagaora_4')"></span>
              </v-btn>
            </div>    
          </div>

          <div v-if="documentData.bolletta_interattiva_pagaora_5==1">
            <div class="datacontainer">
              <span class="paymedium" v-html="$t('message.bolletta_interattiva_pagaora_5')"></span>
            </div>
            <div class="datacontainer">
              <v-btn v-bind="size"
              :href=documentData.bolletta_interattiva_link_pagaora_5 target="_blank"
                class="payButton"
                depressed
                  outlined
                  rounded
                  >
                  <img class="payCcImage" :src="getPay5Image()" contain/>
                  <span class="payButton_content" v-html="$t('message.bolletta_interattiva_button_pagaora_5')"></span>
              </v-btn>
            </div>    
          </div>

          
          <div v-if="documentData.bolletta_interattiva_pagaora_boll==1">
            <div class="datacontainer">
              <span class="paymedium" v-html="$t('message.bolletta_interattiva_pagaora_boll')"></span>
            </div>
            <div class="datacontainer">
              <v-btn v-bind="size"
                class="payButton"
                depressed
                  outlined
                  rounded
                  @click="downloadFile"
                  >
                  <img class="payFormImage" :src="getPayBollImage()" contain/>
                  <span class="payButton_content" v-html="$t('message.bolletta_interattiva_button_pagaora_boll')"></span>
              </v-btn>
          </div>
        </div>

      </v-card>

      <div v-if="documentData.bolletta_interattiva_esponi_rid==1 && documentData.document_payment!='rid'">
        <div class="datacontainer">
          <v-img class="ridImage" :src="getRidImage()" contain />
        </div>
        <div class="datacontainer">
          <span class="ridtitle" v-html="$t('message.bolletta_interattiva_rid_label')"></span>
        </div>
        <div class="datacontainer">
          <span class="ridtext" v-html="$t('message.bolletta_interattiva_rid_text')"></span>
        </div>
        <div class="datacontainer">
          <v-btn v-bind="size" :href=documentData.bolletta_interattiva_rid_link target="_blank" class="ridButton" depressed
            outlined rounded> <span class="ridButton_content" v-html="$t('message.bolletta_interattiva_rid_button')"></span>
          </v-btn>
        </div>
      
       
      
      </div>
      <BollettaInterattivaChart2 v-if="documentData.bolletta_interattiva_bottone_apri_sintesi_importi==1"
          :documentData="documentData"></BollettaInterattivaChart2>

    </v-card>

      
  </v-container>
</template>

<script>
import BollettaInterattivaChart2 from './BollettaInterattivaChart2.vue';
import { services } from "../scripts/services/serviceBuilder";

export default {
  components: {
    BollettaInterattivaChart2,
  },
  data() {
    return {
      enabledModules: ["sintesi_importi2"],
      modules: {
          "sintesi_importi2": "BollettaInterattivaChart2",
      },
      overlayVisible: true,
    };
  },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
  methods: {
    getIconLogo: function () {
      return "./" + location.hostname + "/images/sadIconLogo.png";
    },
    amountWithCurrency: function (_import) {
            return !isNaN(_import.replace(".","").replace(",","."))? _import + " €" : _import;
    },
    getRidImage: function() {
            return './' + location.hostname + '/images/bollettainterattiva/rid.jpg';
    },
    getEuroImage: function() {
            return './' + location.hostname + '/images/bollettainterattiva/euro_icon.png';
    },
    getPay1Image: function() {
            return './' + location.hostname + '/images/bollettainterattiva/payment_1.jpg';
    },
    getPay2Image: function() {
            return './' + location.hostname + '/images/bollettainterattiva/payment_2.jpg';
    },
    getPay3Image: function() {
            return './' + location.hostname + '/images/bollettainterattiva/payment_3.jpg';
    },
    getPay4Image: function() {
            return './' + location.hostname + '/images/bollettainterattiva/payment_4.jpg';
    },
    getPay5Image: function() {
            return './' + location.hostname + '/images/bollettainterattiva/payment_5.jpg';
    },
    getPayBollImage: function() {
            return './' + location.hostname + '/images/bollettainterattiva/pagaora_boll.jpg';
    },
    downloadFile: function() {
      var that = this;

      services.apiCallerMedia.download(that.documentData.detail)
        .then(response =>  {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
        })               
        .catch(error => {
            console.log(error);
        });
      }
  },
}
</script>

<style>
.divCardSpecific {
  float: right;
}
</style>
