
<template>
  <v-container id="bollettaInterattivaFornitura" class="pa-0">
    <h1 class="dett_forn_header">{{ $t('message.bolletta_interattiva_dett_forn_header') }}</h1>
    <v-card v-for="(pod, i) in pods" :key="'pod-' + i" class="cardClass elevation-0" outlined>
      <v-card-text>
        <v-list-item>
          <v-list-item-content class="pa-0">
            <v-list-item-title class="ma-0">
            </v-list-item-title>
            <v-list class="pa-0">
                <v-list-item>
                   <!-- <v-text-field
                      :label="$t('message.bolletta_interattiva_dett_forn_pod')"
                      v-model="pod.sigla"
                      readonly
                      style="font-weight: bold;"
                    >
                    <template #prepend-inner>
                      <div style="width:30px">
                        <v-img contain style="margin: auto 0" max-height="25" max-width="30" :src="getPodImage()"/>
                      </div>
                    </template>
                  </v-text-field>-->
                  <div style="width:30px">
                        <v-img contain style="margin: auto 0" max-height="25" max-width="30" :src="getPodImage()"/>
                      </div>
                    <p><span class="lightLabel">{{ $t('message.bolletta_interattiva_dett_forn_pod') }}: </span>
                      <br/>
                      <strong style="font-size:medium"> {{ pod.sigla }}</strong></p>
                </v-list-item>
                <v-list-item>
                 <!--  <v-text-field
                      :label="$t('message.bolletta_interattiva_dett_forn_pod_address')"
                      v-model="pod.indirizzo"
                      readonly
                      style="font-weight: bold;"
                    >                    
                    <template #prepend-inner>
                      <div style="width:30px">
                        <v-img contain style="margin: auto 0" max-height="25" max-width="30" :src="getAddressImage()"/>
                      </div>
                    </template>
                  </v-text-field> -->
                  <div style="width:30px">
                        <v-img contain style="margin: auto 0" max-height="25" max-width="30" :src="getAddressImage()"/>
                      </div>
                    <p><span class="lightLabel">{{ $t('message.bolletta_interattiva_dett_forn_pod_address') }}:</span> 
                      <br/>
                      <strong style="font-size:medium; white-space: pre-line;"> {{ pod.indirizzo }}</strong></p>
                </v-list-item>
            </v-list>
          </v-list-item-content>
        </v-list-item>
        <slide-up-down :active="showThisModule[i]">
          <v-list-item v-show="bolletta_interattiva_esponi_dettaglio_fornitura">
            <v-list-item-content class="pa-0">
              <v-list class="pa-0">
                <v-list-item>
                    <!--  <v-text-field
                        :label="$t('message.bolletta_interattiva_dett_forn_pod_type')"
                        v-model="pod.tipologia"
                        readonly
                        style="font-weight: bold;"
                      >
                      <template #prepend-inner>
                        <div style="width:30px">
                          <v-img contain style="margin: auto 0" max-height="25" max-width="30" :src="getTipologiaImage()"/>
                        </div>
                      </template>
                    </v-text-field>-->
                    <div style="width:30px">
                        <v-img contain style="margin: auto 0" max-height="25" max-width="30" :src="getTipologiaImage()"/>
                      </div>
                    <p><span class="lightLabel">{{ $t('message.bolletta_interattiva_dett_forn_pod_type') }}: </span>
                      <br/>
                      <strong style="font-size:medium"> {{ pod.tipologia }}</strong></p>
                    </v-list-item>
                    <v-list-item>
                        <p>{{ $t('message.bolletta_interattiva_consumi_pot_imp') }}: <strong> {{ pod.details.powerEmployed }}</strong></p>
                    </v-list-item>
                    <v-list-item>
                        <p>{{ $t('message.bolletta_interattiva_consumi_pot_disp') }}: <strong> {{ pod.details.powerAvailable }}</strong></p>
                    </v-list-item>
                    <v-list-item>
                        <p>{{ $t('message.bolletta_interattiva_consumi_tipo_off') }}: <strong> {{ pod.details.tipoOfferta }}</strong></p>
                    </v-list-item>
                    <v-list-item>
                        <p>{{ $t('message.bolletta_interattiva_consumi_data_att') }}: <strong> {{ pod.details.dataAttivazione }}</strong></p>
                    </v-list-item>
                    <v-list-item>
                        <p>{{ $t('message.bolletta_interattiva_consumi_matricola') }}: <strong> {{ pod.details.matricolaContatore }}</strong></p>
                    </v-list-item>
              </v-list>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-show="bolletta_interattiva_esponi_consumi">
            <v-list-item-content class="pa-0">
              <v-list-item-title class="ma-0">
                <p class="pt-2 consumi_header">
                  {{ $t('message.bolletta_interattiva_consumi_header') }}
                </p>
              </v-list-item-title>
              <p>{{ $t('message.bolletta_interattiva_consumi_text') }}:</p>
              <v-list>
                <v-list-item v-for="(item, j) in pod.monthlyConsuption"
                      :key="'label-'+j" class="labelItem">
                    <v-img :src="getConsumiIcon()" contain style="margin: auto 0; float: left;" max-height="25" max-width="30"></v-img>
                  <span style="padding-top:2px; ">
                    <p style="color:gray; display: inline;"> <strong style="color: black">{{ item.kw }}</strong> <br> {{ $t('message.bolletta_interattiva_consumi_text_period') }}
                      {{item.daysN}} {{ $t('message.bolletta_interattiva_consumi_text_days') }} {{item.month}} {{item.anno}}</p>
                  </span>

                </v-list-item>
              </v-list>
            </v-list-item-content>
          </v-list-item>
          <!-- grafico dei consumi-->
          <v-list-item v-show="bolletta_interattiva_esponi_storico_consumi">
            <v-list-item-content class="pa-0">
              <v-list-item-title class="ma-0">
                <p class="pt-2 consumi_header">
                  {{ $t('message.bolletta_interattiva_consumi_chart') }}
                </p>
                <div style="float: left; margin:auto; width: 90%; height: 300px;">
                  <canvas :ref="'stacked-barchart-canvas-'+i" height="200px"> </canvas>
                </div>
              </v-list-item-title>

            </v-list-item-content>
          </v-list-item>
        </slide-up-down>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pa-0;">
        <v-btn v-if="showButton"
              depressed
              rounded
              class="btnCloseOpen black--text justify-center"
              @click="showModule(i)"
              style="margin-bottom:-25px;">
          {{ showThisModule[i] ? '- ' + $t('message.bolletta_interattiva_bottone_dettagli_close') : '+ ' + $t('message.bolletta_interattiva_bottone_dettagli_open') }}
        </v-btn>
      </v-card-actions>
   </v-card>
  </v-container>
</template>

<script>
import Chart from "chart.js";
import SlideUpDown from 'vue-slide-up-down'

export default {
  data: () => ({
    pods: [],
    showThisModule: [],
    bolletta_interattiva_esponi_dettaglio_fornitura: true,
    bolletta_interattiva_esponi_consumi: true,
    bolletta_interattiva_esponi_storico_consumi: true,
    maxColor: 5,
  }),
  components: {
        SlideUpDown
  },
  props: {
    documentData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    showButton: function () {
      return (this.bolletta_interattiva_esponi_dettaglio_fornitura ||
              this.bolletta_interattiva_esponi_consumi ||
              this.bolletta_interattiva_esponi_storico_consumi);
    }
  },
  methods: {
    getElementColor: function () {
    },
    amountWithCurrency: function () {

    },
    populateData: function() {
      this.pods = [];
      var apiData = this.documentData.fatt_interattiva_data.fornitura;
      for(let i = 0; i < apiData.length; i++) {
        let datum = apiData[i];
        this.pods.push({
              sigla: datum.pod,
              indirizzo: datum.indirizzo.join('\n'),
              tipologia: datum.dettagli_fornitura.tipologia,
              details: {
                powerEmployed: datum.dettagli_fornitura.potenza_impegnata,
                powerAvailable: datum.dettagli_fornitura.potenza_disponibile,
                tipoOfferta: datum.dettagli_fornitura.tipologia_offera,
                dataAttivazione: datum.dettagli_fornitura.data_attivazione_fornitura,
                matricolaContatore: datum.dettagli_fornitura.matricola_contatore
              },
              monthlyConsuption: datum.consumi.map(x => {
                return {
                kw: x.consumo,
                daysN: x.n_giorni,
                month: x.mese,
                anno: x.anno,
                data: x.data,
                stimato_reale: x.stimato_reale
              }
              }),
            chartData: {
              type: "bar",
              data: {
                labels: datum.storico_consumi.map(x => {
                  return x.mese + " " + x.anno
                }), // asse x
                datasets:  this.getDatasets(datum.storico_consumi),
              },
              options: {
                responsive: true, 
                maintainAspectRatio: false,
                  legend: {
                    display: true,
                    position: "bottom",
                  },
                  scales: {
                  xAxes: [{
                      stacked: true // this should be set to make the bars stacked
                  }],
                  yAxes: [{
                      stacked: true // this also..
                  }]
                }
              },
            },
            showThisModule: true,
            chartVariable: null,
            barchartCanvas: null,
        });
      }
    },
    getDatasets: function(storicoConsumiArray) {
      let keysConsumi = Object.keys(storicoConsumiArray[0]).filter(k => k.startsWith("consumo_f"));
      var datasets = [];
      for (let j = 0; j < keysConsumi.length; j++) {
        let kindConsumo = keysConsumi[j]; //.substring(0, 6) + 'i' + keysConsumi[j].substring(7);
        datasets.push({
          label: this.$t('message.' + this.documentData[kindConsumo].split(',')[0]),
          backgroundColor: this.documentData[kindConsumo].split(',')[1],
          data: []
        });
        for(let i = 0; i < storicoConsumiArray.length; i++) {
          datasets[j].data.push(Number(storicoConsumiArray[i][keysConsumi[j]]));
        }
      }
      return datasets;
    },
    getPodImage: function() {
      return './' + location.hostname + '/images/bollettainterattiva/Pod_Icon.png';
    },
    getTipologiaImage: function() {
      return './' + location.hostname + '/images/bollettainterattiva/Tipologia_Icon.jpg';
    },
    getAddressImage: function() {
      return './' + location.hostname + '/images/bollettainterattiva/Address_icon.png';
    },
    getConsumiIcon: function() {
      return './' + location.hostname + '/images/bollettainterattiva/Consumi_Icon.png';
    },
    showModule: function(i) {
      this.populateData();

      let support = Array(this.showThisModule.length).fill(false);
      support[i] = !this.showThisModule[i];
      this.showThisModule = support;

      if(this.showThisModule[i]) {
          this.createChart(i);
      }
    },

    createChart: function(i) {
      let ref = "stacked-barchart-canvas-"+ i;
      const ctx = this.$refs[ref];
      this.pods[i].chartVariable = new Chart(ctx, this.pods[i].chartData);
      this.pods[i].barchartCanvas = ctx;
    }
  },
  created() {
    this.populateData();
  },
  mounted() {
    this.populateData();
    for (let i = 0; i < this.pods.length; i++) {
      this.showThisModule.push(false);
      this.createChart(i);
    }
    this.bolletta_interattiva_esponi_dettaglio_fornitura = Boolean(Number(this.documentData.bolletta_interattiva_esponi_dettaglio_fornitura));
    this.bolletta_interattiva_esponi_consumi = Boolean(Number(this.documentData.bolletta_interattiva_esponi_consumi));
    this.bolletta_interattiva_esponi_storico_consumi = Boolean(Number(this.documentData.bolletta_interattiva_esponi_storico_consumi));
  },
};
</script>
<style scoped>
.v-text-field >>> .v-input__slot::before {
  border-style: none !important;
}
.v-text-field {
  font-weight: bold;
}

</style>