<template>
  <v-container id="MultiComponentSwitch3Dt" class="pa-7">
      <template>
          <v-container v-if="switcherElements.length>0">
            <p class="text-h5 LabelViewDetails">{{checkLabel('message.bolletta_interattiva_duferco_visualizza_dettaglio_3Dt') ? "" : $t('message.bolletta_interattiva_duferco_visualizza_dettaglio_3Dt')}}</p>
            <v-row class="d-flex" justify="center" align="center">
              <v-col v-for="(em, i) in switcherElements" :key="'component-' + i" @click="selectedComponent=em['comp']">
                <v-card :class="(selectedComponent == em['comp']) ? 'moduleSelected' : 'module'" class="rounded-xl" >
                  <v-list-item>
                    <v-list-item-content align="center">
                      <div class="cardmoduleContainer" >
                        <p  :class="(selectedComponent == em['comp']) ? 'cardmoduleTextSelected' : 'cardmoduleText'" style="text-align:center;">{{checkLabel('message.bolletta_interattiva_duferco_module_' + em['lbl']) ? (em['lbl'].replace("_"," ")) : $t('message.bolletta_interattiva_duferco_module_' + em['lbl'])}}</p>  
                        <img :class="(selectedComponent == em['comp']) ? 'cardmoduleImgSelected' : 'cardmoduleImg'"
                            v-if="(selectedComponent == em['comp']) ? em['imgsel'] : em['img']" 
                            :src="getimgComponent((selectedComponent == em['comp']) ? em['imgsel'] : em['img'])" 
                            :id="'logo_component '+ i" @error="() => {(selectedComponent == em['comp']) ? em['imgsel']  = null : em['img'] = null}"/>
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>
            </v-row>
            <v-row v-if="selectedComponent">
              <component :is="getModule(selectedComponent)" :documentData="documentData" :error="error" :checkLabel="checkLabel"></component>
            </v-row>
          </v-container>
      </template>
    </v-container>
</template>

<script>
import BollettaInterattivaProfiloDuferco3Dt from './BollettaInterattivaProfiloDuferco3Dt.vue';
import BollettaInterattivaAutoletturaDuferco3Dt from './BollettaInterattivaAutoletturaDuferco3Dt.vue';
import BollettaInterattivaDatiPagamentoBody3Dt from './BollettaInterattivaDatiPagamentoBody3Dt.vue';
import BollettaInterattivaFornitura3Dt from './BollettaInterattivaFornitura3Dt.vue';
import BollettaInterattivaDatiFornitura3Dt from './BollettaInterattivaDatiFornitura3Dt.vue';
import BollettaInterattivaCustomerCare3Dt from './BollettaInterattivaCustomerCare3Dt.vue';

export default {
  components: {
        BollettaInterattivaProfiloDuferco3Dt,
        BollettaInterattivaAutoletturaDuferco3Dt,
        BollettaInterattivaDatiPagamentoBody3Dt,
        BollettaInterattivaFornitura3Dt,
        BollettaInterattivaDatiFornitura3Dt,
        BollettaInterattivaCustomerCare3Dt
    },
  props: {
    documentData: {
      type: Object,
      default: null,
    },
    modules: {
      type: Object,
      default: null,
    },
    checkLabel: {
        type: Function,
    },
        error: {
      type: Number,
      default: null
    }
  },
  data() {
      return {
      switcherElements:[ 
                        {
                          comp: "dati_pagamento_sintesi",
                          img: this.documentData.tipo_bolletta==='GAS' ? "/images/bollettainterattiva/desktop_menu_sintesi_gas_unsel.svg" : "/images/bollettainterattiva/desktop_menu_sintesi_ee_unsel.svg",
                          imgsel: this.documentData.tipo_bolletta==='GAS' ? "/images/bollettainterattiva/desktop_menu_sintesi_gas_sel.svg" : "/images/bollettainterattiva/desktop_menu_sintesi_ee_sel.svg",
                          lbl: this.checkLabel('message.bolletta_interattiva_sintesi_importi_header') ?  '' : this.$t('message.bolletta_interattiva_sintesi_importi_header'),
                        },
                        {
                          comp: "consumi",
                          img: "/images/bollettainterattiva/desktop_menu_consumi_unsel.svg",
                          imgsel: "/images/bollettainterattiva/desktop_menu_consumi_sel.svg",
                          lbl: this.checkLabel('message.bolletta_interattiva_dett_forn_consumi') ?  '' : this.$t('message.bolletta_interattiva_dett_forn_consumi'),
                        },
                        // {
                        //   comp: "dati_fornitura_duferco",
                        //   img: "/images/bollettainterattiva/Letture_Icon.png",
                        //   imgsel: "/images/bollettainterattiva/Letture_Icon.png",
                        //   lbl: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_header') ?  '' : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_header'),
                        // }//,
                        // {
                        //   comp: "profilo_duferco",
                        //   img: "/images/bollettainterattiva/Telephone_Icon.png",
                        //   imgsel: "/images/bollettainterattiva/rid.jpg",
                        //   lbl: this.checkLabel('message.bolletta_interattiva_duferco_profilo_header_3Dt') ?  '' : this.$t('message.bolletta_interattiva_duferco_profilo_header_3Dt'),
                        // },
                        /*{
                          comp: "customer_care_duferco",
                          img: "/images/bollettainterattiva/Telephone_Icon.png",
                          imgsel: "/images/bollettainterattiva/rid.jpg",
                          lbl: this.checkLabel('message.bolletta_interattiva_duferco_customercare_header') ?  '' : this.$t('message.bolletta_interattiva_duferco_customercare_header'),
                        }*/
                      ],
      selectedComponent : null
      }
  },
  mounted() {
    this.AddComponent();
    if(!this.selectedComponent && this.switcherElements.length>0 ) 
     this.selectedComponent = this.switcherElements[0]['comp'];
  },
  methods: {
    AddComponent(){
      if(Number(this.documentData.bolletta_interattiva_duferco_dati_fornitura)){
        let dati_forn=  {
                          comp: "dati_fornitura_duferco",
                          img: "/images/bollettainterattiva/desktop_menu_datifornitura_unsel.svg",
                          imgsel: "/images/bollettainterattiva/desktop_menu_datifornitura_sel.svg",
                          lbl: this.checkLabel('message.bolletta_interattiva_duferco_dati_fornitura_header') ?  '' : this.$t('message.bolletta_interattiva_duferco_dati_fornitura_header'),
                        };
        this.switcherElements.push(dati_forn);
      }
      if(Number(this.documentData.bolletta_interattiva_duferco_profilo)){
        let profilo = {
                          comp: "profilo_duferco",
                          img: "/images/bollettainterattiva/desktop_menu_profilo_unsel.svg",
                          imgsel: "/images/bollettainterattiva/desktop_menu_profilo_sel.svg",
                          lbl: this.checkLabel('message.bolletta_interattiva_duferco_profilo_header_3Dt') ?  '' : this.$t('message.bolletta_interattiva_duferco_profilo_header_3Dt'),
                        };
        this.switcherElements.push(profilo);
      }
      if(Number(this.documentData.bolletta_interattiva_duferco_customercare)){
        let customercare = {
                            comp: "customer_care_duferco",
                            img: "/images/bollettainterattiva/desktop_menu_customer_unsel.svg",
                            imgsel: "/images/bollettainterattiva/desktop_menu_customer_sel.svg",
                            lbl: this.checkLabel('message.bolletta_interattiva_duferco_customercare_header') ?  '' : this.$t('message.bolletta_interattiva_duferco_customercare_header'),
                          }
        this.switcherElements.push(customercare);
      }
      if(Number(this.documentData.bolletta_interattiva_duferco_autolettura) && this.documentData.tipo_bolletta==='GAS'){
         let autolettura = {
                            comp: "autolettura_duferco",
                            img: "/images/bollettainterattiva/desktop_menu_autolettura_unsel.svg",
                            imgsel: "/images/bollettainterattiva/desktop_menu_autolettura_sel.svg",
                            lbl: this.checkLabel('message.bolletta_interattiva_duferco_autolettura_header') ?  '' : this.$t('message.bolletta_interattiva_duferco_autolettura_header'),
                          }
        this.switcherElements.push(autolettura);
      }

    },
    getModule(name){
      return this.modules[name];
    },
    getimgComponent(imageName) {
      return "./" + location.hostname + imageName;
    },
  },
};
</script>
<style scoped>
.cardmoduleTextSelected {
  color: white;
}
.cardmoduleText {
  color: black;
}
.moduleSelected {
  background: orange!important;
}
.module {
  background: white!important;
}
</style>