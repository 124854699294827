import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    listHeaders(customerId) {
        const url = this.webApiBaseUrl + "/api/document/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    listDocumentsInView(documentType, customerId) {
        const url = this.webApiBaseUrl + "/api/document/" + documentType + "/invoices/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    listDetailHeaders(documentType) {
        const url = this.webApiBaseUrl + "/api/document/moreinfo/headers/" + documentType;
        return this.apiCallerHelper.callGet(url);
    }
}