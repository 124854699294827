<template>
    <v-container id="bollettaInterattivaProfiloDuferco" v-if="bolletta_interattiva_duferco_profilo">
      <v-spacer class="spacerExpPanelUp"></v-spacer>
        <v-expansion-panels flat>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    <template v-slot:actions>
                            <v-icon color="white">
                            mdi-menu-down
                            </v-icon>
                        </template>
                    <h1 style="display:flex;align-items: center;"><img v-if="true" :src="getDufercoProfiloLogo()" id="dufercoProfiloLogo" class="pr-3"/>{{$t('message.bolletta_interattiva_duferco_profilo_header')}}</h1>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-row>
                        <v-col xl="12" lg="12" md="12" sm="12">
                            <p v-if="showDufercoProfiloTesto()" id="dufercoProfiloText" class="pt-3" >{{ $t('message.bolletta_interattiva_duferco_profilo_text') }}</p>
                                <v-row justify="center">                                   
                                    <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                        <div class="font-weight-medium" id="holderName">{{documentData.holder_name}}</div>
                                        <div class="font-weight-medium" id="fiscalCode">Codice Fiscale: {{documentData.document_fiscal_code}}</div>
                                        <p v-html="getTestoCompletezzaProfilo()" class="statoCompilazione"></p>
                                    </v-col>                                    
                                </v-row>
                                <v-row>                               
                                    <v-form ref="profileForm">
                                    <v-container>
                                        <v-row justify="center">
                                            <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                                <v-text-field v-bind:label="'Email'" 
                                                v-model="profiloDigitaleBean.email" outlined
                                                v-bind:prepend-inner-icon="getEmailIcon()"
                                                :rules="[v=> checkEmail(v)]"
                                                @input="showSaveButton()" 
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                                <v-text-field v-bind:label="'Mobile'" 
                                                v-model="profiloDigitaleBean.telefono" outlined
                                                v-bind:prepend-inner-icon="getMobileIcon()" 
                                                :rules="[v=> checkPhone(v)]"
                                                @input="showSaveButton()"
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12"  xl="8" lg="8" md="12" sm="12">
                                                <v-text-field v-bind:label="'Email per bolletta digitale'" 
                                                v-model="profiloDigitaleBean.emailFatturazione" outlined
                                                v-bind:prepend-inner-icon="getEmailFatturazioneIcon()"
                                                :rules="[v=> checkEmail(v)]"
                                                @input="showSaveButton()" 
                                                >
                                                </v-text-field>
                                            </v-col>
                                            <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                                <v-textarea v-bind:label="'Area clienti'" 
                                                outlined
                                                readonly
                                                no-resize
                                                rows="3"
                                                :value="getAreaClientiText()"
                                                v-bind:prepend-inner-icon="getAreaClientiIcon()" 
                                                >
                                                </v-textarea>
                                            </v-col>               
                                        </v-row>
                                    </v-container>
                                    </v-form>
                                  <p v-if="showSaveError">Si è verificato un errore nel salvataggio, riprova più tardi</p>
                                </v-row>
                                <v-row>
                                    <v-col xl="4" lg="4" md="4" sm="4"></v-col>
                                    <v-col xl="4" lg="4" md="4" sm="4" align="center">
                                            <v-btn
                                            :href="documentData.bolletta_interattiva_duferco_profilo_areaclienti_link"
                                            target="_blank"
                                            style="border-radius: 8px !important;"
                                            class="black--text"                          
                                            >
                                            {{$t('message.bolletta_interattiva_duferco_profilo_areaclienti_bottone')}}          
                                            </v-btn>
                                    </v-col>
                                    <v-col xl="4" lg="4" md="4" sm="4"></v-col>
                                </v-row>
                                <v-row v-if="showSave">
                                    <v-col xl="4" lg="4" md="4" sm="4"></v-col>
                                    <v-col xl="4" lg="4" md="4" sm="4" align="center">
                                        <v-dialog v-model="showDialog" width="500">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn
                                                    v-bind="attrs" v-on="on"
                                                    @click="showDialog = true"
                                                    style="border-radius: 8px !important;"
                                                    class="black--text"                          
                                                    >
                                                    {{$t('message.bolletta_interattiva_duferco_profilo_salva')}}          
                                                </v-btn>
                                            </template>

                                            <v-card id="confirm-dialog"> 
                                            <v-card-text class="text-subtitle-1 pa-5"> {{$t('message.bolletta_interattiva_duferco_profilo_conferma_salva')}} </v-card-text>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn text @click="showDialog = false"> {{$t('message.bolletta_interattiva_duferco_profilo_annulla')}} </v-btn>
                                                <v-btn text @click="saveDufercoProfile()"> {{$t('message.bolletta_interattiva_duferco_profilo_salva')}} </v-btn>
                                            </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                            
                                    </v-col>
                                    <v-col xl="4" lg="4" md="4" sm="4"></v-col>
                                </v-row>
                        </v-col>
                    </v-row>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-spacer class="spacerExpPanelDown"></v-spacer>
    </v-container>
  </template>
  
  <script>
  import { services } from '../scripts/services/serviceBuilder'
  
  export default {
    name: 'bollettaInterattivaProfiloDuferco',
    data: function() {
        return {
            bolletta_interattiva_duferco_profilo : true,
            profiloDigitaleBean: {
                idAnagrafica: null,
                livelloCompletezzaProfilo: null,
                email: null,
                telefono: null,
                selfCare: null,
                bollettaCartacea: null,
                pec: null,
                pecFatturazione: null,
                emailFatturazione: null,
            },
            showDialog: false,
            showSave: false,
            showSaveError: false,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: {
        
    },
    components: {
        
    },
    methods: {
        showSaveButton: function(){
            let that = this;
            if(!that.showSave)
                that.showSave = true;
        },
        checkEmail: function (value) {
            if (value == null || value.toString().length == 0)
                return true;
            return /^.+@.+\..+$/.test(value) || this.$t("message.my_profile_check_email");
        },
        checkPhone: function (value) {
            if (value == null || value.toString().length == 0)
                return true;   
            return /^[+]{1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.,\\/0-9]*$/.test(value) || this.$t("message.my_profile_check_phone");
        },
        replaceWithBr: function(stringWithBreakLines) {
            return stringWithBreakLines.replace(/\n/g, "<br />")
        },
        getDufercoProfiloLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/Profilo_icon.png";
        },
        getEmailIcon: function () {
            let that = this;
            if(that.profiloDigitaleBean.email === null || that.profiloDigitaleBean.email.trim().length === 0)
                return "mdi-circle-outline";
            else
                return "mdi-circle";
        },
        getMobileIcon: function () {
            let that = this;
            if(that.profiloDigitaleBean.telefono === null || that.profiloDigitaleBean.telefono.trim().length === 0)
                return "mdi-circle-outline";
            else
                return "mdi-circle";
        },
        getEmailFatturazioneIcon: function () {
            let that = this;
            if(that.profiloDigitaleBean.emailFatturazione === null || that.profiloDigitaleBean.emailFatturazione.trim().length === 0)
                return "mdi-circle-outline";
            else
                return "mdi-circle";
        },
        getAreaClientiIcon: function () {
            let that = this;
            if(that.profiloDigitaleBean.selfCare === null)
                return "mdi-circle-outline";
            else
                return "mdi-circle";
        },
        getAreaClientiText: function () {
            let that = this;
            if(that.profiloDigitaleBean.selfCare === 1)
                return that.$t('message.bolletta_interattiva_duferco_profilo_areaclienti_ok');
            else
                return that.$t('message.bolletta_interattiva_duferco_profilo_areaclienti_ko');
        },
        getTestoCompletezzaProfilo: function () {
            let that = this;
            let apiResult = that.profiloDigitaleBean?.livelloCompletezzaProfilo;
            if(parseInt(apiResult) === 4)
                return that.replaceWithBr(that.$t('message.bolletta_interattiva_duferco_profilo_completo'));
            return that.replaceWithBr(that.$t('message.bolletta_interattiva_duferco_profilo_non_completo'));

        }, 
        getEmailFatturazionePlaceholder: function () {
            let that = this;
            if(that.profiloDigitaleBean.emailFatturazione === null || that.profiloDigitaleBean.emailFatturazione.trim().length === 0)
                return that.$t('message.bolletta_interattiva_duferco_profilo_no_email_fatturazione');
            return "";           
        },      
        showDufercoProfiloTesto: function(){
          let that = this;
          let text = that.$t('message.bolletta_interattiva_duferco_profilo_text');
          if(text === null || text === undefined || text?.length === 0)
            return false;
          return true;
        },
        getProfileFromDuferco: function(){
            let that = this;
            let requests = [];
            requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoProfilo(that.documentData.crypto));
            Promise.all(requests).then((responses) => {
              if (responses[0].data !== '') {
                that.profiloDigitaleBean=responses[0].data;
              }
            });
        },
        saveDufercoProfile: function(){
            let that = this;
            let validationResult = this.$refs.profileForm.validate();
            if(validationResult){
                //Call API
                let profiloObj = {
                    idAnagrafica: that.profiloDigitaleBean.idAnagrafica,
                    email: that.profiloDigitaleBean.email,
                    telefono: that.profiloDigitaleBean.telefono,
                    emailFatturazione: that.profiloDigitaleBean.emailFatturazione
                }
                let requests = [];
                requests.push(services.apiCallerBollettaInterattivaDuferco.postSalvaDufercoProfilo(that.documentData.crypto, profiloObj));

                Promise.all(requests).then((responses) => {
                    that.showDialog = false;
                    if (responses[0].data === '') {
                      that.showSaveError=true;
                    } else {
                      that.showSaveError=false;
                    }
                    that.showSave = false;
                });       
            }else{
                //Form has invalid data closing dialog
                that.showDialog = false;
            }          
        }
    },
  created: function () {
      let that = this;
      that.bolletta_interattiva_duferco_profilo = Boolean(Number(this.documentData.bolletta_interattiva_duferco_profilo));
      that.getProfileFromDuferco();
  }
  }
  </script>

  <style scoped>
.v-text-field >>> fieldset {
  border-color: rgba(255, 255, 255, 0.986)!important;
  color: rgba(255, 255, 255, 0.986)!important;
}
.v-text-field >>> .v-text-field__slot input {
  color:  white !important;
}
.v-text-field >>> .v-text-field__slot label  {
  color:  white !important;
}
.v-text-field >>> .v-icon  {
  color:  white !important;
}
.v-text-field >>>  textarea  {
  color:  white !important;
}


</style>
  