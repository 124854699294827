<template>
    <v-container id="BollettaInterattivaDatiPagamentoBody3Dt">
        <div>
            <p class="text-h5 HeaderMultiComponent">{{$t('message.bolletta_interattiva_sintesi_importi_header')}}</p>
        </div>
        <v-row no-gutters>
            <v-col>
                <v-simple-table id="sintesiImportiTabella1">
                    <template v-slot:default>
                    <tbody>
                        <tr v-for="(item,index) in importiValues" :key="index">
                            <td v-if="item.key==='TOTALE DA PAGARE' || item.key==='TOTALE A CREDITO'" class="text-right" style="border-right: 3px solid white; width: 75%;">{{ item.key }}</td>
                            <td v-else class="text-left" style="border-right: 3px solid white; width: 75%;">{{ item.key }}</td>
                            <td class="text-right">{{ getImportoValue(item.value) }}</td>
                        </tr>
                    </tbody>
                    </template>
                </v-simple-table>
                <!--div v-if="showRiepilogoIvaTable()">
                    <p class="text-h7 pt-6">{{$t('message.bolletta_interattiva_sintesi_importi_header_iva')}}</p>
                    <v-simple-table id="sintesiImportiTabella2">
                        <template v-slot:default>                               
                        <tbody>
                            <tr>
                                <td class="text-left" style="border-right: 3px solid white; width: 30%;">Aliquota IVA</td>
                                <td class="text-left" style="border-right: 3px solid white; width: 45%;">Imponibile</td>
                                <td class="text-left" style="border-right: 3px solid white; width: 25%;">Importo</td>
                            </tr>
                            <tr v-for="item in aliquoteItems" :key="item.aliquota_iva">
                            <td class="text-left" style="border-right: 3px solid white; width: 30%;">{{ item.aliquota_iva + '%' }}</td>
                            <td class="text-left" style="border-right: 3px solid white; width: 45%;">{{ item.imponibile + ' euro' }}</td>
                            <td class="text-left" style="border-right: 3px solid white; width: 25%;">{{ item.importo + ' euro' }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </div-->
            </v-col>
            <v-col>
            <v-col v-show="showGraficoImporti()" class="pa-3" style="position:relative; height: 100%; width: 100%;">
                <canvas id="grafico_importi"> </canvas>
            </v-col>
            </v-col>
        </v-row>      
    </v-container>
</template>

<script>
import { services } from '../../scripts/services/serviceBuilder'
import updateToken from '../../middleware/update-token';
import Chart from "chart.js";
import Vue from "vue";

export default {
    name: 'BollettaInterattivaDatiPagamentoBody3Dt',
    data: function() {
        
        return {
            show: false,
            windowWidth: window.innerWidth,           
            //aliquoteItems:[],         
            graficoImportiValue: [],
            importiValues: [],
            chartData: {
                type: 'pie',
                data:{
                    labels: [],
                    datasets:[{                   
                        data: [],
                        backgroundColor: [],
                        hoverOffset: 4
                    }],
                },
                options: {
                    legend: {
                        display: true,
                        position: 'right',
                        align: 'start',
                        labels: {
                            usePointStyle: true,
                            pointStyle: "circle",
                            padding: 25,
                            fontSize:14
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    animation:false,
                },
            },
            chartVariable: null,
            //bolletta_interattiva_sintesi_importi_riepilogo_iva: false,
            bolletta_interattiva_sintesi_import_grafico: false,
            bolletta_interattiva_bottone_apri_sintesi_importi: false,
            bolletta_interattiva_pagaora_nexi_attivazione: false,
            bolletta_interattiva_pagaora_pagopa_attivazione: false,
            bolletta_interattiva_pagaora_boll: false,

            showNexiErrorDialog: false,

            bolletta_interattiva_document_payment_status: -1,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
    methods: {
        onResize() {
            this.windowWidth = window.innerWidth;
            if(this.windowWidth<850)
                this.chartData.options.legend.position = 'bottom';
                this.chartData.options.legend.labels.fontSize = 10;
                this.chartData.options.legend.align = ''
        },
        getRidImage: function() {
                return './' + location.hostname + '/images/bollettainterattiva/rid.jpg';
        },
        getNexiLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/nexi_logo.png";
        },
        getBollettinoLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/bollettino_icon.jpg";
        },
        getPaymentStatusIco: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/check_mark.svg";
        },
        loadImportiValues: function(){
            let that = this;
            let startingValue = that.documentData.fatt_interattiva_data.sintesi_importi.tabella_importi;
            for (let prop in startingValue) {
                that.importiValues.push({
                    key: prop,
                    value: startingValue[prop]
                });
            }
        },
        getImportoValue: function(value){
            if(value.includes('euro'))
                return value;
            return value + ' euro';
        },
        createChart: function() {
            let that = this;
            let ctx = document.getElementById("grafico_importi").getContext('2d');
            //Preparing data for chart
            let colors = that.graficoImportiValue.map(x=>x.color);
            let dataValues = that.graficoImportiValue.map(x=>x.value);
            let labels = that.graficoImportiValue.map(x=>x.label);
            that.chartData.data.labels = labels;
            that.chartData.data.datasets[0].data = dataValues;
            that.chartData.data.datasets[0].backgroundColor = colors;
            that.chartVariable = new Chart(ctx, that.chartData);
            //that.chartVariable.render();
            //that.chartVariable.destroy();
    
        },
        // showRiepilogoIvaTable: function(){
        //     let that = this;
        //     if(that.bolletta_interattiva_sintesi_importi_riepilogo_iva && that.aliquoteItems.length > 0)
        //         return true;
        //     else
        //         return false;
        // },
        showGraficoImporti: function(){
            let that = this;
            if(that.bolletta_interattiva_sintesi_import_grafico && that.graficoImportiValue.length > 0)
                return true;
            else
                return false;
        },
        dufercoPagamento: function(){
            updateToken(Vue.prototype.$isAuthenticated.value);

            let that = this;
            let requests = [];
            //Calling api_duferco_verifica before proceeding on api_duferco_pagamento
            requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoVerifica(that.documentData.crypto));
            Promise.all(requests).then((responses) => {
                let verificaValue = responses[0].data;
                console.log("Verifica ha valore:", verificaValue);
                if(verificaValue === 1){
                    //OK
                    //Calling profiloDuferco
                    requests = [];
                    requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoProfilo(that.documentData.crypto));
                    Promise.all(requests).then((responses) => {
                        let profiloDuferco = responses[0].data;
                        //Calling api_duferco_pagamento
                        requests = [];
                        requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoPagamento(that.documentData.crypto));
                        Promise.all(requests).then((responses) => {
                            let postData = responses[0].data.post_data;
                            let nexiUrl = responses[0].data.url;
                            //Preparing url = /nexiEsito?id={crypto}&annullo=0 
                            // url_back = /nexiAnnullo?id={crypto}&annullo=1
                            postData.url = window.location.origin + '/nexiEsito?id=' + that.documentData.crypto + '&annullo=0'
                            postData.url_back = window.location.origin + '/nexiAnnullo?id=' + that.documentData.crypto + '&annullo=1'
                            if(postData === null || nexiUrl === null){
                                //Error
                                that.showNexiErrorDialog = true;
                            }else{
                                //Ok go for payment on Nexi
                                //TODO: use postData and nexiUrl to call NEXI services
                                if(profiloDuferco &&  profiloDuferco.emailFatturazione)
                                    postData['mail'] = profiloDuferco.emailFatturazione;
                                
                                postData['descrizione'] = '_*' + that.documentData.document_number + '__';
                                
                                that.postForm (nexiUrl,postData, 'post');
                                /*services.apiCallerBollettaInterattivaDuferco.postNexiPagamento(nexiUrl,postData).then((response)=>{
                                    console.log(response);
                                });*/
                            }
                        });
                    });
                }else{
                   // Error
                   that.showNexiErrorDialog = true;
                }
            });
            
        },
        postForm(path, params, method) {
            method = method || 'post';

            var form = document.createElement('form');
            form.setAttribute('method', method);
            form.setAttribute('action', path);

            for (var key in params) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
            }

            document.body.appendChild(form);
            form.submit();
        },
        dufercoVerifica: function(){
            let that = this;
            let requests = [];
            //Calling api_duferco_verifica before proceeding on api_duferco_pagamento
            requests.push(services.apiCallerBollettaInterattivaDuferco.getDufercoVerifica(that.documentData.crypto));
            return Promise.all(requests);
        },
        reloadOnDufercoPagamentoError: function(){
            let that = this;
            that.showNexiErrorDialog = false;
            location.reload();
        },
        downloadBollettino: function() {
            let that = this;

            services.apiCallerMedia.download(that.documentData.detail)
                .then(response =>  {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'file.pdf');
                    document.body.appendChild(link);
                    link.click();
                })               
                .catch(error => {
                    console.log(error);
                });
        },
            
    },
	created: async function () {
        let that = this;
        //that.aliquoteItems = that.documentData.fatt_interattiva_data.sintesi_importi.tabella_iva;
        that.graficoImportiValue = that.documentData.fatt_interattiva_data.sintesi_importi.grafico_importi;
        that.loadImportiValues();

        //that.bolletta_interattiva_sintesi_importi_riepilogo_iva = Boolean(Number(that.documentData.bolletta_interattiva_sintesi_importi_riepilogo_iva));
        that.bolletta_interattiva_sintesi_import_grafico = Boolean(Number(that.documentData.bolletta_interattiva_sintesi_import_grafico));
        that.bolletta_interattiva_bottone_apri_sintesi_importi =  Boolean(Number(that.documentData.bolletta_interattiva_bottone_apri_sintesi_importi));
        
        if(that.bolletta_interattiva_bottone_apri_sintesi_importi){
            that.show = true;
        }
        
        that.bolletta_interattiva_pagaora_pagopa_attivazione = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_pagopa_attivazione));
        that.bolletta_interattiva_pagaora_nexi_attivazione = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_nexi_attivazione));

        that.bolletta_interattiva_pagaora_boll = Boolean(Number(that.documentData.bolletta_interattiva_pagaora_boll));

        if(!that.documentData.bolletta_interattiva_esponi_stato_pagamento_data || that.documentData.bolletta_interattiva_esponi_stato_pagamento_data.toUpperCase() == 'DB')
            that.bolletta_interattiva_document_payment_status = that.documentData.document_payment_status;
        else
          {
            that.dufercoVerifica().then((responses) => {
            that.bolletta_interattiva_document_payment_status  = responses[0].data;
            });
        }
        
	},
    mounted: function(){
        this.createChart();
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },
    beforeDestroy: function(){
        window.removeEventListener('resize', this.onResize);
    }
}
</script>

<style scoped>
#sintesiImportiTabella1 tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
#sintesiImportiTabella2 tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

.arrow-label {
  text-align: center;
}
</style>