import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    data() {
        const url = this.webApiBaseUrl + "/api/contacts";
        return this.apiCallerHelper.callGet(url);
    }

    sendEmail(customerId, data) {
        const url = this.webApiBaseUrl + "/api/contacts/sendemail/" + customerId;
        return this.apiCallerHelper.callPost(url, data);
    }

}