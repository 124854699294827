import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    download(key) {
        const url = this.webApiBaseUrl + "/api/media/" + key;
        return this.apiCallerHelper.callGetDownload(url);
    }
}