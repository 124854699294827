<template>
  <v-container id="BollettaInterattivaContatti3" class="pa-7">
      <v-row class="contentcontainer">
        <v-col class="detailscontainer" sm="9">
          <v-row class="titlecontainer" style="text-align:left;">
            <h3 class="title" v-html="$t('message.bolletta_interattiva_contacts_header3')"></h3>
          </v-row>

          <v-row lg="12" md="4" sm="12" xs="12"  style="text-align:left;">
            <div class="specificcontactsdetailcontainer">
              <div class="textContainer_address">
                <p v-html="$t('message.bolletta_interattiva_contacts_address3')" class="textValue textAddress">
                </p>
              </div>
            </div>
          </v-row>
          <v-row v-if="documentData.landing_email" lg="12" md="4" sm="12" xs="12" style="text-align:left;">
            <div class="specificcontactsdetailcontainer">
              <div class="textContainer_email">
                <span class="textHeader textEmail" v-html="$t('message.bolletta_interattiva_contacts_email3')"></span>&nbsp;
                <a :href="getMailLink()" :class="{ 'disabled' : (!getMailLink() || getMailLink().length===0) }">
                <v-hover
                v-slot="{ hover }">
                <span class="textValue textEmail" :class=" { 'on-hover': hover }">{{documentData.landing_email}}</span>
                </v-hover>
                </a>
                <p
                  class="textDetails textEmail"
                  v-html="$t('message.info_email')"
                ></p>
              </div>
            </div>
          </v-row>

          <v-row v-if="documentData.landing_pec" lg="12" md="4" sm="12" xs="12" style="text-align:left;">
            <div class="specificcontactsdetailcontainer">
              <div class="textContainer_pec">
                <p  class="textHeader textPec"  v-html="$t('message.bolletta_interattiva_contacts_pec3')"></p>&nbsp;
                <a :href="getPecLink()" :class="{ 'disabled' : (!getPecLink() || getPecLink().length===0) }">
                <v-hover
                v-slot="{ hover }">
                <span class="textValue textPec"  :class=" { 'on-hover': hover }">{{documentData.landing_pec}}</span>
                </v-hover>
                </a>
                <p
                  class="textDetails textPec"
                  v-html="$t('message.info_pec')"
                ></p>
              </div>
            </div>
          </v-row>


          <v-row lg="12" md="4" sm="12" xs="12" style="text-align:left;">
            <div class="specificcontactsdetailcontainer">
              <div class="textContainer_footer">
                <p class="textValue textContact" v-html="$t('message.bolletta_interattiva_contacts_footer3')"></p>
              </div>
            </div>
          </v-row>

          <v-row lg="12" md="4" sm="12" xs="12" style="text-align:left;">
            <div class="specificcontactsdetailcontainer">
              <div class="textContainer_distributore">
                <span class="textHeader textDistributore" v-html="$t('message.bolletta_interattiva_contacts_distributore3')"></span>&nbsp;
                <span class="textValue textDistributore"  v-html="$t('message.bolletta_interattiva_contacts_distributore_value3')"></span>
              </div>
            </div>
          </v-row>
        </v-col>
        <v-col class="telcontainer d-flex" >
          <v-row v-if="documentData.landing_page_tel1" lg="6" md="2" sm="6" xs="6" align="center" justify="center">
            <div class="specificcontactsdetailcontainer">
              <div class="imageContainer_tel">
                <a :href="getTelLink()" :class="{ 'disabled' : (!getTelLink() || getTelLink().length===0) }">
                  <v-img contain max-width="100"  v-if="telImageExists" :src="getTelImage()" @error="telImageError" />
                </a>
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
</template>


<script>
export default {
  data() {
    return {
      telImageExists: true
    };
  },
  props: {
    documentData: {
      type: Object,
      default: null,
    }    
  },
  methods: {
    getTelImage: function () {
      return './' + location.hostname + '/images/bollettainterattiva/numero_verde.png';
    },
    getTelLink: function() {
      return "tel:"+this.documentData.landing_page_tel1;
    },
    getMailLink: function() {
      return "mailto:"+this.documentData.landing_email;
    },
    getPecLink: function() {
      return "mailto:"+this.documentData.landing_email;
    },
    telImageError: function() {
      this.telImageExists = false;
    }
  },
};
</script>
