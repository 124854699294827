<template>
    <v-container id="autoletturaDuferco" v-if="bolletta_interattiva_duferco_autolettura && tipoBolletta==='GAS'">
        <div>
            <p class="text-h5 HeaderMultiComponent">{{$t('message.bolletta_interattiva_duferco_autolettura_header')}}</p>
        </div>    
                    <v-row justify="center">
                        <v-col cols="12">
                            <p class="pt-0 pb-3 ma-0" id="autoletturaDufercoText" align="center">
                                {{checkLabel('message.bolletta_interattiva_duferco_autolettura_text') ? "" : $t('message.bolletta_interattiva_duferco_autolettura_text')}}
                            </p>
                        </v-col>        
                    </v-row>
                    
                    <v-form ref="autoletturaDufercoForm">
                        <v-container>
                        <v-row justify="center">
                            <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                <v-select v-if="fornitureFromDocumentData.length > 1"
                                label="Fornitura"
                                :items="fornitureFromDocumentData"
                                v-model="fornitura"
                                required
                                :item-text ="item => getItemSelectFornituraText(item)"
                                return-object
                                outlined
                                @change="changedFornitura()"
                                ></v-select>
                            </v-col>

                            <v-col v-if="showAutoletturaTopSection" cols="12" xl="8" lg="8" md="12" sm="12" >
                                <p v-if="showSmcInput" id="autoletturaEnterDaysOkText" align="center" class="pt-3">{{$t('message.bolletta_interattiva_duferco_autolettura_ok_enterdays')}}</p>
                                <div v-else class="pb-3" align="center">
                                    <p id="autoletturaNoEnterDaysText" class="pt-3">{{$t('message.bolletta_interattiva_duferco_autolettura_no_enterdays')}}</p>
                                    <v-btn
                                        @click="downloadCalendarMemo()"
                                        style="border-radius: 8px !important;"
                                        class="black--text"
                                        >
                                        MEMO          
                                    </v-btn>
                                </div>
                            </v-col>

                            <v-col v-if="showSmcInput" cols="12" xl="8" lg="8" md="12" sm="12" class="pb-0">
                                <v-text-field v-bind:label="$t('message.bolletta_interattiva_duferco_autolettura_inserimento')" 
                                required outlined
                                v-model="autoletturaValue"
                                suffix=" Smc"                        
                                :rules="[v=> checkAutolettura(v)]"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        </v-container>
                    </v-form>
                    
                    <v-row>
                        <v-col xl="4" lg="4" md="4" sm="4"></v-col>
                        <v-col xl="4" lg="4" md="4" sm="4" align="center">
                            <v-btn
                            v-if="showSmcInput"
                            @click="confermaAutolettura()"
                            style="border-radius: 8px !important;"
                            class="black--text"                          
                            >
                            {{$t('message.bolletta_interattiva_duferco_autolettura_conferma')}}          
                            </v-btn>
                            
                        </v-col>
                        <v-col xl="4" lg="4" md="4" sm="4"></v-col>
                    </v-row>
                    <v-row v-if="showAutoletturaSuccess">
                        <v-col xl="12" lg="12" md="12" sm="12" align="center">
                            <div>{{$t('message.bolletta_interattiva_duferco_autolettura_invia_ok')}}</div>
                        </v-col>
                    </v-row>
    </v-container>
</template>

<script>
import { services } from '../../scripts/services/serviceBuilder'
import { createEvent} from 'ics';

export default {
    name: 'bollettaInterattivaAutoletturaDuferco3Dt',
    data: function() {
        return {
            bolletta_interattiva_duferco_autolettura : true,
            tipoBolletta: "",   
            fornitureFromDocumentData: [],
            fornitura : null,
            autoletturaValue: null,
            showSmcInput: false,
            showAutoletturaTopSection: false,
            showAutoletturaSuccess: false,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: {

    },
    components: {
        
    },
    methods: {
        getAutoletturaDufercoLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/autolettura_icon.png";
        },
        getItemSelectFornituraText: function(item){
            let result = item.tipo_punto +":"+ item.pdr+' - ';
            item.indirizzo.forEach(element => {
                result += element+" - ";
            });
            result = result.slice(0, -3);
            return result;
        },
        confermaAutolettura: function() {
            let that = this;
            let validationResult = that.$refs.autoletturaDufercoForm.validate();
            if(validationResult){
                //Call API to save autolettura
                let requests = [];
                let autoletturaObj={
                    "Idsede": that.fornitura.id_sede,
                    "Segnante": that.autoletturaValue
                }
                requests.push(services.apiCallerBollettaInterattivaDuferco.postAutolettura(autoletturaObj));
                Promise.all(requests).then((responses) => {
                    let apiResult = responses[0].data;
                    //that.showAutoletturaTopSection = true;
                    if(apiResult === 1){
                        //Show success text
                        that.showAutoletturaSuccess = true;
                    }
                });
                
            }else{
                that.showAutoletturaSuccess = false;
            }
        },
        checkAutolettura: function(value){
            if (value == null || value.toString().length == 0)
                return this.$t("message.my_profile_check_text");
            else
                return /^\d+$/.test(value) || this.$t("message.my_profile_check_int");
            
        },
        changedFornitura: function(){
            let that = this;

            let requests = [];
            requests.push(services.apiCallerBollettaInterattivaDuferco.getValidEnterDays());
            Promise.all(requests).then((responses) => {
                let apiResult = responses[0].data;
                that.showAutoletturaTopSection = true;
                if(apiResult === 0){
                    //Show button to download ics file
                    that.showAutoletturaTopSection = true;
                }else{
                    //Show text-field input
                    that.showSmcInput = true;
                }
            });

        },
        downloadCalendarMemo: async function(){
            //Generate ICS file
            let lastDayOfCurrentMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
            let firstDayofNextMonth = new Date(lastDayOfCurrentMonth.valueOf());
            firstDayofNextMonth.setDate(firstDayofNextMonth.getDate() + 1);
            console.log(firstDayofNextMonth);
            let alarm = [{ action: 'display', description: 'Reminder', trigger: { hours: 24, minutes: 0, before: true } }];
            const event = {
                productId:"Duferco Energia",
                start: [lastDayOfCurrentMonth.getFullYear(), lastDayOfCurrentMonth.getMonth()+1, lastDayOfCurrentMonth.getDate(), 11, 0],
                title: 'Reminder Autolettura Gas Duferco',
                description: "Duferco Energia ti ricorda di effettuare l'autolettura ultimo giorno del mese",
                status: 'CONFIRMED',
                recurrenceRule: 'FREQ=MONTHLY;BYMONTHDAY=-1;UNTIL='+firstDayofNextMonth.getFullYear()+firstDayofNextMonth.getMonth()+1+firstDayofNextMonth.getDate()+'T000000',
                alarms: alarm
            }
            

            /*
            ics.createEvent(event, (error, value) => {
                if (error) {
                    console.log(error)
                    return
                }
                console.log(value)
            });
            */

            const filename = 'DufercoAutolettura.ics'
            const file = await new Promise((resolve, reject) => {
                createEvent(event, (error, value) => {
                if (error) {
                    reject(error)
                }
                
                resolve(new File([value], filename, { type: 'plain/text' }))
                })
            })
            const url = URL.createObjectURL(file);
            
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = filename;
            
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
            
            URL.revokeObjectURL(url);
        }
    },
	created: function () {
        let that = this;
		that.bolletta_interattiva_duferco_autolettura = Boolean(Number(that.documentData.bolletta_interattiva_duferco_autolettura));
        that.tipoBolletta = that.documentData.tipo_bolletta;
        that.fornitureFromDocumentData = that.documentData.fatt_interattiva_data.fornitura;
        if(that.documentData.fatt_interattiva_data.fornitura.length === 1){
            //Se la fornitura è una sola mostro chiamo API per enterDays e mostro o bottone per memo o input Smc
            that.fornitura = that.documentData.fatt_interattiva_data.fornitura[0];
            that.changedFornitura();
        }     
	}
}
</script>
