import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    publicAccess(queryString) {
        const url = this.webApiBaseUrl + "/api/public/access?" + queryString;
        return this.apiCallerHelper.callGet(url);
    }

}