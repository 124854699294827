<template>
    <v-container>
        <v-card title="">
            <v-card-title>
                Nexi Esito di pagamento
            </v-card-title>
            <v-card-text>
                {{ getEsitoText() }}
            </v-card-text>
        <v-card-actions>
            <v-btn block @click="goBackToBolletta()">Torna alla bolletta</v-btn>
        </v-card-actions>
        </v-card>
    </v-container>
</template>
<script>

import { services } from '../scripts/services/serviceBuilder'

export default {
    data: () => ({

    }),
    methods:{
        goBackToBolletta:function(){
            const urlParams = new URLSearchParams(window.location.search);
            const myParam = urlParams.get('id');
            window.open("/bollettainterattiva" + "?id="+myParam, '_self');
        },
        getEsitoText:function(){
            const urlParams = new URLSearchParams(window.location.search);

            if(urlParams.get('esito').toUpperCase() === 'OK')
                return this.$t("message.bolletta_interattiva_pagaora_nexi_esito_ok");
            else
                return this.$t("message.bolletta_interattiva_pagaora_nexi_esito_ko")
        },
        postDufercoSegnaPagamento: function (crypto,postData)
        {
            let requests = [];
            //Calling postDufercoSegnaPagamento
            requests.push(services.apiCallerBollettaInterattivaDuferco.postDufercoSegnaPagamento(crypto,postData));
            return Promise.all(requests);
        }
    },
    props:{
        nexiEsito: {
            type: Object,
            default: null
        },
        cryptoId: {
            type: String,
            default: null
        },
        documentId: {
            type: String,
            default: null
        },
        error: {
            type: Number,
            default: null
        }
    },
    computed: {
       
    },
    mounted() {
        //console.log(this.$route.query.test) ;
        const urlParams = new URLSearchParams(window.location.search);

        let postData = {
                "esito" : urlParams.get('esito'),
                "codiceEsito" : urlParams.get('codiceEsito'),
                "messaggio" : urlParams.get('messaggio'),
                "codAut" : urlParams.get('codAut'),
                "codTrans" : urlParams.get('codTrans'),
                "data" : urlParams.get('data'),
                "divisa" : urlParams.get('divisa'),
                "importo" : urlParams.get('importo'),
                "orario" : urlParams.get('orario'),
                "mac" : urlParams.get('mac'),
                "brand" : urlParams.get('brand'),
                "tipoTransazione" : urlParams.get('tipoTransazione')
            };
        if(urlParams.get('esito').toUpperCase() === 'OK') {
          console.log(postData);
          this.postDufercoSegnaPagamento(urlParams.get('id'), postData);
        } else {
          console.debug('Pagamento rifiutato con esito:', urlParams.get('esito'), 'Codice esito:', urlParams.get('codiceEsito'), 'Messaggio:', urlParams.get('messaggio'))
        }

    }
};
</script>