import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    listHeaders(customerId) {
        const url = this.webApiBaseUrl + "/api/contract/headers/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    listContractsInView(contractType, customerId) {
        const url = this.webApiBaseUrl + "/api/contract/" + contractType + "/" + customerId;
        return this.apiCallerHelper.callGet(url);
    }

    listDetailHeaders(contractType) {
        const url = this.webApiBaseUrl + "/api/contract/moreinfo/headers/" + contractType;
        return this.apiCallerHelper.callGet(url);
    }

    listContractDetail(nContract) {
        const url = this.webApiBaseUrl + "/api/contract/dialog/headers/" + nContract;
        return this.apiCallerHelper.callGet(url);
    }

    listContractDetailKind(nContract, kind) {
        const url = this.webApiBaseUrl + "/api/contract/dialog/" + nContract + "/kind/" + kind;
        return this.apiCallerHelper.callGet(url);
    }

    listContractDetailHeaders(id) {
        const url = this.webApiBaseUrl + "/api/contract/details/headers/" + id;
        return this.apiCallerHelper.callGet(url);
    }

    listContractDetails(id) {
        const url = this.webApiBaseUrl + "/api/contract/details/" + id;
        return this.apiCallerHelper.callGet(url);
    }

    getContractInfo() {
        const url = this.webApiBaseUrl + "/api/contract/info";
        return this.apiCallerHelper.callGet(url);
    }

}