import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    data() {
        const url = this.webApiBaseUrl + "/api/social";
        return this.apiCallerHelper.callGet(url);
    }
}