<template>
<div>
    <v-row v-if="show == 'horizontal'" :justify="justify">
        <v-list class="menu__container" :class="menuContainer">
            <v-list-item  v-for="(item, i) in items" :key="i">
                <v-layout v-if="typeof item.sub_menus !== 'undefined' && item.sub_menus.length > 0">
                    <v-menu  tile :close-on-content-click="true"  offset-y transition="slide-y-transition" bottom >
                        <template v-slot:activator="{ on }">
                            <v-list-item-title class="menu__list" v-on="on"> {{ $t("message." + item.name) }}  </v-list-item-title>
                            <v-icon color="white" v-on="on">mdi-menu-down</v-icon>
                        </template>
                        <v-list>
                            <v-list-item v-for="(subItem, i) in item.sub_menus" :key="i" @click="doMenuAction(subItem)" >
                                <v-list-item-title class="d-flex flex-wrap" >{{ $t("message." + subItem.name) }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>  
                </v-layout>
                <v-list-item-title v-else  class="menu__list pa-4 pt-0 pb-0" @click="doMenuAction(item)">{{ $t("message." + item.name) }}</v-list-item-title>
            </v-list-item>
        </v-list>
    </v-row>
    <v-list v-if="show == 'vertical'" :justify="justify"  class="menu__container" :class="menuContainer">
        <v-list-item  v-for="(item, i) in items" :key="i">
                <v-list-group v-if="typeof item.sub_menus !== 'undefined' && item.sub_menus.length > 0" color="white" :value="value">
                    <template  v-slot:activator>
                    <v-list-item-title class="menu__list"> {{ $t("message." + item.name) }}</v-list-item-title>
                    </template>
                            <v-list-item  v-for="(subItem, i) in item.sub_menus" :key="i" @click="doMenuAction(subItem)" >
                                <v-list-item-title class="menu__list">{{ $t("message." + subItem.name) }}</v-list-item-title>
                            </v-list-item>
                </v-list-group>
            <v-list-item-title v-else  class="menu__list pa-4 pt-0 pb-0" @click="doMenuAction(item)">{{ $t("message." + item.name) }}</v-list-item-title>
        </v-list-item>
    </v-list>
    
</div>
</template>
<script>

export default {
    data: () => ({
       
    }),
    methods:{
        doMenuAction: function (item) {
            if(item.action != null) {
                switch(item.action) {
                    case "logout":                        
                        this.$root.$emit("logout");
                        return;
                    case "multimodule":
                        if (this.$route.path !== '/' + item.arguments) {
                            localStorage.setItem("multimodules", item.component);
                            this.$router.push({name: 'multimodule', params: {argument : item.arguments}});
                        }  
                        return;
                }
            }

            let page = '/' + item.component;
            
            if (this.$route.path !== page)
            {
                if(page == "/bollettainterattiva") {
                    window.open(page + "?invoiceId=123", '_blank');
                }
                else
                    this.$router.push(page);
            }
                
        },
        toggleChildren(item){
            item.isOpen = !item.isOpen
        }
    },
    props:{
        justify:null,
        value:null,
        show: null,
        display: {
            type : String,
            required : true
        },
        centered: Boolean,
        right: Boolean,
        showArrows: Boolean,
        items: {
            type: Array,
            required: true
        },
    },
    computed: {
        menuContainer: function() {
            if(this.display == "horizontal")
                return "horizontalClass";
            return "verticalClass";
        }
    }
}
</script>

<style scoped>
    .horizontalClass {
        /* determina l'orientamento orizontale del componente Menu */
        display: flex !important;
        /* justify-content: center !important; */

    }
    .verticalClass {
        /* determina l'orientamento verticale del componente Menu */
        display: flex;
        flex-direction: column;
        align-items: baseline;
    }


</style>

