<template>
  <v-container id="BollettaInterattivaDatiFornituraCard3Dt"  justify="center" style="margin-top:10px">
        <v-row no-gutters>
            <v-col cols="3"> 
                <img v-if="imgFornitura" :src="getImgFornitura()" id="logo_fornitura" @error="() => {imgFornitura = !imgFornitura}" width="100%"/>
            </v-col>
            <v-col cols="9"> 
                <div v-for="(em, i) in item.fields" :key="i" >
                    <div v-if="em.labelText != null" :class="(em.labelTitle != null ) ? em.labelTitle:''"  class='.text-wrap' >{{ (em.labelTitle != null ) ? em.labelTitle + ': ' + em.labelText :  em.labelText}}</div>
                </div>
            </v-col>
        </v-row>   
  </v-container>
</template>

<script>

export default {
  name: 'BollettaInterattivaDatiFornituraCard3Dt',
  data: function() {
      return {
          imgFornitura: true,
          ArrayValues: null
      }
  },
  props: {
      documentData: {
          type: Object,
          default: null
      },
      checkLabel: {
          type: Function,
       },
      item : {
        type: Object
      } 
  },
  computed: {
      
  },
  components: {
      
  },
  methods: {
    getImgFornitura: function () {
        return (this.item && this.item.img) ? this.item.img : null;
    }
  },
  created: function () {
  }
}
</script>
