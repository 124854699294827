<template>
  <v-app>
    <v-skeleton-loader type="article" tile :loading="loading">
      <!-- contenitore header principale -->
      <v-container id="headerContainer" fluid class="pa-0" style="max-height: 160px">
        <v-app-bar height="120" class="middleHeader" app dense fixed :src="getHeaderImage()">
          <v-container class="wrapWidth container_style">
            <v-row align="center">
              <v-col xl="3" lg="3" md="3" sm="4">
                <v-img contain :src="getHeaderLogo()" class="footer_logo"></v-img>
              </v-col>
              <v-col xl="6" lg="6" md="6" sm="4"></v-col>
              <v-col xl="3" lg="3" md="3" sm="4" class="py-0 mb-2" align="right">
                <Languages :locales="locales" :dark="true" :customStyle="{width: '120px'}"></Languages>
              </v-col>              
            </v-row>
          </v-container>
        </v-app-bar>
      </v-container>
      <!--contenitore del main-->
      <v-main class="main">
        <v-container class="wrapWidth">
          <BollettaInterattiva2 :cryptoId="cryptoId" :documentId="documentId" :error="error"/>
        </v-container>
      </v-main>
      <!--contenitore del footer-->
      <v-footer class="footer" style="background-color: #373737;">
        <v-container class="wrapWidth" justify="center">
          <v-row>
            <div class="footer_social_media_section">
              <v-btn icon target="_blank" :href="'https://www.facebook.com/blu-energy/'">
                <v-img :src="getSocialMediaImage('facebook')" style="width: 12px;" />
              </v-btn>
              <v-btn icon target="_blank" :href="'https://www.youtube.com/channel/blu-energy'">
                <v-img :src="getSocialMediaImage('youtube')" style="width: 12px;" />
              </v-btn>
              <v-btn icon target="_blank" :href="'https://www.linkedin.com/company/blu-energy'">
                <v-img :src="getSocialMediaImage('linkedin')" style="width: 12px;" />
              </v-btn>
              <v-btn icon target="_blank" :href="'https://instagram.com/blu-energy'">
                <v-img :src="getSocialMediaImage('instagram')" style="width: 12px;" />
              </v-btn>
            </div>
          </v-row>
          <v-row>
            <a href="https://blu-energy.com/" title="" target="_blank" class="footer_site_link">
              <span style="color: rgb(255, 255, 255);">blu-energy.com</span>
            </a>          
          </v-row>
          <v-row>
            <v-img contain :src="getFooterLogo()" class="footer_logo"></v-img>
          </v-row>
          <v-row>
            <span class="footer_copyright">© 2022 BLU Energy All rights reserved.</span>
          </v-row>
        </v-container>
      </v-footer>
    </v-skeleton-loader>
  </v-app>
</template>
<script>
import BollettaInterattiva2 from "../components/BollettaInterattiva2.vue";
import Languages from "../components/Languages.vue";
import { services } from "../scripts/services/serviceBuilder";
export default {
  name: "templateBollettaInterattiva2",
  components: {
    BollettaInterattiva2,
    Languages,
  },
  props: {
  
  },
  data: () => ({
    cryptoId: null,
    documentId: null,
    error: null,
    loading: true,
    locales: []
  }),
  computed: {
    isAuthenticated: function () {
      var result = this.getKeycloak(this.$parent);
      if (
        !result ||
        !Object.prototype.hasOwnProperty.call(result, "idTokenParsed")
      ) {
        return false;
      }
      return true;
    },
  },
  watch: {
      $route (to){
        this.loading = false;
        this.error = to.query.error;
        this.cryptoId = to.query.id;
        this.documentId = to.query.documentId;
      }
  },
  methods: {
    getKeycloak: function (node) {
      if (node == null) return null;
      if (node.$options.name != "App") return this.getKeycloak(node.$parent);
      return node.keycloak;
    },
    getHeaderImage: function () {
      if (this.$vuetify.breakpoint.mobile) {
        return "./" + location.hostname + "/images/bollettainterattiva/Header_mobile.jpg";
      }
      return "./" + location.hostname + "/images/bollettainterattiva/Header_Desktop.jpg";
    },
    getFooterLogo: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/Logo_Footer.png";
    },
    getHeaderLogo: function () {
      return "./" + location.hostname + "/images/bollettainterattiva/Logo.png";
    },
    getSocialMediaImage: function(key) {
      let url = null;
      switch (key) {
        case 'facebook':
          url = "./" + location.hostname + "/images/bollettainterattiva/Social_Icons/FB.png";
          break;
        case 'youtube':
          url = "./" + location.hostname + "/images/bollettainterattiva/Social_Icons/YT.png";
          break;
        case 'linkedin':
          url = "./" + location.hostname + "/images/bollettainterattiva/Social_Icons/LN.png";
          break;
        case 'instagram':
          url = "./" + location.hostname + "/images/bollettainterattiva/Social_Icons/IG.png";
          break;
      }
      return url;
    }
  },
  created: function () {
    var that = this;

    var requests = [];
    requests.push(services.apiCallerLocale.list());

    Promise.all(requests).then((responses) => {
      if (
        !!responses[0].data &&
        Array.isArray(responses[0].data) &&
        responses[0].data.length > 1
      ) {
        that.locales = responses[0].data;
        that.$i18n.locale = that.$locale.value;
      }
    });
  },
};
</script>

