import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    listQuestions(locale) {
        const url = this.webApiBaseUrl + "/api/Faq/" + locale;
        return this.apiCallerHelper.callGet(url);
    }

    downloadFaqAttachment(customerId, faqAttachId, contractId) {
        const url = this.webApiBaseUrl + "/api/Faq/attach/" + customerId + "/" + faqAttachId + "/" + contractId;
        return this.apiCallerHelper.callGet(url);
    }
}