import Vue from 'vue';

export default async function (isAuthenticated) {
    if (isAuthenticated) {
        if(Vue.$keycloak.tokenParsed.locale != null)
            Vue.prototype.$locale.value = Vue.$keycloak.tokenParsed.locale;
        
        console.debug('check token (flow is ' + Vue.$keycloak.flow + ') ...');
        if (Vue.$keycloak.flow != 'implicit') {
            await Vue.$keycloak.updateToken(70)
                .then((refreshed)=>{
                    if (refreshed){
                        //console.log('Token refreshed at ' + new Date());
                    } else {
                        //console.log('Token not refreshed at ' + new Date());
                    }
                }).catch(() => {
                    console.warn('failed to refresh token at ' + new Date());
                    if (Vue.$keycloak.isTokenExpired()) {
                        var logoutOptions = { redirectUri : window.location.origin };
                        Vue.$keycloak.logout(logoutOptions); 
                    }       
                });
        }
        console.debug('token is ' + (Vue.$keycloak.isTokenExpired() ? 'expired now' : 'still valid'));
        return Vue.$keycloak.token;
    }
    return '';
}
