<template>
    <v-container id="BollettaInterattivaCustomerCare3Dt" v-if="bolletta_interattiva_duferco_customercare">
        <div>
            <p class="text-h5 HeaderMultiComponent">{{$t('message.bolletta_interattiva_duferco_customercare_header')}}</p>
        </div>    
                    <v-row justify="center">
                        <v-col cols="12" >
                            <p id="customerCareText" class="pt-3">{{ $t('message.bolletta_interattiva_duferco_customercare_text') }}</p>
                        </v-col>                         
                        <v-form ref="customerCareForm">
                            <v-container>
                            <v-row justify="center">
                                <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                    <v-autocomplete
                                    label="Categoria"
                                    :items="categoriaItems"
                                    v-model="categoria"
                                    required
                                    outlined
                                    :persistent-placeholder=true
                                    :placeholder="categoriaPlaceHolder"
                                    :rules="[v=> checkRequired(v)]"
                                    @change="changedCategoria()"
                                    ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                    <v-text-field v-bind:label="$t('message.bolletta_interattiva_duferco_customercare_oggetto')" 
                                    v-model="oggetto" required outlined 
                                    :persistent-placeholder=true
                                    :rules="[v=> checkRequired(v)]"
                                    placeholder="Categoria | Codice Cliente | Numero Fattura">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" xl="8" lg="8" md="12" sm="12">
                                    <v-textarea
                                    outlined
                                    v-bind:label="$t('message.bolletta_interattiva_duferco_customercare_descrizione')"
                                    v-model="descrizione"
                                    required
                                    no-resize
                                    :rules="[v=> checkRequired(v)]"
                                    ></v-textarea>
                                </v-col>
                                <v-col v-if="allegato === null || allegato === undefined" cols="12" xl="8" lg="8" md="12" sm="12" class="pt-0">
                                    <label for="allegato">
                                        <span id="allegatoLabel">
                                            {{$t('message.bolletta_interattiva_duferco_customercare_allegato')}}
                                        </span><br>
                                        <span v-if="!fileInputValidate" id="customerCareAttachErrorText">{{$t('message.bolletta_interattiva_duferco_customercare_allegato_ko')}}</span>
                                        <input type="file" id="allegato" style="display:none" @click="(event) => clickedAllegato(event)" @change="attachedFile()"/>
                                    </label>
                                </v-col>
                                <v-col v-else cols="12" xl="8" lg="8" md="12" sm="12" class="pt-0">
                                    <label for="removeButton">
                                        <span class="pl-2">{{ getRemoveFileLabel }}</span> 
                                        <span @click="removeAllegato()" id="removeButton" class="grey--text">
                                        ( Delete )
                                        </span>
                                        
                                    </label>                                        
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-form> 
                    </v-row>
                    <v-row>
                        <v-col xl="5" lg="5" md="5" sm="5"></v-col>
                        <v-col xl="2" lg="2" md="2" sm="2" align-center>
                            <v-dialog v-model="showDialog" width="500">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    v-bind="attrs" v-on="on"
                                    :disabled="!($refs.customerCareForm.validate()&&fileInputValidate)"
                                    @click="showDialog = true"
                                    style="border-radius: 8px !important;"
                                    class="black--text"                          
                                    >
                                    {{$t('message.bolletta_interattiva_duferco_customercare_invia')}}          
                                    </v-btn>
                                </template>

                                <v-card id="confirm-dialog"> 
                                    <v-card-text class="text-subtitle-1 pa-5"> {{$t('message.bolletta_interattiva_duferco_customercare_conferma_invia')}} </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="showDialog = false"> {{$t('message.bolletta_interattiva_duferco_customercare_annulla')}} </v-btn>
                                        <v-btn text @click="sendCustomerCareMessage()"> {{$t('message.bolletta_interattiva_duferco_customercare_invia')}} </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-col>
                        <v-col xl="5" lg="5" md="5" sm="5"></v-col>
                    </v-row>
                    <div v-if="showCustomerCareInvioSuccess" align="center" id="customerCareSuccessText" class="pt-3">{{$t('message.bolletta_interattiva_duferco_customercare_invio_ok')}}</div>
                    <div v-if="showSaveError" align="center" id="customerCareErrorText" class="pt-3">{{$t('message.bolletta_interattiva_duferco_customercare_invio_ko')}}</div>

    </v-container>
</template>

<script>
import { services } from '../../scripts/services/serviceBuilder'
import updateToken from '../../middleware/update-token';
import Vue from "vue";

export default {
    name: 'customerCare',
    data: function() {
        return {
            bolletta_interattiva_duferco_customercare : true,   
            categoria:'',
            oggetto: '',
            descrizione: '',
            allegato: null,
            allegatoCleared: false,
            categoriaPlaceHolder: 'Scegli una categoria',
            categoriaItems: ['Evidenza di pagamento', 'Estratto conto', 'Richiesta rateizzazione fattura', 'Modifica modalità di pagamento', 'Rimborso'],
            showDialog: false,
            showCustomerCareInvioSuccess: false,
            showSaveError: false,
        }
    },
    props: {
        documentData: {
            type: Object,
            default: null
        },
        checkLabel: {
            type: Function,
        },
    },
    computed: {
        getRemoveFileLabel: function(){
            let that = this;
            if(that.allegato?.name.length > 0)
                return that.allegato.name;
            else
                return "Rimuovi allegato";
        },
        fileInputValidate: function(){
          if ( this.categoria === 'Evidenza di pagamento' && this.allegato === null) {
            return false;
          } else {
            return true;
          }
        },
    },
    components: {
        
    },
    methods: {
        checkRequired: function (value) {
            if (value == null || value.toString().length == 0)
                return this.$t("message.my_profile_check_text");
            return true;
        },
        getCustomerCareLogo: function () {
            return "./" + location.hostname + "/images/bollettainterattiva/customerCare.png";
        },
        clickedAllegato: function (event) {
           let that= this;
           //Prevents filePicker from showing while input component gets re-rendered after v-if
           if(that.allegatoCleared){
                event.preventDefault();
                that.allegatoCleared = false;
           }
        },
        removeAllegato: function () {
            let that = this;
            that.allegato = null;
            that.allegatoCleared = true;
        },
        attachedFile: function () {
            let that = this;
            var x = document.getElementById("allegato");
            that.allegato = x.files[0];
        },
        changedCategoria: function () {
            let that = this;
            let codiceCliente = Array.from(this.documentData.index_fields).find(item => item.label === "invoices_codice_cliente").value;
            let nDoc = Array.from(this.documentData.index_fields).find(item => item.label === "invoices_n_avviso").value;
            if(that.categoria.length>0){
                that.oggetto = that.categoria + " | " + codiceCliente + " | " + nDoc;
            }else{
                that.oggetto = '';
            }
        },
        sendCustomerCareMessage: function () {
            let that = this;
            let validationResult = this.$refs.customerCareForm.validate();
            if(validationResult){
                let messageObj = {
                    categoria: that.categoria,
                    oggetto: that.oggetto,
                    messaggio: that.descrizione
                }

                updateToken(Vue.prototype.$isAuthenticated.value);

                let requests = [];               
                if(that.allegato !== null && that.allegato !== undefined){
                    messageObj.allegato_nome = that.allegato.name;

                    // console.debug('allegato:', that.allegato)
                    const reader = new FileReader();
                    reader.onload = () => {
                      messageObj.allegato = reader.result.split(",")[1];
                      // console.debug(messageObj.allegato); // Base64-encoded image data

                      requests.push(services.apiCallerBollettaInterattivaDuferco.postCustomerCare(that.documentData.crypto, messageObj));

                      Promise.all(requests).then((responses) => {
                        that.showDialog = false;
                        if (responses[0].data != 1) {
                          that.showSaveError=true;
                          that.showCustomerCareInvioSuccess = false;
                        }
                        else
                        {
                          that.showSaveError=false;
                          that.showCustomerCareInvioSuccess = true;
                          that.categoria = '';
                          that.oggetto = '';
                          that.descrizione = '';
                          that.removeAllegato();
                        }
                      });
                    };
                    console.debug('avvio lettura del file');
                    reader.readAsDataURL(that.allegato);
                }else{
                    requests.push(services.apiCallerBollettaInterattivaDuferco.postCustomerCare(that.documentData.crypto, messageObj));
                    
                    Promise.all(requests).then((responses) => {
                        that.showDialog = false;
                        that.showCustomerCareInvioSuccess = true;
                        if (responses[0].data != 1) {
                                that.showSaveError=true;
                                that.showCustomerCareInvioSuccess = false;
                            }
                            else
                            {
                                that.showSaveError=false;
                                that.showCustomerCareInvioSuccess = true;
                                that.categoria = '';
                                that.oggetto = '';
                                that.descrizione = '';
                            }
                    });
                }
                
                
            }else{
                //Not calling API, form validation errors
                that.showDialog = false;
            }
            
        },
    },
	created: function () {
		this.bolletta_interattiva_duferco_customercare = Boolean(Number(this.documentData.bolletta_interattiva_duferco_customercare));
	}
}
</script>
<style scoped>
.v-text-field >>> fieldset {
  border-color: rgba(255, 255, 255, 0.986)!important;
  color: rgba(255, 255, 255, 0.986)!important;
}
.v-text-field >>> .v-text-field__slot input {
  color:  white !important;
}
.v-text-field >>> .v-select__slot input {
  color:  white !important;
}
.v-text-field >>> .v-select__slot label  {
  color:  white !important;
}
.v-text-field >>> .v-text-field__slot label  {
  color:  white !important;
}
.v-text-field >>> .v-icon  {
  color:  white !important;
}
.v-text-field >>>  textarea  {
  color:  white !important;
}


</style>
  