<template>
  <v-container id="bollettaInterattivaAvviso" class="px-0">
    <div class="notifiedUpperMessageContainer">
      <p class="notifiedUpperMessage ml-auto pr-2" v-html="$t('message.bolletta_interattiva_mancati_pagamenti_header')">
      </p>
      <div v-for="(item, i) in documentData.fatt_interattiva_data.mancati_pagamenti" :key="'row-'+i"
        class="d-flex notifiedContainer">
        
          <v-icon class="notifiedImageContainer"
          >mdi-emoticon-sad-outline</v-icon>

        <v-card class="notifiedInfoContainer ma-2">
          <v-row align="end">
            <v-col lg="4" md="4" sm="4" xs="12">
              <div class="notifiedInfo">
                <p class="notifiedTitle" v-html="$t('message.bolletta_interattiva_ndoc_label')"></p>
                <p class="notifiedNumber">{{item[0]}}</p>
                <p class="notifiedDateTitle"><span v-html="$t('message.bolletta_interattiva_date_prefix')"></span>&nbsp;<span class="notifiedIssueDate">{{item[1]}}</span></p>
                <p class="notifiedExpiredDate"> <span v-html="$t('message.bolletta_interattiva_expired_prefix')"></span>&nbsp;<span>{{item[2]}}</span></p>              
              </div>
            </v-col>
            <v-col lg="4" md="4" sm="4" xs="12">
              <div class="notifiedImportInfo">
                <p class="notifiedImportTitle" v-html="$t('message.bolletta_interattiva_importo')"></p>
                <p class="notifiedImport">{{amountWithCurrency(item[3])}}</p>
              </div>
            </v-col>
            <v-col lg="4" md="4" sm="4" xs="12">
            </v-col>
          </v-row>
        </v-card>
      </div>
    </div>    
  </v-container>
</template>

<script>
export default {
  data() {
    return {

    };
  },
    props: {
        documentData: {
            type: Object,
            default: null
        }
    },
  methods: {
    getIconLogo: function () {
      return "./" + location.hostname + "/images/sadIconLogo.png";
    },
    amountWithCurrency: function (_import) {
            return !isNaN(_import.replace(".","").replace(",","."))? _import + " €" : _import;
    },
  },
}
</script>

<style>
.divCardSpecific {
  float: right;
}
</style>
