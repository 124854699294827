<template>
    <v-container>
        <template v-if="$vuetify.breakpoint.mobile">
            <component v-for="(em, i) in enabledModules" :key="'component-' + i" :is="getModule(em)" :documentData="documentData" :error="error"></component>
        </template>
        <template v-else>
            <v-row>
                <v-col cols="4">
                    <component v-for="(em, i) in enabledModulesLeft" :key="'component-left-' + i" :is="getModule(em)" :documentData="documentData" :error="error"></component>
                </v-col>
                <v-col cols="8" class="py-0">
                    <component v-for="(em, i) in enabledModulesRight" :key="'component-right-' + i" :is="getModule(em)" :documentData="documentData" :error="error"></component>
                </v-col>
            </v-row>             
        </template>
    </v-container>
</template>

<script>
import BollettaInterattivaBody from './BollettaInterattivaBody.vue';
import BollettaInterattivaRiepilogoDoc from './BollettaInterattivaRiepilogoDoc.vue';
import BollettaInterattivaRiepilogoDoc2 from './BollettaInterattivaRiepilogoDoc2.vue';
import BollettaInterattivaChart from './BollettaInterattivaChart.vue';
import BollettaInterattivaChart2 from './BollettaInterattivaChart2.vue';
import BollettaInterattivaAvviso from './BollettaInterattivaAvviso.vue';
import BollettaInterattivaContatti from './BollettaInterattivaContatti.vue';
import BollettaInterattivaDownload from './BollettaInterattivaDownload.vue';
import BollettaInterattivaBanner from './BollettaInterattivaBanner.vue'
import BollettaInterattivaFornitura from './BollettaInterattivaFornitura.vue';
import BollettaInterattivaDatiPagamento from './BollettaInterattivaDatiPagamento.vue';
import ErrorPage from "../components/ErrorPage.vue";
import { services } from '../scripts/services/serviceBuilder'


export default ({
    components: {
        BollettaInterattivaBody,
        BollettaInterattivaRiepilogoDoc,
        BollettaInterattivaRiepilogoDoc2,
        BollettaInterattivaChart,
        BollettaInterattivaChart2,
        BollettaInterattivaAvviso,
        BollettaInterattivaContatti,
        BollettaInterattivaDownload,
        BollettaInterattivaBanner,
        BollettaInterattivaFornitura,
        BollettaInterattivaDatiPagamento,
        ErrorPage
    },
    props:{
      cryptoId: {
        type: String,
        default: null
      },
      documentId: {
        type: String,
        default: null
      },
      error: {
        type: Number,
        default: null
      }
    },
    data() {
        return {
            enabledModules: [],
            enabledModulesLeft: [],
            enabledModulesRight: [],
            modules: {
                "riepilogo_doc": "BollettaInterattivaRiepilogoDoc",
                "riepilogo_doc2": "BollettaInterattivaRiepilogoDoc2",
                "sintesi_importi": "BollettaInterattivaChart",
                "sintesi_importi2": "BollettaInterattivaChart2",
                "rate": "BollettaInterattivaBody",
                "info_e_contatti": "BollettaInterattivaContatti",
                "scarica_pdf": "BollettaInterattivaDownload",
                "banner_pubblicitario": "BollettaInterattivaBanner",
                "mancati_pagamenti": "BollettaInterattivaAvviso",
                "error_page": "ErrorPage",
                "fornitura": "BollettaInterattivaFornitura",
                "dati_pagamento": "BollettaInterattivaDatiPagamento",
            },
            overlayVisible: true,
            documentData: null
        }
    },
    computed: {
        isMobile: function () {
            return this.$vuetify.breakpoint.mobile;
        }
    },
    methods: {
        payBtnClicked: function(item) {
            console.log(item);
        },
        getModule(name){
            return this.modules[name];
        }
    },
    created: function () {
		var that = this;
        var requests = [];
        if(that.error == null && that.cryptoId != null) {
            requests.push(services.apiCallerBollettaInterattiva.modules());
            if(that.cryptoId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfo(that.cryptoId));
            else if(that.documentId != null)
                requests.push(services.apiCallerBollettaInterattiva.documentInfoById(that.documentId));
            else {
                that.enabledModules = ['error_page'];
                return;
            }

            Promise.all(requests)
            .then(responses => {
                if(responses[0].data){
                    if(!!responses[0].data['modules'] && Array.isArray(responses[0].data['modules'])){
                        that.enabledModules = responses[0].data['modules'];
                    }
                    if(!!responses[0].data['modules_left'] && Array.isArray(responses[0].data['modules_left'])){
                        that.enabledModulesLeft = responses[0].data['modules_left'];
                    }
                    if(!!responses[0].data['modules_right'] && Array.isArray(responses[0].data['modules_right'])){
                        that.enabledModulesRight = responses[0].data['modules_right'];
                    }
                }

                var obj = responses[1].data;
                obj.crypto = that.cryptoId;
                that.documentData = obj;
            })
            .catch(error => {
                that.error = error.data;
                that.enabledModules = ['error_page'];
            });
        }
        else {
            that.enabledModules = ['error_page'];
        }
    }
})
</script>