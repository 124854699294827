import Vue from 'vue'
import Keycloak from 'keycloak-js'
import AppSettingsService from "../scripts/services/appSettingsService";

const options = {
    url: AppSettingsService.getSettings().authServerUrl, 
    realm: 'undefined',
    clientId: AppSettingsService.getSettings().authClientId
}

let _keycloak = Keycloak(options)

const Plugin = {
  install(Vue) {
    Vue.$keycloak = _keycloak
  }
}

Plugin.install = Vue => {
  Vue.$keycloak = _keycloak
  Object.defineProperties(Vue.prototype, {
    $keycloak: {
      get() {
        return _keycloak
      },
      set(kc) {
        Vue.$keycloak = kc;
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
