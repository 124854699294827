
<template>
  <v-container id="bollettaInterattivaChart">
   <div class="pa-0">
      <v-row align="center">
          <v-col lg="6" md="6" sm="12">
              <v-list-item>
                <v-list-item-content class="pa-0">
                  <v-list-item-title class="ma-0">
                    <p class="labelTitle px-4" v-html="$t('message.bolletta_interattiva_sintesi_importi_header')">
                    </p>
                  </v-list-item-title>
                  <v-list>
                      <v-list-item v-for="(item, i) in chartData.data.labels"
                          :key="'label-'+i" class="labelItem">
                          <v-list-item-subtitle class="pa-0">
                              <v-avatar
                                :color="'labelColor' + ((i % maxColor) + 1)"
                                size="10"
                              ></v-avatar>
                              {{ item }}
                              <span class="labelItemImport"> {{ amountWithCurrency(i) }}</span>
                          </v-list-item-subtitle>
                      </v-list-item>
                  </v-list>
                </v-list-item-content>
              </v-list-item>
          </v-col>

          <v-col lg="6" md="6" sm="12">
              <canvas id="donut-chart" height="200px"> </canvas>
          </v-col>
        </v-row>
    </div>
  </v-container>
</template>

<script>
import Chart from "chart.js";

export default {
  data: () => ({
    chartData: {
      type: "doughnut",
      data: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: [],
            hoverOffset: 0,
          },
        ]
      },
      options: {
        legend: {
          display: false,
          position: "left",
        },
      },
    },
    maxColor: 5
  }),
  props: {
    documentData: {
      type: Object,
      default: null,
    },
  },
  methods: {
    getElementColor: function (index) {
      return this.chartData.data.datasets[0].backgroundColor[index];
    },
    amountWithCurrency: function (index) {
      return !isNaN(this.chartData.data.datasets[0].data[index])? 
        "€ " + this.chartData.data.datasets[0].data[index] : this.chartData.data.datasets[0].data[index];
    }
  },
  created() {
    this.documentData.fatt_interattiva_data.sintesi_importi.forEach(item => {
      for (const [key, value] of Object.entries(item)) {
        this.chartData.data.labels.push(key);
        this.chartData.data.datasets[0].data.push(value.replace(".","").replace(",","."));
      }
    });
  },
  mounted() {
    const ctx = document.getElementById("donut-chart");

    for(var i=0; i<this.documentData.fatt_interattiva_data.sintesi_importi.length; i++)
    {
        let para = document.querySelector('#bollettaInterattivaChart .labelColor' + ((i % this.maxColor) + 1));
        let current_color = getComputedStyle(para).getPropertyValue("background-color");
        this.chartData.data.datasets[0].backgroundColor.push(current_color); 
    }
        
    new Chart(ctx, this.chartData);
  },
};
</script>
