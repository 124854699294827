import AppSettingsService from './appSettingsService';

export default class ApiCaller {
    constructor(helper) {
        this.webApiBaseUrl = AppSettingsService.getSettings().webApiBaseUrl;
        this.bollettaInterattivaApiBaseUrl = AppSettingsService.getSettings().bollettaInterattivaApiBaseUrl;
        this.apiCallerHelper = helper;
    }

    getValidEnterDays() {
        const url = this.webApiBaseUrl + "/api/api_duferco_get_enterdays";
        return this.apiCallerHelper.callGet(url);
    }

    getDufercoProfilo(cryptoId) {
        const url = this.webApiBaseUrl + "/api/api_duferco_get_profilo/" + cryptoId;
        return this.apiCallerHelper.callGet(url);
    }

    postSalvaDufercoProfilo(cryptoId, data){
        const url = this.webApiBaseUrl + "/api/api_duferco_put_profilo/" + cryptoId;
        return this.apiCallerHelper.callPut(url, data);
    }

    postCustomerCare(cryptoId, data){
        const url = this.webApiBaseUrl + "/api/api_duferco_messaggio/" + cryptoId;
        return this.apiCallerHelper.callPost(url, data);
    }

    postAutolettura(data){
        const url = this.webApiBaseUrl + "/api/api_duferco_salva_autolettura";
        return this.apiCallerHelper.callPost(url, data);
    }

    getDufercoVerifica(cryptoId){
        const url = this.webApiBaseUrl + "/api/api_duferco_verifica/" + cryptoId;
        return this.apiCallerHelper.callGet(url);
    }

    getDufercoPagamento(cryptoId){
        const url = this.webApiBaseUrl + "/api/api_duferco_pagamento/" + cryptoId;
        return this.apiCallerHelper.callGet(url);
    }

    postDufercoSegnaPagamento(cryptoId, data){
        const url = this.webApiBaseUrl + "/api/api_duferco_segna_pagamento/" + cryptoId;
        return this.apiCallerHelper.callPost(url, data);
    }

    postNexiPagamento(nexiUrl, data){
        return this.apiCallerHelper.callPost(nexiUrl, data);
    }
}
