<template>
<v-container id="bollettaInterattiDownload" class="px-0">
  <v-card class="messageCard" outlined>
    <v-list-item>
      <v-list-item-content>
        <div class="messageContainer" >
        <p class="messageNotified" v-html="$t('message.bolletta_interattiva_download_title')"></p>  
        </div>
      </v-list-item-content>
    </v-list-item>
      

    <v-card-actions class="d-flex justify-center pa-0;">
      <v-btn v-bind="size"
      class="downloadPdfButton white--text"
      depressed
        outlined
        rounded
        @click="downloadFile"
        > <span class="downloadPdfButton_content" v-html="$t('message.bolletta_interattiva_download_action')"></span> 
        </v-btn>
    </v-card-actions>
  </v-card>
</v-container>
</template>

<script>
import { services } from "../scripts/services/serviceBuilder";

export default {
 computed: {
    size () {
      const size = {xs:'large',sm:'xx-large',lg:'xx-large',xl:'xx-large'}[this.$vuetify.breakpoint.name];
      return size ? { [size]: true } : {}
    }
  },
  props: {
        documentData: {
            type: Object,
            default: null
        }
    },
  methods:{
    downloadFile: function() {
      var that = this;

      services.apiCallerMedia.download(that.documentData.detail)
        .then(response =>  {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
        })               
        .catch(error => {
            console.log(error);
        });
      },
  }

}
</script>

<style>

</style>